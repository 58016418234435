import React, { Component } from "react";
import { withStyles } from "@material-ui/core";

class ViewTemplateMsg extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}
	render() {
		const { classes } = this.props
		return(
			<div className={classes.root}>
			</div>		
		)
	}
}

const styles = (theme) => ({
	root: {
	}
})

export default withStyles(styles)(ViewTemplateMsg);
