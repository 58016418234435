import React, { Component } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  withStyles,
  Box,
  CircularProgress,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Snackbar,
  Paper,
  Avatar,
  Tooltip,
  Hidden,
  List,
  Tab,
  Tabs,
  ListItemText,
  ListItem,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  Visibility,
  VisibilityOff,
  CloudUpload as CloudUploadIcon,
  ErrorOutline,
  Edit as EditIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";
import { modifyUser, signOut } from "../Login/loginstate";
import { fetchPartnerDetails } from "../../store/partnerState";
import { Alert, Autocomplete } from "@material-ui/lab";
import countriesTimezones from "../../config/countriesTimezones.json";
import { APIURL } from "../../config/config";

class AgentProfilePage extends Component {
  state = {
    displayName: "",
    password: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    changeName: false,
    changePassword: false,
    emailHelper: "",
    showPassword: false,
    status: "",
    isLoading: false,
    faviconUrl: "",
    brandLogoUrl: "",
    webhookUrl: "",
    loadingGifUrl: "",
    tabValue: 0,
    isEditing: false, // Added to handle edit mode
    selectedFile: null,
    selectedCancelledCheque: null,
    isCompany: false,
    formValues: {
      panImage: null,
      legalName: "",
      cancelledCheque: null,
      bankAccountNumber: "",
      accountName: "",
      ifscCode: "",
      panNumber: "",
      confirmAccountNumber: "",
      companyAddress: "",
      companyCity: "",
      companyPostal: "",
      companyCountry: "",
      state: "",
    },
    validationErrors: {
      bankAccountNumber: "",
      ifscCode: "",
      panNumber: "",
    },
    postalCodeError: false,
    availableStates: [],
  };

  componentDidMount() {
    this.loadPartnerDetails(this.props.partnerDetail);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partnerDetail !== this.props.partnerDetail) {
      this.loadPartnerDetails(this.props.partnerDetail);
    }
  }

  loadPartnerDetails = (partnerDetail) => {
    if (partnerDetail) {
      const selectedCountry = countriesTimezones.find(
        (country) => country.country === partnerDetail.companyCountry
      );

      this.setState({
        formValues: {
          legalName: partnerDetail.companyLegalName || "",
          bankAccountNumber: partnerDetail.bankAccountNumber || "",
          accountName: partnerDetail.accountName || "",
          ifscCode: partnerDetail.ifscCode || "",
          panNumber: partnerDetail.panNumber || "",
          panImage: partnerDetail.panImage || "",
          confirmAccountNumber: partnerDetail.confirmAccountNumber || "",
          companyAddress: partnerDetail.companyAddress || "",
          companyCity: partnerDetail.companyCity || "",
          companyPostal: partnerDetail.companyPostal || "",
          companyCountry: selectedCountry ? selectedCountry.country : "",
          state: partnerDetail.state || "",
        },
        availableStates: selectedCountry?.states || [],
      });
    }
  };

  handlePasswordChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onChange = (event) => {
    const { name, value, files } = event.target;

    if (files) {
      // Handle file uploads
      this.setState({ [name]: files[0] }, () =>
        this.validateField(name, files[0])
      );
    } else {
      // Handle text input changes
      const updatedValue =
        name === "panNumber" || name === "ifscCode"
          ? value.toUpperCase()
          : value;

      this.setState(
        (prevState) => ({
          formValues: {
            ...prevState.formValues,
            [name]: updatedValue,
          },
        }),
        () => {
          // Validate after state update with latest state values
          this.validateField(name);
        }
      );
    }
  };

  validateField = (name) => {
    const { bankAccountNumber, confirmAccountNumber } = this.state.formValues;
    let errorMessage = "";

    switch (name) {
      case "bankAccountNumber":
        // Validate bank account number
        errorMessage = this.validateBankAccountNumber(bankAccountNumber);
        break;
      case "confirmAccountNumber":
        // Validate confirm account number with the latest bankAccountNumber
        errorMessage = this.validateConfirmAccountNumber(
          confirmAccountNumber,
          bankAccountNumber
        );
        break;
      case "ifscCode":
        errorMessage = this.validateIFSCCode(this.state.formValues.ifscCode);
        break;
      case "panNumber":
        errorMessage = this.validatePAN(this.state.formValues.panNumber);
        break;
      default:
        break;
    }

    console.log(`Validation for ${name}:`, errorMessage); // Debugging line

    this.setState((prevState) => ({
      validationErrors: {
        ...prevState.validationErrors,
        [name]: errorMessage,
      },
    }));
  };

  validateBankAccountNumber = (value) => {
    const minLength = 9;
    const maxLength = 18;

    if (!value || value.length < minLength || value.length > maxLength) {
      return `Bank account number must be between ${minLength} and ${maxLength} digits.`;
    }

    return "";
  };

  validateConfirmAccountNumber = (value, bankAccountNumber) => {
    const minLength = 9;
    const maxLength = 18;

    if (!value || value.length < minLength || value.length > maxLength) {
      return `Confirm account number must be between ${minLength} and ${maxLength} digits.`;
    }

    if (bankAccountNumber && value.length !== bankAccountNumber.length) {
      return "Bank account number and confirm account number must have the same length.";
    }

    if (bankAccountNumber && value !== bankAccountNumber) {
      return "Bank account number and confirm account number must match.";
    }

    return "";
  };

  validateIFSCCode = (value) => {
    const ifscCodeRegex = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
    return ifscCodeRegex.test(value) ? "" : "Invalid IFSC code.";
  };

  validatePAN = (value) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(value) ? "" : "Invalid PAN number.";
  };

  // Handle the Edit button click
  onEdit = () => {
    this.setState({ changeName: true, changePassword: true, isEditing: true });
  };

  // Handle the Cancel button click
  onCancel = () => {
    this.setState({
      changeName: false,
      changePassword: false,
      isEditing: false, // Revert to non-edit mode
    });
  };

  // Handle the Save button click
  onSave = () => {
    const { tabValue } = this.state;

    if (tabValue === 1) {
      // Call the function to save bank details
      this.onSaveBankDetails();
    } else if (tabValue === 2) {
      // Call the function to change the password
      this.onSavePasswordChange();
    }
  };

  onSaveBankDetails = async () => {
    const { formValues } = this.state;
    const { partnerDetail } = this.props;
    const { _id } = partnerDetail;

    this.setState({ isLoading: true });

    // Determine required fields based on isCompany state
    let requiredFields = [];

    if (this.state.isCompany) {
      // Required fields for companies
      requiredFields = [
        "legalName",
        "companyAddress",
        "companyCity",
        "companyCountry",
        "companyPostal",
        "bankAccountNumber",
        "confirmAccountNumber",
        "accountName",
        "ifscCode",
        "panNumber",
      ];
    } else {
      // Required fields for non-companies (individuals, etc.)
      requiredFields = [
        "bankAccountNumber",
        "confirmAccountNumber",
        "accountName",
        "ifscCode",
        "panNumber",
      ];
    }

    // Validate required fields
    const hasErrors = requiredFields.some((field) => {
      const value = formValues[field];
      return value === undefined || value === null || value === "";
    });

    if (hasErrors) {
      this.setState({
        alertType: "error",
        alertMsg: "Please fill in all required fields and fix any errors.",
        isLoading: false,
      });
      return;
    }

    // Create an object with the form values
    const updatePayload = {
      _id,
      AreYouCompany: this.state.isCompany,
      ...formValues,
    };

    try {
      // Send form data to server as JSON
      const response = await axios.post(
        `${APIURL}/affiliates/update-affiliate-profile`,
        updatePayload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { message } = response.data;
      await this.props.fetchPartnerDetails();

      this.setState({
        alertType: "success",
        alertMsg: message || "Profile updated successfully",
        isLoading: false,
        changeName: false,
        isEditing: false,
      });

      // Handle file uploads separately if they exist
      // if (this.state.selectedFile) {
      //   await this.uploadFileToS3(this.state.selectedFile, "panImage");
      // }

      // if (this.state.selectedCancelledCheque) {
      //   await this.uploadFileToS3(
      //     this.state.selectedCancelledCheque,
      //     "cancelledCheque"
      //   );
      // }
    } catch (error) {
      console.error(error);
      this.setState({
        alertType: "error",
        alertMsg:
          error?.response?.data?.message || "Something went wrong, try again",
        isLoading: false,
      });
    }
  };

  onSavePasswordChange = async () => {
    const { currentPassword, newPassword } = this.state;
    const { partnerDetail } = this.props;
    const { _id } = partnerDetail;

    this.setState({ isLoading: true });

    // Validate required fields
    if (!currentPassword || !newPassword) {
      this.setState({
        alertType: "error",
        alertMsg: "Please enter both the current and new password.",
        isLoading: false,
      });
      return;
    }
    const passwordFields = {
      currentPassword,
      newPassword,
      _id,
    };
    try {
      const response = await axios.post(
        `${APIURL}/affiliates/reset-profile-password`,
        passwordFields
      );

      const { message } = response.data;
      await this.props.fetchPartnerDetails();

      this.setState({
        alertType: "success",
        alertMsg: message || "Password updated successfully",
        isLoading: false,
        changePassword: false,
        isEditing: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        alertType: "error",
        alertMsg: error?.response?.data || "Something went wrong, try again",
        isLoading: false,
      });
    }
  };

  validateFormValues = () => {
    const { formValues } = this.state;
    const requiredFields = [
      "legalName",
      "bankAccountNumber",
      "accountName",
      "ifscCode",
      "panNumber",
      "confirmAccountNumber",
    ];

    return requiredFields.every((field) => formValues[field] !== "");
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleFileChange = async (event) => {
    const { partnerDetail } = this.props;
    const { _id } = partnerDetail;
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("_id", _id);
    formData.append("file", file);
    try {
      const fileResponse = await axios.post(
        `${APIURL}/affiliates/upload-file-to-s3`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.setState((prevState) => ({
        selectedFile: file,
        formValues: {
          ...prevState.formValues,
          panImage: fileResponse.data.fileData,
        },
      }));
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  handleCancelledChequeChange = async (event) => {
    const { partnerDetail } = this.props;
    const { _id } = partnerDetail;
    const selectedChequeFile = event.target.files[0];
    const formData = new FormData();
    formData.append("_id", _id);
    formData.append("file", selectedChequeFile);

    try {
      const fileResponse = await axios.post(
        `${APIURL}/affiliates/upload-file-to-s3`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.setState((prevState) => ({
        selectedCancelledCheque: selectedChequeFile,
        formValues: {
          ...prevState.formValues,
          cancelledCheque: fileResponse.data.fileData,
        },
      }));
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  handleCountryChange = (e, value) => {
    const selectedCountry = countriesTimezones.find(
      (country) => country.country === value?.country
    );

    this.setState({
      formValues: {
        ...this.state.formValues,
        companyCountry: value?.country || "",
        state: "",
        timezone: selectedCountry?.timezone || "",
      },
      availableStates: selectedCountry?.states || [],
    });
  };

  render() {
    const { classes, agent } = this.props;
    const { isEditing, isLoading, changeName } = this.state;

    return (
      <Grid container className={classes.root}>
        {/* Sidebar visible on screens 768px and above */}
        <Hidden only={["xs"]}>
          <Grid item xs={12} sm={4} md={2} className={classes.sidebarContainer}>
            <Box p={2}>
              <Typography variant="h6">Your Account</Typography>
              <List component="nav">
                <ListItem
                  button
                  onClick={() => this.setState({ tabValue: 0 })}
                  className={classes.sidebarButton}
                >
                  <ListItemText primary="Personal Information" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => this.setState({ tabValue: 1 })}
                  className={classes.sidebarButton}
                >
                  <ListItemText primary="Bank Details" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => this.setState({ tabValue: 2 })}
                  className={classes.sidebarButton}
                >
                  <ListItemText primary="Account Settings" />
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Hidden>

        {/* Horizontal Tabs for screens less than 768px */}
        <Hidden smUp>
          <Grid item xs={12} className={classes.sidebarHorizontal}>
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              variant="scrollable"
              scrollButtons="on"
              allowScrollButtonsMobile
            >
              <Tab label="Personal Info" />
              <Tab label="Bank Details" />
              <Tab label="Account Settings" />
            </Tabs>
          </Grid>
        </Hidden>

        {/* Form Container */}
        <Grid item xs={12} sm={8} md={10} className={classes.formContainer}>
          <Box className={classes.profileContainer}>
            <Paper className={classes.profilePaper}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={3}
              >
                <Avatar className={classes.avatar}>
                  {agent?.name?.charAt(0)}
                </Avatar>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                {isEditing && this.state.tabValue !== 0 ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onSave}
                      className={classes.editButton}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={this.onCancel}
                      className={classes.logoutButton}
                    >
                      Cancel
                    </Button>
                  </>
                ) : this.state.tabValue !== 0 ? (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={this.onEdit}
                      className={classes.editButton}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<ExitToAppIcon />}
                      className={classes.logoutButton}
                      onClick={() => this.props.signOut(agent.id)}
                    >
                      Logout
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<ExitToAppIcon />}
                    className={classes.logoutButton}
                    onClick={() => this.props.signOut(agent.id)}
                  >
                    Logout
                  </Button>
                )}
              </Box>

              <Box className={classes.tabContent}>
                {/* Form Fields based on tabValue */}
                {this.state.tabValue === 0 && (
                  <Box p={3}>
                    <Typography variant="h5">Personal Information</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">Name</Typography>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="text"
                          id="name"
                          value={agent.name}
                          placeholder="Enter your name"
                          disabled
                          name="name"
                          fullWidth
                          onChange={this.onChange}
                          className={classes.textFieldRootAutocomplete}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">Address</Typography>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="text"
                          id="address"
                          value={agent.address}
                          placeholder="Enter your address"
                          disabled
                          name="address"
                          fullWidth
                          onChange={this.onChange}
                          className={classes.textFieldRootAutocomplete}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">City</Typography>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="text"
                          id="city"
                          value={agent.city}
                          placeholder="Enter your city"
                          disabled
                          name="city"
                          fullWidth
                          onChange={this.onChange}
                          className={classes.textFieldRootAutocomplete}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">State</Typography>
                        <Autocomplete
                          disableClearable
                          options={this.state.availableStates}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) =>
                            this.setState({
                              formValues: {
                                ...this.state.formValues,
                                state: value,
                              },
                            })
                          }
                          value={this.state.formValues.state}
                          disabled
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="agentState"
                              variant="outlined"
                              margin="dense"
                              placeholder="Select your state"
                              className={classes.textFieldRootAutocomplete}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">Postal Code</Typography>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="text"
                          id="postalCode"
                          value={agent.postalCode}
                          placeholder="Enter your postal code"
                          disabled
                          name="postalCode"
                          fullWidth
                          onChange={this.onChange}
                          className={classes.textFieldRootAutocomplete}
                        />
                        {this.state.postalCodeError && (
                          <Tooltip
                            title="Postal code should not exceed 7 characters"
                            placement="right"
                          >
                            <ErrorOutline color="error" />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">Country</Typography>
                        <Autocomplete
                          disableClearable
                          disabled
                          options={countryData}
                          onFocus={this.changeCountryState}
                          getOptionLabel={(option) => option.country}
                          onChange={this.handleCountryChange}
                          value={
                            countryData.find(
                              (option) => option.code === agent.country
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="country"
                              variant="outlined"
                              margin="dense"
                              placeholder="Select your country"
                              fullWidth
                              className={classes.textFieldRootAutocomplete}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {this.state.tabValue === 1 && (
                  <Box p={3}>
                    <Typography variant="h5">Company Information</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                          Bank Account Number
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="bankAccountNumber"
                          value={this.state.formValues.bankAccountNumber}
                          onChange={this.onChange}
                          disabled={!changeName}
                          className={classes.textFieldRootAutocomplete}
                          InputProps={{
                            endAdornment: this.state.validationErrors
                              .bankAccountNumber && (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    this.state.validationErrors
                                      .bankAccountNumber
                                  }
                                >
                                  <ErrorOutline color="error" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                          Confirm Bank Account Number
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="confirmAccountNumber"
                          value={this.state.formValues.confirmAccountNumber}
                          onChange={this.onChange}
                          disabled={!changeName}
                          className={classes.textFieldRootAutocomplete}
                          InputProps={{
                            endAdornment: this.state.validationErrors
                              .confirmAccountNumber && (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    this.state.validationErrors
                                      .confirmAccountNumber
                                  }
                                >
                                  <ErrorOutline color="error" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                          Account Name<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="accountName"
                          value={this.state.formValues.accountName}
                          onChange={this.onChange}
                          disabled={!changeName}
                          className={classes.textFieldRootAutocomplete}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                          IFSC Code<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="ifscCode"
                          value={this.state.formValues.ifscCode.toUpperCase()}
                          onChange={this.onChange}
                          disabled={!changeName}
                          className={classes.textFieldRootAutocomplete}
                          InputProps={{
                            endAdornment: this.state.validationErrors
                              .ifscCode && (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={this.state.validationErrors.ifscCode}
                                >
                                  <ErrorOutline color="error" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                          PAN Number<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="panNumber"
                          value={this.state.formValues.panNumber}
                          onChange={(e) => {
                            const panNumberUpper = e.target.value.toUpperCase(); // Convert to uppercase
                            this.setState((prevState) => ({
                              formValues: {
                                ...prevState.formValues,
                                panNumber: panNumberUpper,
                              },
                            }));
                          }}
                          disabled={!changeName}
                          className={classes.textFieldRootAutocomplete}
                          InputProps={{
                            endAdornment: this.state.validationErrors
                              .panNumber && (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={this.state.validationErrors.panNumber}
                                >
                                  <ErrorOutline color="error" />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" mt={2}>
                          <Button
                            variant="contained"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            disabled={!changeName}
                            className={classes.uploadButton}
                          >
                            Upload PAN Card
                            <input
                              type="file"
                              hidden
                              onChange={this.handleFileChange}
                            />
                          </Button>

                          {this.state.selectedFile && (
                            <Box ml={2}>
                              <Typography variant="body1">
                                {this.state.selectedFile.name}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography>
                            Note<span style={{ color: "red" }}>*</span>: Please
                            upload a file less than 1 MB
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" mt={2}>
                          <Button
                            variant="contained"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            disabled={!changeName}
                            className={classes.uploadButton}
                          >
                            Upload Cancelled Cheque
                            <input
                              type="file"
                              hidden
                              onChange={this.handleCancelledChequeChange}
                            />
                          </Button>

                          {this.state.selectedCancelledCheque && (
                            <Box ml={2}>
                              <Typography variant="body1">
                                {this.state.selectedCancelledCheque.name}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography>
                            Note<span style={{ color: "red" }}>*</span>: Please
                            upload a file less than 1 MB
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Checkbox for "Are you a company?" */}
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.isCompany}
                              onChange={(e) =>
                                this.setState({ isCompany: e.target.checked })
                              }
                              name="isCompany"
                              color="primary"
                            />
                          }
                          label="Are you a company?"
                        />
                      </Grid>

                      {/* Conditionally render the company form */}
                      {this.state.isCompany && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                              Legal Company Name
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              name="legalName"
                              value={this.state.formValues.legalName}
                              onChange={this.onChange}
                              disabled={!changeName}
                              className={classes.textFieldRootAutocomplete}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                              Company Address
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              name="companyAddress"
                              value={this.state.formValues.companyAddress}
                              onChange={this.onChange}
                              disabled={!changeName}
                              className={classes.textFieldRootAutocomplete}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                              Country<span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Autocomplete
                              disableClearable
                              options={countriesTimezones}
                              getOptionLabel={(option) => option.country}
                              onChange={this.handleCountryChange}
                              value={
                                countriesTimezones.find(
                                  (option) =>
                                    option.country ===
                                    this.state.formValues.companyCountry
                                ) || null
                              }
                              disabled={!changeName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select your country"
                                  margin="dense"
                                  className={classes.textFieldRootAutocomplete}
                                />
                              )}
                            />
                          </Grid>
                          {this.state.availableStates.length > 0 && (
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1">
                                State<span style={{ color: "red" }}>*</span>
                              </Typography>
                              <Autocomplete
                                disableClearable
                                options={this.state.availableStates}
                                getOptionLabel={(option) => option}
                                onChange={(e, value) =>
                                  this.setState({
                                    formValues: {
                                      ...this.state.formValues,
                                      state: value,
                                    },
                                  })
                                }
                                value={this.state.formValues.state}
                                disabled={!changeName}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select your state"
                                    margin="dense"
                                    className={
                                      classes.textFieldRootAutocomplete
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                              Company City
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              name="companyCity"
                              value={this.state.formValues.companyCity}
                              onChange={this.onChange}
                              disabled={!changeName}
                              className={classes.textFieldRootAutocomplete}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1">
                              Company Postal Code
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              name="companyPostal" // This should match the key in formValues
                              value={this.state.formValues.companyPostal}
                              onChange={this.onChange}
                              disabled={!this.state.isEditing}
                              className={classes.textFieldRootAutocomplete}
                              InputProps={{
                                endAdornment: this.state.postalCodeError && (
                                  <InputAdornment position="end">
                                    <Tooltip title="Postal code should not exceed 7 characters">
                                      <ErrorOutline color="error" />
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}

                {/* {this.state.tabValue === 1 &&
                  agent.businessType !== "Business" && (
                    <Box p={3}>
                      <Typography variant="h5">Bank Details</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1">
                            Bank Account Number
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            name="bankAccountNumber"
                            value={this.state.formValues.bankAccountNumber}
                            onChange={this.onChange}
                            disabled={!changeName}
                            className={classes.textFieldRootAutocomplete}
                            InputProps={{
                              endAdornment: this.state.validationErrors
                                .bankAccountNumber && (
                                <InputAdornment position="end">
                                  <Tooltip
                                    title={
                                      this.state.validationErrors
                                        .bankAccountNumber
                                    }
                                  >
                                    <ErrorOutline color="error" />
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1">
                            Confirm Bank Account Number
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            name="confirmAccountNumber"
                            value={this.state.formValues.confirmAccountNumber}
                            onChange={this.onChange}
                            disabled={!changeName}
                            className={classes.textFieldRootAutocomplete}
                            InputProps={{
                              endAdornment: this.state.validationErrors
                                .confirmAccountNumber && (
                                <InputAdornment position="end">
                                  <Tooltip
                                    title={
                                      this.state.validationErrors
                                        .confirmAccountNumber
                                    }
                                  >
                                    <ErrorOutline color="error" />
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1">
                            Account Name
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            name="accountName"
                            value={this.state.formValues.accountName}
                            onChange={this.onChange}
                            disabled={!changeName}
                            className={classes.textFieldRootAutocomplete}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1">IFSC Code</Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            name="ifscCode"
                            value={this.state.formValues.ifscCode}
                            onChange={this.onChange}
                            disabled={!changeName}
                            className={classes.textFieldRootAutocomplete}
                            InputProps={{
                              endAdornment: this.state.validationErrors
                                .ifscCode && (
                                <InputAdornment position="end">
                                  <Tooltip
                                    title={this.state.validationErrors.ifscCode}
                                  >
                                    <ErrorOutline color="error" />
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1">
                            PAN Number
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            name="panNumber"
                            value={this.state.formValues.panNumber}
                            onChange={this.onChange}
                            disabled={!changeName}
                            className={classes.textFieldRootAutocomplete}
                            InputProps={{
                              endAdornment: this.state.validationErrors
                                .panNumber && (
                                <InputAdornment position="end">
                                  <Tooltip
                                    title={
                                      this.state.validationErrors.panNumber
                                    }
                                  >
                                    <ErrorOutline color="error" />
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Box display="flex" alignItems="center" mt={2}>
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<CloudUploadIcon />}
                          disabled={!changeName}
                          className={classes.uploadButton}
                        >
                          Upload PAN Card
                          <input
                            type="file"
                            hidden
                            onChange={this.handleFileChange}
                          />
                        </Button>
                        {this.state.selectedFile && (
                          <Box ml={2}>
                            <Typography variant="body1">
                              {this.state.selectedFile.name}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )} */}

                {this.state.tabValue === 2 && (
                  <Box p={3}>
                    <Typography variant="h5">Account Settings</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                          Current Password
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          type={this.state.showPassword ? "text" : "password"}
                          value={this.state.currentPassword}
                          onChange={this.handlePasswordChange}
                          name="currentPassword"
                          disabled={!this.state.changePassword}
                          className={classes.textFieldRootAutocomplete}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={() =>
                                  this.setState({
                                    showPassword: !this.state.showPassword,
                                  })
                                }
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                          New Password
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          type={this.state.showPassword ? "text" : "password"}
                          value={this.state.newPassword}
                          onChange={this.handlePasswordChange}
                          name="newPassword"
                          disabled={!this.state.changePassword}
                          className={classes.textFieldRootAutocomplete}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={() =>
                                  this.setState({
                                    showPassword: !this.state.showPassword,
                                  })
                                }
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>
        </Grid>

        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </Grid>
    );
  }
}

const countryData = countriesTimezones.map((option) => option);

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "whitesmoke",
    boxSizing: "border-box",
  },
  sidebarContainer: {
    backgroundColor: "#ffffff",
    boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
    overflowY: "auto",
    height: "100vh",
  },
  formContainer: {
    overflowY: "auto",
    height: "100vh",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      height: "100vh",
    },
  },
  progress: {
    position: "fixed",
    top: "8em",
    right: "44.5vw",
  },
  snackbar: {
    top: theme.spacing(9),
  },
  disabledField: {
    cursor: "not-allowed",
  },
  textFieldRootAutocomplete: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    padding: "2px 8px",
    marginTop: "2%",
    background: "white",
    border: "2px solid grey",
    justifyContent: "center",
    alignItems: "center",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  scrollableContent: {
    height: "100vh",
    maxHeight: "100vh",
    overflowY: "auto",
  },
  sidebar: {
    backgroundColor: "#ffffff",
    boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
  },
  sidebarHorizontal: {
    display: "flex",
    backgroundColor: "#ffffff",
    justifyContent: "center",
    boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      overflowX: "auto",
      whiteSpace: "nowrap",
    },
  },
  profileGrid: {
    justifyContent: "center",
    marginBottom: "3%",
  },
  profileContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: "3%",
  },
  profilePaper: {
    paddingTop: "3%",
    width: "100%",
    overflowY: "auto",
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: "40px",
  },
  editButton: {
    marginRight: theme.spacing(2),
  },
  logoutButton: {
    backgroundColor: "#ffffff",
  },
  editable: {
    backgroundColor: "#ffffff",
  },
  readOnly: {
    backgroundColor: "#f0f0f0",
  },
  uploadButton: {
    backgroundColor: "#012d62",
    color: "white",
  },
  tabContent: {
    display: "content",
    marginTop: theme.spacing(4),
    width: "100%",
    marginBottom: "3%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "30%",
    },
  },
  sidebarButton: {
    width: "100%",
    maxWidth: "200px",
    minWidth: "150px",
    textAlign: "left",
    paddingLeft: "0",
    paddingRight: "0",
  },
});

const connectedAgentProfilePage = connect(
  (state) => ({
    agent: state.partner.partner,
    partnerDetail: state.partner.partner,
  }),
  { modifyUser, signOut, fetchPartnerDetails }
)(AgentProfilePage);

export default withStyles(styles)(connectedAgentProfilePage);
