import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { APIURL } from "../../../config/config";
import {
  Typography,
  withStyles,
  Box,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ComposedGraph from "../../../commons/Graphs/ComposedGraph";
import DateFilter from "../../../commons/Filters/DateFilter";

const current = moment();
const INTERVAL_VALUES = {
  Today: {
    fromDate: current.clone().startOf("day"),
    toDate: current,
  },
  Yesterday: {
    fromDate: current.clone().subtract(1, "days").startOf("day"),
    toDate: current.clone().subtract(1, "days").endOf("day"),
  },
  "Last 7 days": {
    fromDate: current.clone().subtract(7, "days").startOf("day"),
    toDate: current,
  },
  "Last 30 days": {
    fromDate: current.clone().subtract(30, "days").endOf("day"),
    toDate: current,
  },
};
const INTERVALS = Object.keys(INTERVAL_VALUES);

function AffiliateAnalytics(props) {
  const { classes, projectId, partnerId, currency } = props;
  const [loading, setLoading] = useState(true);
  const [interval, setInterval] = useState("Last 7 days");
  const [graphData, setGraphData] = useState([]);
  const [startDate, setStartDate] = useState(
    INTERVAL_VALUES[interval].fromDate
  );
  const [endDate, setEndDate] = useState(INTERVAL_VALUES[interval].toDate);
  const [focusedInput, setFocusedInput] = useState();
  const eventTypes = {
    sent: 0,
    delivered: 0,
    read: 0,
    failed: 0,
  };

  const [stats, setStats] = useState({ ...eventTypes });
  const [analytics, setAnalytics] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("ALL");
  const [showReport, setShowReport] = useState(false);
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      const weekly = INTERVAL_VALUES["Last 7 days"];
      getAnalytics(weekly.fromDate, weekly.toDate);
      didMount.current = true;
      return;
    }
  }, [selectedCountry]);

  const getAnalytics = async (startDate, endDate) => {
    try {
      // let url = APIURL + `/affiliates/affiliate-analytics`;
      // if (startDate) url += `&startDate=${startDate}`;
      // if (endDate) url += `&endDate=${endDate}`;
      // const { data } = await axios.get(url);
      // setAnalytics(data.AffiliateAnalytics);
      setLoading(false);
    } catch (err) {
      console.log("Unable to fetch WCC Analytics ->", err);
      setLoading(false);
    }
  };

  const setDateRange = (startDate, endDate) => {
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
      setLoading(true);
      getAnalytics(startDate, endDate);
    }
  };

  const setFocus = (focusedInput) => {
    const picker = document.getElementsByClassName("DateInput");
    if (picker[0])
      picker[0].style.border =
        focusedInput === "startDate" ? "1px solid grey" : "1px solid lightgrey";
    if (picker[1])
      picker[1].style.border =
        focusedInput === "endDate" ? "1px solid grey" : "1px solid lightgrey";
    setFocusedInput(focusedInput);
  };
  const keys = ["Sent", "Delivered", "Read", "Failed"];

  return (
    <Box width="100%">
      <Grid
        container
        alignItems="center"
        className={classes.dateFilterContainer}
        style={{ margin: "1em", overflow: "hidden" }}
      >
        <Box
          p="0em 2em"
          mt="1em"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h5" component="span">
              Analytics
            </Typography>
            <Typography variant="body2" component="span">
              {window.innerWidth < 400 ? " / day" : " (per day)"}
            </Typography>
          </Box>
          {/* <Box>
            <Button
              style={{
                left: "4px",
              }}
              color="primary"
              size="small"
              onClick={() => {
                setShowReport(true);
              }}
              variant="outlined"
              startIcon={<GetApp />}
            >
              {window.innerWidth < 400 ? "Report" : "Download Report"}
            </Button>
          </Box> */}
        </Box>

        <React.Fragment>
          <Grid item>
            <Box p={{ xs: 2, md: 3 }} mr={2}>
              <Autocomplete
                disableClearable
                value={interval}
                options={INTERVALS.map((option) => option)}
                onChange={(e, value) => {
                  const obj = INTERVAL_VALUES[value];
                  setInterval(value);
                  setStartDate(obj.fromDate);
                  setEndDate(obj.toDate);
                  setLoading(true);
                  getAnalytics(obj.fromDate, obj.toDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    InputProps={{ ...params.InputProps }}
                    placeholder="Select duration"
                    name="Duration"
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item>
            <DateFilter
              startDate={startDate}
              endDate={endDate}
              focusedInput={focusedInput}
              setDate={setDateRange}
              setFocus={setFocus}
              classes={classes}
              minDate={moment(new Date("04/25/2023"))}
            />
          </Grid>

          {/* <Grid item xs={12} container>
            <Grid item xs={6} sm={3} className={classes.dataContainer}>
              <Typography variant="body2" gutterBottom>
                Sent
              </Typography>
              <Typography variant="body2">{stats.sent}</Typography>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.dataContainer}>
              <Typography variant="body2" gutterBottom>
                Delivered
              </Typography>
              <Typography variant="body2">{stats.delivered}</Typography>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.dataContainer}>
              <Typography variant="body2" gutterBottom>
                Read
              </Typography>
              <Typography variant="body2">{stats.read}</Typography>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.dataContainer}>
              <Typography variant="body2" gutterBottom>
                Failed
              </Typography>
              <Typography variant="body2">{stats.failed}</Typography>
            </Grid>
          </Grid> */}
        </React.Fragment>

        <ComposedGraph
          data={[...graphData]}
          keys={keys}
          isLoading={loading}
          color="hsl(83deg 91% 50% / 44%)"
        />
      </Grid>
      {/* <DownloadReport
        classes={classes}
        showReport={showReport}
        setShowReport={setShowReport}
        currency={currency}
        projectId={projectId}
        partnerId={partnerId}
        dialogHeading={"Download CSV Report"}
        dialogBody={"Select date range to download combined analytics"}
      /> */}
    </Box>
  );
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // marginBottom: "5em",
    // paddingBottom: "80px"
  },
  roundedBox: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    backgroundColor: "white",
    border: "1px black",
    margin: "1em",
    // padding: "1.2em",
    paddingLeft: "2em",
  },
  messageCountContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    backgroundColor: "white",
    border: "1px black",
    margin: "1em",
    padding: "1.2em",
    paddingLeft: "2em",
    paddingBottom: "10px",
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    boxShadow: "0 0 11px rgb(230 230 230)",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  tabPageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 124,
    boxShadow: "0 0 12px rgb(171 170 170)",
    boxSizing: "border-box",
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  dataContainer: {
    // padding: "2em",
    minWidth: "10vw",
  },
  agentMessage: {
    textAlign: "justify",
    margin: "0.1em 0.5em",
    backgroundColor: "#EBF5F3",
    color: theme.palette.primary.main,
    borderRadius: "22px",
    width: "100%",
    marginTop: "auto",
    overflow: "hidden",
    whiteSpace: "pre-line",
    wordBreak: "break-word",
  },
  textField: {
    width: 200,
    padding: "2px 16px",
  },
  dateFilterContainer: {
    padding: "0.5em",
    backgroundColor: "white",
    borderRadius: "1em",
    margin: "0 1em 1em",
    width: "auto",
  },
  countryDropdown: {
    width: 200,
    float: "right",
    marginLeft: 24,
    padding: "2px 16px",
  },
  totalContainer: {
    [theme.breakpoints.down("md")]: {
      margin: "18px 12px 0 0",
    },
    [theme.breakpoints.up("md")]: {
      margin: "24px 0 0 18px",
    },
  },
  changeQualityStatus: {
    "& .MuiChip-labelSmall": {
      color: "white",
    },
  },
  attachTooltipPopper: {
    "& .MuiTooltip-tooltip": {
      padding: 0,
      background: "#EBF5F3",
      borderRadius: 8,
      boxShadow: "0 0 6px #c6c6c6",
    },
    "& .MuiTooltip-arrow": {
      color: "#EBF5F3",
      filter: "drop-shadow(0px -2px 1px #ddd)",
    },
  },
  tabIndicator_OVERVIEW: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
  },
  tabIndicator_SENT: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "#F2C14E",
  },
  tabIndicator_DELIVERED: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "#BDBF00",
  },
  tabIndicator_READ: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "rgb(8, 207, 101)",
  },
  tabIndicator_REPLIED: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "rgb(83, 189, 235)",
  },
  tabIndicator_CLICKED: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "#a48ed2",
  },
  tabIndicator_FAILED: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "#F78154",
  },
  tabIcon: {
    fontSize: 18,
    position: "relative",
    right: 4,
    top: 4,
  },
  previewWrapper: {
    "& .arrowTop": {
      bottom: -80,
    },
  },
  qualityStatus: {
    "& .MuiChip-label": {
      color: "white",
    },
  },
  menuRoot: {
    top: "44px !important",
    left: "-30px !important",
  },
  dialogContainer: {
    "& .MuiDialogContent-root": { overflow: "hidden" },
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "450px",
        minWidth: "100px",
        height: "550px",
        margin: "0",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "450px",
        height: "550px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

export default withStyles(styles)(AffiliateAnalytics);
