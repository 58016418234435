import React, { Component } from "react";
import axios from "axios";
import { partnerURL } from "../../config/config";
import { URL } from "../../config/config";
import ProjectCard from "./ProjectLayout";
import DeleteConfirm from "../Assistants/ConfirmationDialog";
import moment from "moment";
import dialCodes, {
  countryMap,
  isoMap,
  isoToDialCode,
} from "../../config/dialCodes";
import {
  withStyles,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  Tooltip,
  DialogContent,
  Dialog,
  DialogActions,
  Snackbar,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import {
  ArrowBackOutlined,
  Info,
  LockOpenOutlined,
  Rotate90DegreesCcw,
} from "@material-ui/icons";
import { timeZones } from "../../config/timezone";
import {
  Clear,
  Add,
  Visibility,
  VisibilityOff,
  InfoOutlined,
  CheckCircleOutlined,
} from "@material-ui/icons";
import { Autocomplete, Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreateProjectDialog from "../../commons/Dialog/CreateProjectDialog";
import getFormattedDate from "../../helpers/formatDate";
import Header from "../../commons/Header/Header";
import plansHelper from "../../helpers/plansHelper";
import ResetPasswordDialog from "./ResetPassword";
import { minBusinessFieldsPartners } from "./BusinessDetailsForm";

class ClientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "",
      alertMsg: "",
      display_name: "",
      email: "",
      contact: "",
      company: "",
      createdOn: "",
      Loading: true,
      projects: [],
      type: "",
      totalproject: 0,
      openProjectDialog: false,
      allBusiness: {},
      clientDialog: false,
      createEmail: null,
      createDisplay_name: null,
      createCompany: null,
      createContact: null,
      currency: "INR",
      timezone: "Asia/Calcutta GMT+05:30",
      countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
      industry: null,
      companySize: null,
      password: null,
      projectSubmitLoader: false,
      newProjectName: "",
      buttonDisable: false,
      assistantFilter: "",
      searchdone: "",
      reqId4Business: "",
      busineesPassword: "",
      isResetEnabled: true,
      openResetDialog: false,
      projectSubmitLoader2: false,
      deleteDialog: false,
      reportLoading: false,
    };
  }
  componentDidMount() {
    // this.fetchBusiness();
    this.fetchProject();
    this.fetchAllBusiness();
  }
  fetchBusiness() {
    const partnerId = this.props.user.id;
    const _id = this.props.match.params._id;
    axios
      .get(partnerURL + `/partner/${partnerId}/business/${_id}`)
      .then((response) => {
        const {
          company,
          contact,
          display_name,
          email,
          type,
          createdOn,
          companySize,
          id,
          password,
        } = response.data;
        this.setState({
          display_name: display_name,
          email: email,
          contact: contact,
          company: company,
          type: type,
          createdOn,
          companySize,
          reqId4Business: id,
          busineesPassword: password,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  setSnackbar = (alert) => {
    this.setState({
      alertMsg: alert.msg,
      alertType: alert.severity,
    });
  };
  handleInput = (e) => {
    this.setState({ assistantFilter: e.target.value });
  };
  handleEnter = (e) => {
    // const { backupSearchTemplates, search } = this.state;
    if (e.key === "Enter") {
      if (!this.state.assistantFilter) {
        this.setState({
          searchdone: "not done",
        });
      } else {
        this.setState({
          searchdone: "its done",
        });
      }
      this.fetchProject(this.state.assistantFilter);

      this.setState({
        projects: [this.props.assistants],
      });
    }
  };
  projectSubmit = (data) => {
    this.setState({ projectSubmitLoader: true });
    const businessId = this.props.match.params._id;
    if (data?.checked && data?.checked === true) {
      data.shippingAddress = data.billingAddress;
    }
    axios
      .post(
        partnerURL +
          `/partner/${this.props.partnerId}/business/${businessId}/project`,
        {
          ...data,
        }
      )
      .then((response) => {
        this.setState({
          alertType: "success",
          alertMsg: "Successfully created",
          projectSubmitLoader: false,
          openProjectDialog: false,
        });
        this.fetchProject();
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, tryagain",
          projectSubmitLoader: false,
        });
      });
  };

  deleteBusiness = () => {
    this.setState({ projectSubmitLoader2: true });
    const businessId = this.props.match.params._id;
    axios
      .post(URL + `/api/delete-business/${businessId}`)
      .then((response) => {
        this.setState({
          alertType: "success",
          alertMsg: response.data.message,
          projectSubmitLoader2: false,
          openProjectDialog: false,
        });
        this.props.history.push("/businesses");
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, tryagain",
          projectSubmitLoader2: false,
        });
      });
  };

  sendResetPasswordMail = () => {
    this.setState({ projectSubmitLoader: true });
    axios
      .post(`${URL}/auth/send-password-reset-email`, {
        email: this.state.email,
      })
      .then((response) => {
        this.setState({
          projectSubmitLoader: false,
          isResetEnabled: false,
          alertType: "success",
          alertMsg:
            "Account password setup link has been successfully sent to the business email",
        });

        setTimeout(() => {
          this.setState({
            isResetEnabled: true,
          });
        }, 5 * 60 * 1000);
      })
      .catch((error) => {
        this.setState({
          projectSubmitLoader: false,
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, try again",
        });
      });
  };

  fetchProject(serch) {
    const clientId = this.props.match.params._id;
    axios
      .post(URL + `/api/business-projects`, {
        name: serch,
        clientId,
      })
      .then((response) => {
        const { projects, totalprojects } = response.data;
        this.setState({
          projects: projects,
          totalproject: totalprojects,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleBusinessInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleclientDialog = () => {
    this.setState({ clientDialog: !this.state.clientDialog });
  };
  toggleResetDialog = () => {
    this.setState({ openResetDialog: !this.state.openResetDialog });
  };
  fetchAllBusiness() {
    const user = this.props.user;
    axios
      .get(
        URL +
          `${
            user.isReferral
              ? `/api/partner-referral-allbusiness-lists?partnerReferralId=${user.id}`
              : `/api/allbusiness-lists`
          }`,
        {
          skip: 0,
          rowsPerPage: 0,
          partnerReferralId: user.id,
        }
      )
      .then((response) => {
        const { business } = response.data;
        let businessObj = {};
        business.forEach((item) => {
          businessObj[item._id] = item;
        });
        this.setState({
          allBusiness: { ...businessObj },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  createclient = async () => {
    try {
      const partnerId = this.props.user.id;
      const {
        createEmail,
        createDisplay_name,
        createCompany,
        createContact,
        timezone,
        password,
      } = this.state;
      const queryObj = {
        display_name: createDisplay_name,
        email: createEmail,
        contact: createContact,
        company: createCompany,
        password,
        timezone,
        currency: this.props?.partner?.currency ?? "INR",
      };

      let response = await axios.post(
        partnerURL + `/partner/${partnerId}/business/`,
        queryObj
      );
      // this.props.createnewClient(response.data)
      this.fetchAllBusiness();
      this.setState({
        clientDialog: !this.state.clientDialog,
        openProjectDialog: true,
      });
      this.setState({
        status: "success",
        statusMessage: "Business Created Succesfully",
      });
      this.setState({
        createEmail: null,
        createDisplay_name: null,
        createCompany: null,
        createContact: null,
        currency: "INR",
        timezone: "Asia/Calcutta GMT+05:30",
        countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
        industry: null,
        companySize: null,
        password: null,
      });
    } catch (error) {
      this.setState({
        buttonDisable: false,
      });
      const errorResponse = error.response;
      console.error(error);
      const errorMessage = errorResponse?.data?.message;
      this.setState({
        status: "error",
        statusMessage: errorMessage,
      });
    }
  };
  buttondisbale = () => {
    this.setState({ buttonDisable: !this.state.buttonDisable });
  };

  projectDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openProjectDialog: !this.state.openProjectDialog });
  };

  selectBusiness = (e, value, reason) => {
    if (reason === "select-option") {
      const assistantValue = Object.keys(this.state.allBusiness).find(
        (key) => this.state.allBusiness[key].name === value.split(" - ")[1]
      );
      this.setState({ selectBusiness: assistantValue });
    }
  };

  handleClear = () => {
    this.setState({
      assistantFilter: "",
    });
    this.setState({
      searchdone: "not done",
    });
    this.fetchProject();
  };

  getConvoReport = async (startDate, endDate) => {
    const clientId = this.props.match.params._id;
    const partnerId = this.props.user.id;
    this.setState({ reportLoading: true });

    try {
      const response = await axios.post(
        partnerURL +
          `/partner/${partnerId}/business/${clientId}/business-convo-report`,
        { startDate, endDate },
        { responseType: "stream" }
      );

      const report = response.data?.logs || [];
      const headers = Array.from(
        new Set(report.flatMap((entry) => Object.keys(entry)))
      );
      let csvContent = headers.join(",") + "\n";

      report.forEach((entry) => {
        headers.forEach((header) => {
          const cellContent = entry[header]
            ? `"${entry[header].replace(/\n/g, "\n")}"`
            : "";
          csvContent += cellContent + ",";
        });
        csvContent = csvContent.slice(0, -1);
        csvContent += "\n";
      });

      // Download the file as CSV
      var downloadLink = document.createElement("a");
      const blob = new Blob([csvContent], { type: "text/csv" });
      var url = window.URL.createObjectURL(blob);
      downloadLink.href = url;

      // Format file name
      let start = moment(startDate, "YYYY-MM-DD").isValid()
        ? moment(startDate).format("DD-MM-YYYY")
        : null;
      let end = moment(endDate, "YYYY-MM-DD").isValid()
        ? moment(endDate).format("DD-MM-YYYY")
        : null;

      if (startDate && endDate) {
        downloadLink.download = `Business_Conversation_Report_${start}_to_${end}.csv`;
      } else {
        downloadLink.download = `Business_Conversation_Report.csv`;
      }

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      this.setState({
        reportLoading: false,
        alertMsg: "Successfully Downloaded!",
        alertType: "success",
      });
    } catch (error) {
      this.setState({
        reportLoading: false,
        alertMsg: error?.response?.data?.message || "Error downloading report!",
        alertType: "error",
      });
      console.log(error);
    }
  };

  render() {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const partnerId = this.props.user.id;
    const isPasswordValid = passwordRegex.test(this.state.password);
    const {
      classes,
      match,
      business,
      user,
      partner,
      partnerPlanFamily,
      partnerWccFamily,
    } = this.props;
    const { type } = partner || {};
    const toggleDialog = () => {
      this.props.history.goBack();
    };
    const SIZE = [
      "1 - 10 Employees",
      "10 - 20 Employees",
      "20 - 50 Employees",
      "50 - 200 Employees",
      "200 + Employees",
    ];
    const getflag = (langcode) => {
      var first = langcode.charCodeAt(0) + 127397;
      var second = langcode.charCodeAt(1) + 127397;
      var flag = `&#${first};&#${second};`;
      const x = document.createElement("p");
      x.innerHTML = flag;
      return x.innerText;
    };

    const VERTICALS = [
      "Ecommerce",
      "Education",
      "Automotive",
      "IT Services",
      "Real Estate",
      "SAAS/Apps",
      "Gaming",
      "Entertainment",
      "Finance and Banking",
      "Medical and Health",
      "Hotel and Lodging",
      "Beauty, Spa and Salon",
      "Clothing and Apparel",
      "Event Planning and Service",
      "Food and Grocery",
      "Professional Services",
      "Public Service",
      "Non-profit",
      "Shopping and Retail",
      "Travel and Transportation",
      "Restaurant",
      "Other",
    ];
    return (
      <>
        <Grid container alignItems="center" className={classes.dialogTopbar}>
          <Header
            businessTitle={
              "Business : " + this.state.display_name.split(" ")[0]
            }
            buttonPrefixIcon={<Add />}
            buttonTitle={user.isReferral ? "" : "Create New Project"}
            onButtonClick={this.projectDialog}
            showSearch={true}
            searchPlaceholder={"Search project name"}
            handleSearch={this.handleInput}
            handleEnter={this.handleEnter}
            searchDone={this.state.searchdone === "its done"}
            handleClear={this.handleClear}
            filter={this.state.assistantFilter}
            onBackPress={toggleDialog}
            syncButtonTitle={type === "DIRECT" && "Reset Password"}
            onSyncButtonClick={this.toggleResetDialog}
            syncButtonPrefixIcon={<LockOpenOutlined />}
            businessArchived={!!business[0]?.businessArchived}
            getConvoReport={this.getConvoReport}
            reportLoading={this.state.reportLoading}
            clientReport={true}
          />
        </Grid>
        <div className={classes.root}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ background: "rgb(247 247 247)" }}
          >
            <Grid container item xs={12} md={10} spacing={2}>
              <Grid
                container
                item
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {!this?.state?.busineesPassword &&
                type !== "DIRECT" &&
                business[0].businessArchived !== true ? (
                  <Grid
                    xs={8}
                    padding="10px 36px"
                    style={{
                      background: "#f7ebffdb",
                      display: "flex",
                      alignItems: "center",
                      // marginTop: 12,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography variant="h4" style={{ padding: "10px 10px" }}>
                      Your password setup is incomplete tap Reset Password
                      button to set your password
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  {!this?.state?.busineesPassword &&
                  type !== "DIRECT" &&
                  business[0].businessArchived !== true ? (
                    <Tooltip
                      title={
                        this.state.isResetEnabled
                          ? "Reset Password"
                          : "Retry again a in few mins"
                      }
                    >
                      <span>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.sendResetPasswordMail}
                          disabled={
                            !this.state.isResetEnabled ||
                            this.state.projectSubmitLoader
                          }
                          endIcon={
                            this.state.projectSubmitLoader ? (
                              <CircularProgress size={18} />
                            ) : (
                              ""
                            )
                          }
                        >
                          Reset Password
                        </Button>
                      </span>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  {business[0].businessArchived !== true ? (
                    <Tooltip
                      title={
                        this.state.isResetEnabled
                          ? "Delete Business"
                          : "Retry again a in few mins"
                      }
                    >
                      <span style={{ marginLeft: "8px" }}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            this.setState({
                              deleteDialog: true,
                            });
                          }}
                          disabled={
                            !this.state.isResetEnabled ||
                            this.state.projectSubmitLoader2
                          }
                          endIcon={
                            this.state.projectSubmitLoader2 ? (
                              <CircularProgress size={18} />
                            ) : (
                              ""
                            )
                          }
                        >
                          Delete Business
                        </Button>
                      </span>
                    </Tooltip>
                  ) : (
                    <></>
                  )}

                  <DeleteConfirm
                    openDialog={this.state.deleteDialog}
                    closeDialog={() => {
                      this.setState({
                        deleteDialog: false,
                      });
                    }}
                    body={
                      "Are you sure that you want to delete this business ?"
                    }
                    deleteLoading={this.state.projectSubmitLoader2}
                    action={this.deleteBusiness}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={10} lg={10} spacing={2}>
              <Grid item xs>
                <div className={classes.roundedBox}>
                  <Grid container style={{ marginBottom: "0.4em" }}>
                    <Grid item xs={12} container alignItems="center">
                      <Typography variant="body2" gutterBottom>
                        Total Project
                      </Typography>
                      <Box mb="4px" ml={1}>
                        <Tooltip
                          enterTouchDelay={0}
                          style={{ fontSize: "1.2em" }}
                          placement="top"
                          title="Total number of projects present of this business"
                        >
                          <Info color="disabled" />
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                      <Typography variant="h3" style={{ fontWeight: "bold" }}>
                        <span
                          style={{
                            fontSize: 16,
                            marginRight: 2,
                            fontFamily: "Roboto",
                          }}
                        >
                          {this.state.projects.length}
                        </span>
                      </Typography>
                      <Box ml={4}></Box>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs>
                <div className={classes.roundedBox}>
                  <Grid container style={{ marginBottom: "0.4em" }}>
                    <Grid item xs={12} container alignItems="center">
                      <Typography variant="body2" gutterBottom>
                        WABA Live
                      </Typography>
                      <Box mb="4px" ml={1}>
                        <Tooltip
                          enterTouchDelay={0}
                          style={{ fontSize: "1.2em" }}
                          placement="top"
                          title={
                            "Total number of projects whose Whatsapp Business API are live"
                          }
                        >
                          <Info color="disabled" />
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                      <Typography variant="h3" style={{ fontWeight: "bold" }}>
                        <span
                          style={{
                            fontSize: 16,
                            marginRight: 2,
                            fontFamily: "Roboto",
                          }}
                        >
                          {"0"}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs>
                <div className={classes.roundedBox}>
                  <Grid container style={{ marginBottom: "0.4em" }}>
                    <Grid item xs={12} container alignItems="center">
                      <Typography variant="body2" gutterBottom>
                        Created On
                      </Typography>
                      <Box mb="4px" ml={1}>
                        <Tooltip
                          enterTouchDelay={0}
                          style={{ fontSize: "1.2em" }}
                          placement="top"
                          title={"Date on which the business was registered"}
                        >
                          <Info color="disabled" />
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                      <Typography variant="h3" style={{ fontWeight: "bold" }}>
                        <span
                          style={{
                            fontSize: 16,
                            marginRight: 2,
                            fontFamily: "Roboto",
                          }}
                        >
                          {getFormattedDate(new Date(this.state.createdOn))}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs>
                <div className={classes.roundedBox}>
                  <Grid container style={{ marginBottom: "0.4em" }}>
                    <Grid item xs={12} container alignItems="center">
                      <Typography variant="body2" gutterBottom>
                        Company Size
                      </Typography>
                      <Box mb="4px" ml={1}>
                        <Tooltip
                          enterTouchDelay={0}
                          style={{ fontSize: "1.2em" }}
                          placement="top"
                          title="Size of the company"
                        >
                          <Info color="disabled" />
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                      <Typography variant="h3" style={{ fontWeight: "bold" }}>
                        <span
                          style={{
                            fontSize: 16,
                            marginRight: 2,
                            fontFamily: "Roboto",
                          }}
                        >
                          {this.state.companySize ?? 1}+
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={10} spacing={2}>
              <Box style={{ marginBottom: "1.7em" }}>
                <Grid className={classes.roundedBox2}>
                  <Grid xs spacing={1}>
                    <Grid item xs>
                      <Typography variant="h3" style={{ fontWeight: "bold" }}>
                        Business Details
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <div className={classes.roundedBox3}>
                        <Grid
                          container
                          justify={"space-between"}
                          style={{ marginBottom: "0.4em" }}
                        >
                          <Grid item>
                            <Grid item xs={12} container alignItems="center">
                              <Typography variant="body2" gutterBottom>
                                Company Name
                              </Typography>
                              <Box mb="4px" ml={1}>
                                <Tooltip
                                  enterTouchDelay={0}
                                  style={{ fontSize: "1.2em" }}
                                  placement="top"
                                  title="Name of the business"
                                >
                                  <Info color="disabled" />
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={12} container alignItems="center">
                              <Typography
                                variant="h3"
                                style={{ fontWeight: "bold" }}
                              >
                                <span
                                  style={{
                                    fontSize: 16,
                                    marginRight: 2,
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  {this.state.company}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Grid item xs={12} container alignItems="center">
                              <Typography variant="body2" gutterBottom>
                                Mobile Number
                              </Typography>
                              <Box mb="4px" ml={1}>
                                <Tooltip
                                  enterTouchDelay={0}
                                  style={{ fontSize: "1.2em" }}
                                  placement="top"
                                  title="Mobile Number linked with this business"
                                >
                                  <Info color="disabled" />
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={12} container alignItems="center">
                              <Typography
                                variant="h3"
                                style={{ fontWeight: "bold" }}
                              >
                                <span
                                  style={{
                                    fontSize: 16,
                                    marginRight: 2,
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  {this.state.contact}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Grid item xs={12} container alignItems="center">
                              <Typography variant="body2" gutterBottom>
                                Admin Email
                              </Typography>
                              <Box mb="4px" ml={1}>
                                <Tooltip
                                  enterTouchDelay={0}
                                  style={{ fontSize: "1.2em" }}
                                  placement="top"
                                  title="Email linked with this business"
                                >
                                  <Info color="disabled" />
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={12} container alignItems="center">
                              <Typography
                                variant="h3"
                                style={{ fontWeight: "bold" }}
                              >
                                <span
                                  style={{
                                    fontSize: 16,
                                    marginRight: 2,
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  {this.state.email?.startsWith("__archived__")
                                    ? this.state.email.replace(
                                        "__archived__",
                                        ""
                                      )
                                    : this.state.email}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box px={"16px"} mb={"8px"}>
                {this.state.projects.length == 0 ? (
                  <Grid item xs>
                    <Typography
                      variant="h3"
                      style={{
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      No project !!!!
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs>
                    <Typography variant="h2" style={{ fontWeight: "bold" }}>
                      Live Projects
                    </Typography>
                  </Grid>
                )}
              </Box>
              <Box px={"8px"} mb={"8px"}>
                <Grid container spacing={2}>
                  {this.state.projects.map((project) => {
                    return (
                      <Grid item xs={12} md={4} lg={4}>
                        <ProjectCard
                          project={project}
                          //   isRedirectedFrom360={isRedirectedFrom360}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Box my={16} />
            </Grid>
          </Grid>

          {/* project dialog */}
          {this.state.openProjectDialog && (
            <CreateProjectDialog
              showDialog={this.state.openProjectDialog}
              closeDialog={this.projectDialog}
              allBusiness={this.state.allBusiness}
              selectBusiness={this.selectBusiness}
              setProjectName={(e) =>
                this.setState({ newProjectName: e.target.value })
              }
              createBusiness={() =>
                this.setState({
                  openProjectDialog: false,
                  clientDialog: true,
                })
              }
              onSubmit={this.projectSubmit}
              disableSubmit={this.state.projectSubmitLoader}
              reqId4Business={this.state.reqId4Business}
              planFamilies={partnerPlanFamily}
              defaultPlanFamily={partner?.defaultPlanFamily}
              defaultPlanName={partner?.defaultPlanName}
              wccPlans={partnerWccFamily}
              defaultWccPlan={partner?.defaultWccPlan}
              type={type}
              partner={partner}
            />
          )}

          {/* reset password dialog */}
          {this.state.openResetDialog && (
            <ResetPasswordDialog
              showDialog={this.state.openResetDialog}
              toggleDialog={this.toggleResetDialog}
              clientId={this.props.match?.params?._id}
              setSnackbar={this.setSnackbar}
              classes={classes}
            />
          )}

          <Dialog open={this.state.clientDialog}>
            <DialogContent style={{ marginRight: 8, width: 500 }}>
              <Grid container justifyContent="flex-end">
                <IconButton onClick={this.toggleclientDialog}>
                  <Clear />
                </IconButton>
              </Grid>

              <Typography
                variant="h3"
                style={{
                  marginBottom: "16px",
                }}
                color="primary"
              >
                Create Your Business Here
              </Typography>

              <TextField
                id="name"
                label="Full Name"
                variant="outlined"
                type="text"
                name="createDisplay_name"
                onChange={this.handleBusinessInput}
                value={this.state.createDisplay_name}
                className={classes.textFieldRoot}
              />
              <TextField
                id="email"
                label="Email Id"
                variant="outlined"
                type="text"
                name="createEmail"
                onChange={this.handleBusinessInput}
                value={this.state.createEmail}
                className={classes.textFieldRoot}
              />
              <div>
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  name="password"
                  onChange={this.handleBusinessInput}
                  value={this.state.password}
                  className={classes.textFieldRoot}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            isPasswordValid
                              ? ""
                              : "Minimum eight characters, at least one letter, one number"
                          }
                        >
                          {isPasswordValid ? (
                            <CheckCircleOutlined
                              className={classes.passwordIcon}
                              style={{ color: "#00d064" }}
                            />
                          ) : (
                            <InfoOutlined
                              className={classes.passwordIcon}
                              style={{ color: "#c3c3c3" }}
                            />
                          )}
                        </Tooltip>
                        <IconButton
                          className={classes.visiblityButton}
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowConfirmPassword}
                        >
                          {this.state.howConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {!minBusinessFieldsPartners.includes(partnerId) && (
                <TextField
                  id="company"
                  label="Company Name"
                  variant="outlined"
                  type="text"
                  name="createCompany"
                  onChange={this.handleBusinessInput}
                  value={this.state.createCompany}
                  className={classes.textFieldRoot}
                />
              )}
              {!minBusinessFieldsPartners.includes(partnerId) && (
                <Autocomplete
                  disableClearable
                  options={SIZE.map((option) => option)}
                  onChange={(e, value, reason) => {
                    this.setState({
                      companySize: value,
                    });
                  }}
                  value={this.state.companySize}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company Size"
                      variant="outlined"
                      classes={{ root: classes.textFieldRootAutocomplete }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              )}
              {!minBusinessFieldsPartners.includes(partnerId) && (
                <Autocomplete
                  disableClearable
                  options={VERTICALS.map((option) => option)}
                  onChange={(e, value, reason) => {
                    this.setState({
                      industry: value,
                    });
                  }}
                  value={this.state.industry}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Industry"
                      variant="outlined"
                      classes={{ root: classes.textFieldRootAutocomplete }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              )}

              <Box position="relative">
                <Autocomplete
                  disableClearable
                  options={dialCodes.map((i) => i.dialCode)}
                  classes={{ popper: classes.textFieldPopper }}
                  getOptionLabel={(option) => {
                    return `${getflag(isoMap[option])} ${option} ${
                      countryMap[option.substring(1)]
                    }`;
                  }}
                  onChange={(e, value, reason) => {
                    this.setState({
                      countryCode: value,
                    });
                  }}
                  value={this.state.countryCode}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      classes={{ root: classes.codeTextFieldRoot }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />

                <TextField
                  id="contact"
                  placeholder="Mobile Number"
                  type="text"
                  name="createContact"
                  onChange={this.handleBusinessInput}
                  value={this.state.createContact}
                  className={classes.phoneTextFieldRoot}
                />
              </Box>

              {!minBusinessFieldsPartners.includes(partnerId) && (
                <Autocomplete
                  disableClearable
                  options={["INR", "USD"]}
                  value={this.props?.partner?.currency ?? "INR"}
                  disabled="true"
                  name="currency"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Preferred Billing Currency"
                      classes={{ root: classes.textFieldRootAutocomplete }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              )}
              <Autocomplete
                disableClearable
                options={timeZones.map((option) => option)}
                onChange={(e, value) => {
                  this.setState({
                    timezone: value,
                  });
                }}
                value={this.state.timezone}
                name="timezone"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Timezone"
                    classes={{ root: classes.textFieldRootAutocomplete }}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Grid item container justifyContent="flex-end" xs={12}>
                <Button
                  disabled={
                    !minBusinessFieldsPartners.includes(partnerId)
                      ? !this.state.company ||
                        !this.state.companySize ||
                        !this.state.industry ||
                        !this.state.display_name ||
                        !this.state.contact ||
                        this.state.buttonDisable
                      : !this.state.display_name ||
                        !this.state.email ||
                        !this.state.contact
                  }
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    this.buttondisbale();
                    this.createclient();
                  }}
                  endIcon={
                    this.state.buttonDisable ? (
                      <CircularProgress size={18} />
                    ) : (
                      ""
                    )
                  }
                  style={{ margin: 16 }}
                >
                  Create
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={!!this.state.alertMsg}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={this.onSnackbarClose}
            className={classes.snackbar}
            autoHideDuration={3000}
          >
            <Alert
              severity={this.state.alertType}
              onClose={this.onSnackbarClose}
            >
              {this.state.alertMsg}
            </Alert>
          </Snackbar>
        </div>
      </>
    );
  }
}

const styles = (theme) => ({
  projectdialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflowX: "hidden",
    paddingTop: "15px",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    background: "rgb(248,248,248)",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "20px"
    },
  },
  skeltonContainer: {
    width: "100%",
    borderRadius: "8px",
    height: "270px",
    // background: "rgb(70 20 134 / 15%)",
    background: "white",
  },
  skelton: {
    height: "100%",
    width: "100%",
    margin: "0px",
    transform: "none",
    borderRadius: "8px",
  },
  formCloseButton: {
    padding: 0,
    float: "right",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  roundedBox: {
    minWidth: "100px",
    height: "60px",
    borderRadius: "12px",
    backgroundColor: "white",
    border: "1px black",
    margin: "1em 0em",
    padding: "2em",
  },
  roundedBox3: {
    // height: "45px",
    borderRadius: "12px",
    backgroundColor: "rgb(237, 226, 252)",
    border: "1px black",
    margin: "1em 0em",
    padding: "1em 2em",
    flexWrap: "nowrap",
    lineBreak: "anywhere",
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)", marginLeft: "80px" },
    background: "white",
    border: "2px solid grey",
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  roundedBox2: {
    // width: "100%",
    [theme.breakpoints.down("sm")]: {
      // height: "auto",
      borderRadius: "12px",
      marginRight: "0px",
    },
    height: "100%",
    backgroundColor: "white",
    border: "1px black",
    borderRadius: "8px",
    margin: "0px 8px",
    padding: "1.5em 1.5em 1.5em 1.5em",
    marginBottom: "2em",
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  codeTextFieldRoot: {
    width: "90px",
    padding: "0 0 0 14px",
    position: "absolute",
    zIndex: 1,
    margin: "10px 0 0 2px",
    background: "white",
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "12px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  textField1: {
    width: 300,
    marginRight: 8,
    height: 42,
  },
});
const Templateconnect = connect(
  (state) => ({
    business: state.client?.business,
    user: state.login.user,
    partnerId: state.partner.partner._id,
    assistants: state.assistants.assistants,
    partner: state.partner.partner,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    partnerWccFamily: state.wccPlans.allWccPlans,
  }),
  {}
)(ClientDetails);
export default withStyles(styles)(withRouter(Templateconnect));
