import React, { Component } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  withStyles,
  Box,
  CircularProgress,
  TextField,
  Button,
  Link,
  Snackbar,
  Tooltip,
  IconButton,
  Fade,
  InputAdornment,
} from "@material-ui/core";
import { Autocomplete, Alert } from "@material-ui/lab";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser } from "../Login/loginstate";
import leftImage from "../../static/9978094-new.png";
import logo from "../../static/AiSensy_Logo_Dark_PNG.png";
import { APIURL as url } from "../../config/config";
import dialCodes, { countryMap, isoMap } from "../../config/dialCodes";

const getflag = (langcode) => {
  var first = langcode.charCodeAt(0) + 127397;
  var second = langcode.charCodeAt(1) + 127397;
  var flag = `&#${first};&#${second};`;
  const x = document.createElement("p");
  x.innerHTML = flag;
  return x.innerText;
};

const styles = (theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    margin: 0,
  },
  leftContainer: {
    backgroundColor: "#cbe7e6",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    position: "relative",
    width: "40%",
    height: "100vh",
    overflow: "hidden",
    padding: "0 20px",
    boxSizing: "border-box",
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
  },
  logoLink: {
    position: "absolute",
    top: 20,
    left: 20,
  },
  rightLogoLink: {
    position: "absolute",
    top: "-1.5%",
    left: 5,
    [theme.breakpoints.up(960)]: {
      display: "none",
    },
  },
  logo: {
    width: 250,
    [theme.breakpoints.down(960)]: {
      width: 150,
    },
  },
  leftImageContainer: {
    width: "100%",
    height: "auto",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    position: "relative",
  },
  leftImage: {
    width: "60%",
    maxWidth: "100%",
    height: "auto",
    marginTop: "5%",
    position: "relative",
  },
  centeredWrapper: {
    display: "flex",
    justifyContent: "center",
    margin: "24px 0 0",
    alignItems: "center",
    width: "100%",
  },
  rightContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    height: "100vh",
    position: "relative",
    padding: "20px",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
      padding: "10px",
    },
  },
  formContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "400px",
    alignItems: "center",
    padding: "0 50px 50px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  nextButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "7%",
    width: "100%",
  },
  nextButton: {
    width: "100%",
  },
  googleSignInButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "#0563fa",
    color: "white",
    textTransform: "none",
  },
  signInButton: {
    marginTop: theme.spacing(2),
    textTransform: "none",
    "&.disabled": {
      backgroundColor: "#ddd",
      color: "#888",
    },
    "&.enabled": {
      color: "#fff",
      backgroundColor: "#0a474c",
    },
  },
  orDivider: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: theme.spacing(2, 0),
    "& span": {
      flex: 1,
      borderBottom: "1px solid #ccc",
    },
    "& span:first-child": {
      marginRight: theme.spacing(1),
    },
    "& span:last-child": {
      marginLeft: theme.spacing(1),
    },
  },
  signUpLink: {
    position: "absolute",
    top: 20,
    right: 20,
    [theme.breakpoints.down(960)]: {
      position: "absolute",
      top: "9%",
      right: 20,
    },
    [theme.breakpoints.down(538)]: {
      position: "absolute",
      top: "7%",
      right: 20,
      marginBottom: "2%",
    },
  },
  welcomeText: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(538)]: {
      marginBottom: theme.spacing(1),
      marginTop: "18%",
    },
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  resendOtpButton: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  errorText: {
    color: "red",
    textAlign: "center",
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    "& input": { width: "calc(100% - 80px)", marginLeft: "80px" },
    background: "white",
    border: "2px solid grey",
  },
  otpInput: {
    width: "60px",
    height: "60px",
    background: "white",
    padding: "4px 6px",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 5px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  otpContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 2px",
    marginTop: "4px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  codeTextFieldRoot: {
    width: "80px",
    padding: "0 0 0 14px",
    position: "absolute",
    zIndex: 1,
    margin: "10px 0 0 2px",
    background: "white",
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "12px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  textFieldRoot: {
    backgroundColor: "#f1f1f1",
    outline: "none",
    border: "none",
    borderRadius: "8px",
    width: "100%",
    marginBottom: theme.spacing(3),
    padding: "14px 15px",
    boxShadow: "none",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    fontSize: "15px",
    fontWeight: 500,
  },
  otpButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
  },
  passwordFieldContainer: {
    alignItems: "center",
    position: "relative",
    width: "100%",
  },
  passwordTooltip: {
    position: "absolute",
    right: 10,
  },
  passwordField: {
    flex: 1,
    width: "100%",
  },
  passwordIcon: {
    marginLeft: theme.spacing(1),
    position: "absolute",
    right: "-30px",
    top: "20px",
  },
  passwordIconEmail: {
    marginLeft: theme.spacing(1),
    position: "absolute",
    right: "2%",
    top: "48%",
  },
  passwordIconName: {
    marginLeft: theme.spacing(1),
    position: "absolute",
    right: "2%",
    top: "11%",
  },
  passwordIconCompanyName: {
    marginLeft: theme.spacing(1),
    position: "absolute",
    right: "2%",
    top: "23%",
  },
  passwordIconContact: {
    marginLeft: theme.spacing(1),
    position: "absolute",
    right: "2%",
    top: "49%",
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.main,
  },
});


class AddAffiliatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      alertType: "",
      alertMsg: "",
      formValues: {
        name: "",
        country: "",
        state: "",
        city: "",
        companyName: "",
        contact: "",
        email: "",
        userName: "",
        loginPassword: "",
        type: "Affiliate",
        planType: "tier0",
        timezone: "",
        currency: "",
        partnershipFee: "0",
        otp: ["", "", "", ""],
        facebookAds: "",
        companySize: "",
        industry: [],
        websiteURL: "",
        confirmPassword: "",
      },
      errors: {
        email: "",
        userName: "",
        loginPassword: "",
        otp: "",
        confirmPassword: "",
        name: "",
        contact: "",
        companyName: "",
      },
      passwordValidation: {
        length: false,
        lowercase: false,
        number: false,
        specialChar: false,
      },
      showPasswordTooltip: false,
      generatedOtp: "",
      isOtpVerified: false,
      otpResendTime: 30,
      canResendOtp: false,
      countryCode: "+91",
      contact: "",
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      snackbarError: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  }

  handleChange = (event, value, name) => {
    const { formValues, errors, passwordValidation } = this.state;
    formValues[name] = value;
    errors[name] = "";

    if (name === "loginPassword" || name === "confirmPassword") {
      const length = formValues.loginPassword.length >= 8;
      const lowercase = /[a-z]/.test(formValues.loginPassword);
      const number = /\d/.test(formValues.loginPassword);
      const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(
        formValues.loginPassword
      );

      passwordValidation.length = length;
      passwordValidation.lowercase = lowercase;
      passwordValidation.number = number;
      passwordValidation.specialChar = specialChar;

      errors.loginPassword =
        !length || !lowercase || !number || !specialChar
          ? "Password must be 8 characters long and include at least 1 lowercase letter, 1 number, and 1 special character"
          : "";

      // Validate confirm password
      errors.confirmPassword =
        formValues.confirmPassword !== formValues.loginPassword
          ? "Passwords do not match"
          : "";
    }

    if (name === "email" && !this.validateEmail(value)) {
      errors.email = "Enter a valid email address";
    }

    if (name === "name" && !/^[A-Za-z\s]+$/.test(value)) {
      errors.name = "Name should contain only alphabets and spaces";
    }

    if (name === "contact" && (!/^\d+$/.test(value) || value.length <= 7)) {
      errors.contact = "Contact number should be 7 to 10 digits long";
    }
    if (name === "companyName" && value.length === 0) {
      errors.companyName = "Company Name can't be empty";
    }
    this.setState({ formValues, errors, passwordValidation });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const { formValues, errors } = this.state;

    if (name === "contact") {
      if (/^\d*$/.test(value)) {
        // Only allow digits
        formValues[name] = value;
        if (value.length <= 7) {
          errors.contact = "Contact number should be 7 to 10 digits long";
        } else {
          errors.contact = "";
        }
      }
    } else {
      formValues[name] = value;
    }

    this.setState({ formValues, errors });
  };

  handleOtpChange = (index, event) => {
    const otp = [...this.state.formValues.otp];
    otp[index] = event.target.value;
    if (event.target.value.length === 1 && index < 3) {
      this[`otpInput${index + 1}`].focus();
    }
    this.setState({ formValues: { ...this.state.formValues, otp } });
  };

  handleNext = () => {
    const { step } = this.state;
    if (this.isStepComplete()) {
      if (step === 1) {
        this.generateOtp();
        this.setState({ isLoading: true });
      }
    }
  };

  handleBack = () => {
    this.setState((prevState) => ({ step: prevState.step - 1 }));
  };

  handleSubmit = () => {
    const { formValues, countryCode } = this.state;
    const { userName, loginPassword, email, contact } = formValues;
    const updatedFormValues = { ...formValues, contact: countryCode + contact };

    axios
      .post(url + "/affiliates/create-affiliate", updatedFormValues)
      .then((response) => {
        if (response.status === 200) {
          this.props.loginUser(userName, loginPassword, email);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.message === "Email already in use") {
            this.setState({
              snackbarOpen: true,
              alertType: "error",
              snackbarMessage: "Email already in use",
              snackbarError: true,
            });
          } else if (error.response.data.message === "User blocked") {
            this.setState({
              snackbarOpen: true,
              alertType: "error",
              snackbarMessage:
                "You have tried too many times, please contact customer support",
              snackbarError: true,
            });
          }
        } else {
          console.error("There was an error!", error);
        }
      });
  };

  generateOtp = () => {
    const { email, name } = this.state.formValues;
    axios
      .post(url + "/affiliates/send-otp", { email, fullName: name })
      .then((response) => {
        if (response.data.success) {
          this.setState(
            {
              isLoading: false,
              generatedOtp: response.data.otp,
              isOtpVerified: false,
              otpResendTime: 30,
              canResendOtp: false,
            },
            () => {
              this.startOtpTimer();
              this.setState((prevState) => ({ step: prevState.step + 1 })); // Move to OTP step
            }
          );
        } else if (response.data.blocked) {
          this.setState({
            snackbarOpen: true,
            alertType: "error",
            snackbarMessage:
              "You have tried too many times, please contact customer support",
            isLoading: false,
            snackbarError: true,
          });
        } else {
                    this.setState({
                      snackbarOpen: true,
                      alertType: "error",
                      snackbarMessage:
                        "Error Generating OTP",
                      isLoading: false,
                      snackbarError: true,
                    });
          console.error("Error generating OTP");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data === "Email already in use!") {
            this.setState({
              snackbarOpen: true,
              alertType: "error",
              snackbarMessage: "Email already in use",
              isLoading: false,
              snackbarError: true,
            });
          } else if (error.response.data === "blocked") {
            this.setState({
              snackbarOpen: true,
              alertType: "error",
              snackbarMessage:
                "You have tried too many times, please contact customer support",
              isLoading: false,
              snackbarError: true,
            });
          }
        } else {
                     this.setState({
                       snackbarOpen: true,
                       alertType: "error",
                       snackbarMessage:
                         "There was an error generating otp",
                       isLoading: false,
                       snackbarError: true,
                     });
          console.error("There was an error generating the OTP!", error);
        }
      });
  };

  generateResendOtp = () => {
    const { email, name } = this.state.formValues;
    axios
      .post(url + "/affiliates/send-otp", { email, fullName: name })
      .then((response) => {
        if (response.data.success) {
          this.setState(
            {
              isLoading: false,
              generatedOtp: response.data.otp,
              isOtpVerified: false,
              otpResendTime: 30,
              canResendOtp: false,
            },
            () => {
              this.startOtpTimer();
            }
          );
        } else if (response.data.blocked) {
          this.setState({
            snackbarOpen: true,
            alertType: "error",
            snackbarMessage:
              "You have tried too many times, please contact customer support",
            isLoading: false,
            snackbarError: true,
          });
        } else {
          console.error("Error generating OTP");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data === "Email already in use!") {
            this.setState({
              snackbarOpen: true,
              alertType: "error",
              snackbarMessage: "Email already in use",
              isLoading: false,
              snackbarError: true,
            });
          } else if (error.response.data === "blocked") {
            this.setState({
              snackbarOpen: true,
              alertType: "error",
              snackbarMessage:
                "You have tried too many times, please contact customer support",
              isLoading: false,
              snackbarError: true,
            });
          }
        } else {
          console.error("There was an error generating the OTP!", error);
        }
      });
  };


  startOtpTimer = () => {
    this.otpTimer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.otpResendTime <= 1) {
          clearInterval(this.otpTimer);
          return { otpResendTime: 0, canResendOtp: true };
        }
        return { otpResendTime: prevState.otpResendTime - 1 };
      });
    }, 1000);
  };

  verifyOtp = () => {
    const { formValues } = this.state;
    const { email, otp } = formValues;
    axios
      .post(url + "/affiliates/verify-otp", { email, otp: formValues.otp.join("") })
      .then((response) => {
        if (response.data) {
          this.setState(
            { isOtpVerified: true, errors: { ...this.state.errors, otp: "" } },
            this.handleSubmit
          );
        } else {
          this.setState({
            isOtpVerified: false,
            errors: {
              ...this.state.errors,
              otp: "Invalid OTP. Please try again.",
            },
          });
        }
      })
      .catch((error) => {
        console.error("There was an error verifying the OTP!", error);
        this.setState({
          isOtpVerified: false,
          errors: {
            ...this.state.errors,
            otp: "An error occurred. Please try again.",
          },
        });
      });
  };

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  isStepComplete = () => {
    const { step, formValues, errors } = this.state;
    const stepFields = {
      1: [
        "name",
        "companyName",
        "contact",
        "email",
        "userName",
        "loginPassword",
      ],
      2: ["otp"],
    };

    const areFieldsFilled = stepFields[step]
      ? stepFields[step].every((field) => formValues[field])
      : true;
    const areFieldsValid = Object.values(errors).every((error) => !error);

    if (step === 1) {
      return areFieldsFilled && areFieldsValid;
    }

    return areFieldsFilled && areFieldsValid;
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleClickShowConfirmPassword = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  renderStep = () => {
    const {
      step,
      formValues,
      errors,
      passwordValidation,
      showPasswordTooltip,
      otpResendTime,
      canResendOtp,
      countryCode,
      showPassword,
      showConfirmPassword,
    } = this.state;
    const { classes } = this.props;

    const passwordTooltipContent = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span
          style={{
            color: passwordValidation.length ? "green" : "red",
            transition: "color 0.3s",
          }}
        >
          - Must be 8 characters long
        </span>
        <span
          style={{
            color: passwordValidation.lowercase ? "green" : "red",
            transition: "color 0.3s",
          }}
        >
          - At least 1 lowercase letter
        </span>
        <span
          style={{
            color: passwordValidation.number ? "green" : "red",
            transition: "color 0.3s",
          }}
        >
          - At least 1 number
        </span>
        <span
          style={{
            color: passwordValidation.specialChar ? "green" : "red",
            transition: "color 0.3s",
          }}
        >
          - At least 1 special character
        </span>
      </div>
    );

    switch (step) {
      case 1:
        return (
          <Fade in timeout={300}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  freeSolo
                  options={[]}
                  value={formValues.name}
                  onInputChange={(event, value) =>
                    this.handleChange(event, value, "name")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Name"
                      variant="outlined"
                      error={!!errors.name}
                      className={classes.textFieldRootAutocomplete}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: errors.name && (
                          <InputAdornment position="end">
                            <Tooltip title={errors.name} placement="right">
                              <ErrorOutlineIcon color="error" />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  freeSolo
                  options={[]}
                  value={formValues.companyName}
                  onInputChange={(event, value) =>
                    this.handleChange(event, value, "companyName")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company Name"
                      variant="outlined"
                      error={!!errors.companyName}
                      className={classes.textFieldRootAutocomplete}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: errors.companyName && (
                          <InputAdornment position="end">
                            <Tooltip title={errors.companyName} placement="right">
                              <ErrorOutlineIcon color="error" />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}></div>
                <div style={{ position: "relative" }}>
                  <Autocomplete
                    disableClearable
                    options={dialCodes.map((i) => i.dialCode)}
                    classes={{ popper: classes.textFieldPopper }}
                    getOptionLabel={(option) => {
                      return `${getflag(isoMap[option])} ${option}     ${
                        countryMap[option.substring(1)]
                      }`;
                    }}
                    onChange={(e, value, reason) => {
                      this.setState({
                        countryCode: value,
                      });
                    }}
                    value={countryCode}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        classes={{ root: classes.codeTextFieldRoot }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                  <TextField
                    id="contact"
                    placeholder="Mobile Number"
                    type="text"
                    name="contact"
                    onChange={(e) => this.handleInputChange(e)}
                    value={formValues.contact}
                    className={classes.phoneTextFieldRoot}
                    InputProps={{
                      endAdornment: errors.contact && (
                        <InputAdornment position="end">
                          <Tooltip title={errors.contact} style={{marginLeft:"82%"}} placement="right">
                            <ErrorOutlineIcon color="error" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  freeSolo
                  options={[]}
                  value={formValues.email}
                  onInputChange={(event, value) => {
                    this.handleChange(event, value, "email");
                    this.handleChange(event, value, "userName"); // Set both email and username
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Email"
                      variant="outlined"
                      error={!!errors.email}
                      className={classes.textFieldRootAutocomplete}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: errors.email && (
                          <InputAdornment position="end">
                            <Tooltip title={errors.email} placement="right">
                              <ErrorOutlineIcon color="error" />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} className={classes.passwordFieldContainer}>
                <Autocomplete
                  disableClearable
                  freeSolo
                  options={[]}
                  value={formValues.loginPassword}
                  onInputChange={(event, value) =>
                    this.handleChange(event, value, "loginPassword")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Login Password"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      error={!!errors.loginPassword}
                      className={`${classes.textFieldRootAutocomplete} ${classes.passwordField}`}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            {errors.loginPassword && (
                              <Tooltip
                                title={passwordTooltipContent}
                                placement="right"
                                interactive
                              >
                                <ErrorOutlineIcon color="error" />
                              </Tooltip>
                            )}
                            <IconButton
                              size="small"
                              onMouseEnter={() =>
                                this.setState({ showPasswordTooltip: true })
                              }
                              onMouseLeave={() =>
                                this.setState({ showPasswordTooltip: false })
                              }
                              onClick={this.handleClickShowPassword}
                            >
                              {showPassword ? (
                                <Visibility style={{ color: 'rgba(0, 0, 0, 0.15)' }} />
                              ) : (
                                <VisibilityOff style={{ color: 'rgba(0, 0, 0, 0.15)' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} className={classes.passwordFieldContainer}>
                <Autocomplete
                  disableClearable
                  freeSolo
                  options={[]}
                  value={formValues.confirmPassword}
                  onInputChange={(event, value) =>
                    this.handleChange(event, value, "confirmPassword")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      variant="outlined"
                      error={!!errors.confirmPassword}
                      className={`${classes.textFieldRootAutocomplete} ${classes.passwordField}`}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            {errors.confirmPassword && (
                              <Tooltip
                                title={errors.confirmPassword}
                                placement="right"
                              >
                                <ErrorOutlineIcon color="error" />
                              </Tooltip>
                            )}
                            <IconButton
                              size="small"
                              onClick={this.handleClickShowConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <Visibility style={{ color: 'rgba(0, 0, 0, 0.15)' }} />
                              ) : (
                                <VisibilityOff style={{ color: 'rgba(0, 0, 0, 0.15)' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Fade>
        );
      case 2:
        return (
          <Fade in timeout={300}>
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item xs={12} className={classes.otpContainer}>
                {[0, 1, 2, 3].map((index) => (
                  <TextField
                    key={index}
                    variant="outlined"
                    placeholder="0"
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: "center", fontSize: "24px" },
                    }}
                    value={formValues.otp[index]}
                    onChange={(event) => this.handleOtpChange(index, event)}
                    className={classes.otpInput}
                    inputRef={(input) => (this[`otpInput${index}`] = input)}
                  />
                ))}
              </Grid>
              <Grid item xs={12} className={classes.timerContainer}>
                <Typography variant="body2" gutterBottom>
                  OTP sent to {formValues.email} Resend OTP after{" "}
                  {`00:${otpResendTime < 10 ? "0" : ""}${otpResendTime}`}
                </Typography>
                {otpResendTime === 0 ? (
                  <Typography variant="body2" gutterBottom>
                    Didn't receive the code?{" "}
                    <Button onClick={this.generateResendOtp} style={{ cursor: "pointer" }}>
                      Resend OTP
                    </Button>
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </Fade>
        );
      default:
        return null;
    }
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false, snackbarError: false });
  };

  render() {
    const { isAuthenticated, classes } = this.props;
    const { step, formValues, snackbarOpen, snackbarMessage, snackbarError } =
      this.state;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return (
        <div className={classes.root}>
          <Grid container className={classes.loginContainer}>
            <Grid item xs={12} sm={5} className={classes.leftContainer}>
              <Box alignItems="left">
                <Link href="/" className={classes.logoLink}>
                  <img src={logo} alt="AiSensy Logo" className={classes.logo} />
                </Link>
                <Typography
                  variant="h5"
                  textAlign="center"
                  gutterBottom
                  className={classes.welcomeText}
                  style={{
                    color: "#4A4A4A",
                    fontSize: "30px",
                    fontWeight: "bolder",
                    textAlign: "left",
                    marginLeft: "8%",
                    marginTop: "5.2%",
                  }}
                >
                  AiSensy Affiliate Dashboard
                </Typography>
                <Typography
                  variant="h4"
                  textAlign="center"
                  gutterBottom
                  style={{ textAlign: "left", marginLeft: "8%" }}
                >
                  Start with 20% commission and Grow More !!!
                </Typography>
                <Box className={classes.leftImageContainer}>
                  <img
                    src={leftImage}
                    alt="Left Graphic"
                    className={classes.leftImage}
                  />
                </Box>

                <Typography variant="h4" style={{ marginTop: "10px" }}>
                  Trusted by 50000 + Brands
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={7} className={classes.rightContainer}>
              <Link href="/" className={classes.rightLogoLink}>
                <img src={logo} alt="AiSensy Logo" className={classes.logo} />
              </Link>
              <div className={classes.signUpLink}>
                <Typography variant="h5">
                  Already a member ?{" "}
                  <Link
                    variant="h5"
                    href="/login"
                    style={{ color: "darkgreen" }}
                  >
                    Log in
                  </Link>
                </Typography>
              </div>
              <div style={{ textAlign: "center", marginTop: "10%" }}>
                <Typography
                  style={{ color: "#4A4A4A", fontSize: "30px" }}
                  variant="h5"
                  className={classes.welcomeText}
                  gutterBottom
                >
                  Create Your AiSensy Affiliate Account
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ color: "#4A4A4A" }}
                >
                  Fill in the details below to complete your signup.
                </Typography>
              </div>
              <div className={classes.centeredWrapper}>
                <div
                  component="form"
                  noValidate
                  className={classes.formContainer}
                >
                  <Fade in timeout={300} key={step}>
                    <div>{this.renderStep()}</div>
                  </Fade>
                  <div
                    className={
                      step === 2
                        ? classes.otpButtonContainer
                        : classes.nextButtonContainer
                    }
                  >
                    {step > 1 && (
                      <Button
                        onClick={this.handleBack}
                        color="primary"
                        variant="outlined"
                      >
                        Back
                      </Button>
                    )}
                    {step === 2 ? (
                      <Button
                        onClick={this.verifyOtp}
                        color="primary"
                        variant="contained"
                        disabled={!formValues.otp}
                      >
                        Verify OTP
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleNext}
                        color="primary"
                        variant="contained"
                        disabled={
                          !this.isStepComplete() || this.state.isLoading
                        }
                        className={classes.nextButton}
                      >
                        {this.state.isLoading ? (
                          <>
                            <Typography>Sending OTP </Typography>
                            <CircularProgress
                              size={24}
                              color="inherit"
                              style={{ marginLeft: "10px" }}
                            />
                          </>
                        ) : (
                          "Next "
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Snackbar
            open={!!this.state.snackbarOpen}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={this.handleSnackbarClose}
            className={classes.snackbar}
            autoHideDuration={3000}
          >
            <Alert
              severity={this.state.alertType}
              onClose={this.handleSnackbarClose}
            >
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      );
    }
  }
}

const mapDispatchToProps = {
  loginUser,
};

export default connect(
  (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    isLoading: state.login.isLoading,
    error: state.login.error,
    errmsg: state.login.errmsg,
  }),
  mapDispatchToProps
)(withStyles(styles)(AddAffiliatePage));
