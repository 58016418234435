import React, { createRef, PureComponent } from "react";

import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
} from "recharts";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const global_strokes = [
  { id: "av", color: "#F2C14E" },
  { id: "bv", color: "#BDBF00" },
  { id: "wv", color: "#4babda" },
  { id: "ev", color: "#F70054" },
  { id: "cv", color: "#F78154" },
  { id: "gv", color: "#a48ed2" },
  { id: "dv", color: "#5FAD56" },
  { id: "8", color: "#5D3FD3" },
  { id: "9", color: "#C70039" },
  { id: "10", color: "#40B5AD" },
  { id: "11", color: "#F2e14E" },
  { id: "12", color: "#BDBc00" },
  { id: "13", color: "#4baBda" },
  { id: "14", color: "#F70354" },
  { id: "15", color: "#F78254" },
  { id: "16", color: "#a48Ed2" },
  { id: "17", color: "#5FAF56" },
  { id: "18", color: "#5D3Ft3" },
  { id: "19", color: "#6e6e6e" },
  { id: "20", color: "#433030" },
  { id: "21", color: "#984ba1" },
  { id: "22", color: "#d7f142" },
  { id: "23", color: "#3f98a6" },
  { id: "24", color: "#333cc5" },
  { id: "25", color: "#2a2f18e3" },
  { id: "26", color: "#1d9483e3" },
];

class LineGraph extends PureComponent {
  constructor(props) {
    super(props);
    this.rootRef = createRef(null);
  }
  render() {
    const { data, keys, title, isLoading, Subheading, Options, fixed_strokes } =
      this.props;
    const classes = this.props.classes;
    const strokes = fixed_strokes || global_strokes;
    const color = "white";
    let newData = [];
    if (data.length === 1 && data[0].date) {
      const record = data[0];
      const dummyRecord = {};
      let dateBefore = new Date(record.date);
      dateBefore.setDate(dateBefore.getDate() - 1);
      dateBefore = dateBefore.toDateString().split(" ");

      Object.keys(record).forEach((i) => {
        dummyRecord[i] = 0;
      });
      newData = [
        { ...dummyRecord, date: `${dateBefore[2]} ${dateBefore[1]}` },
        record,
      ];
    }
    return (
      <div
        ref={this.rootRef}
        className={classes.root}
        style={{ backgroundColor: color }}
      >
        <Grid container>
          {title && (
            <Grid item xs={12} md={6}>
              <Box m="1em 0em" className={classes.header}>
                <Typography variant="h3" component="span">
                  {title}
                </Typography>
                <Typography variant="body2" component="span">
                  {" "}
                  (per day)
                </Typography>
              </Box>
            </Grid>
          )}
          {Options && (
            <Grid item xs={12} md={6}>
              <Box
                m="1em 0em"
                className={classes.header}
                textAlign={{ xs: "left", md: "right" }}
              >
                <Options />
              </Box>
            </Grid>
          )}
        </Grid>

        {Subheading && <Subheading />}
        {isLoading || !this.rootRef.current ? (
          <Box display="flex" justifyContent="center" width="100%">
            <Skeleton width={"90%"} height={300} />
          </Box>
        ) : (
          <React.Fragment>
            {data.length > 0 ? (
              <Box p="1em 1%">
                <ComposedChart
                  width={this.rootRef.current.clientWidth - 40}
                  height={
                    this.rootRef.current.clientWidth / 3 > 300
                      ? 300
                      : this.rootRef.current.clientWidth / 3
                  }
                  data={data.length === 1 ? newData : data}
                >
                  <defs>
                    {strokes.map((e) => {
                      return (
                        <linearGradient
                          key={e.id}
                          id={e.id}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="0%"
                            stopColor={e.color}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={e.color}
                            stopOpacity={0}
                          />
                        </linearGradient>
                      );
                    })}
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" axisLine={false} scale="point" />
                  <YAxis
                    axisLine={false}
                    allowDecimals={false}
                    tickFormatter={(number) => {
                      if (number > 1000000000) {
                        return (
                          (number / 1000000000).toFixed(0).toString() + "B"
                        );
                      } else if (number > 1000000) {
                        return (number / 1000000).toFixed(0).toString() + "M";
                      } else if (number > 1000) {
                        return (number / 1000).toFixed(0).toString() + "K";
                      } else {
                        return number.toString();
                      }
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  {keys.map((key, index) => {
                    return (
                      <Area
                        key={key}
                        strokeWidth={2}
                        type="monotone"
                        dataKey={key}
                        stroke={strokes[index].color}
                        isAnimationActive={true}
                        // stackId="1"
                        fillOpacity={1}
                        fill={`url(#${strokes[index].id})`}
                      />
                    );
                  })}
                </ComposedChart>
              </Box>
            ) : (
              <Box
                py={1}
                display="flex"
                justifyContent="center"
                width="100%"
                height={300}
                alignItems="center"
              >
                <Typography variant="h6" color="textSecondary">
                  {" "}
                  Nothing to show for now ;)
                </Typography>
              </Box>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    // margin: "3% 0em",
    // paddingTop: "1em",
    width: "100%",
    justifyContent: "left",
    flexDirection: "column",
    alignItems: "start",
    // color: "white",
    // backgroundColor: "#a100f2",
    backgroundColor: "white",
    borderRadius: "1em",
  },
  heading: { margin: "0.5em" },
  header: {
    [theme.breakpoints.down("md")]: {
      padding: "0 20px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 30px",
    },
  },
});

export default withStyles(styles)(LineGraph);
