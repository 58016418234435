import React, { Component } from "react";
import { withStyles, Grid, Typography, Box } from "@material-ui/core";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ManageNavBarDesktop from "./Navbar/ManageNavBarDesktop";
import { renderManageRoute } from "./Routes/ProjectRoutes";
import { fetchPartnerDetails } from "../../store/partnerState";
class ManageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchPartnerDetails();
  }

  render() {
    const renderFilteredManageRoutes = this.props.partner.isZohoEnabled
      ? renderManageRoute
      : renderManageRoute.filter((i) => i.name !== "Manage Addons");
    const { classes, agent, partner } = this.props;
    const { type } = partner || {};
    const baseroute = "/manage";
    return (
      <Grid container className={classes.root}>
        <Grid item>
          <ManageNavBarDesktop />
        </Grid>
        <Grid item xs className={classes.routeWrapper}>
          <div id="route-container" className={classes.routeContainer}>
            <Switch>
              <Route
                key={30}
                exact
                path={baseroute}
                render={(routeProps) => (
                  <Redirect
                    to={type !== "DIRECT" ? `/manage/config` : `/manage/agents`}
                    {...routeProps}
                  />
                )}
              />
              {renderFilteredManageRoutes.map((route, index) => {
                return route.name ? (
                  <Route
                    key={index}
                    path={baseroute + route.to}
                    component={
                      !!!route.excludedTypes?.includes(type) && route.component
                    }
                  />
                ) : (
                  <></>
                );
              })}
              <Route component={() => <Redirect to="/error" />} />
            </Switch>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    boxSizing: "border-box",
  },
  routeWrapper: {
    height: "100vh",
    overflow: "hidden",
    boxSizing: "border-box",
    background: "white",
  },
  routeContainer: {
    width: "100%",
    height: "100vh",
    transition: "1s",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {},
  },
});

const connectedManageLayout = connect(
  (state) => ({
    agent: state.login.user,
    partner: state.partner.partner,
  }),
  { fetchPartnerDetails }
)(ManageLayout);

export default withStyles(styles)(connectedManageLayout);
