import axios from "axios";
import { URL } from "../../config/config";

const initialState = {
  media: {
    AUDIO: [],
    VIDEO: [],
    IMAGE: [],
    FILE: [],
  },
  loading: {
    AUDIO: false,
    VIDEO: false,
    IMAGE: false,
    FILE: false,
  },
  loadingMore: {
    AUDIO: false,
    VIDEO: false,
    IMAGE: false,
    FILE: false,
  },
  hasMore: {
    AUDIO: false,
    VIDEO: false,
    IMAGE: false,
    FILE: false,
  },
  recent: {
    AUDIO: [],
    VIDEO: [],
    IMAGE: [],
    FILE: [],
  },
  total: {
    AUDIO: 0,
    VIDEO: 0,
    IMAGE: 0,
    FILE: 0,
  },
  uploading: {
    AUDIO: {},
    VIDEO: {},
    IMAGE: {},
    FILE: {},
  },
};

export const bg_colors = {
  IMAGE: "rgb(250, 173, 32, 0.15)",
  VIDEO: "rgb(95, 102, 205, 0.15)",
  FILE: "rgb(236, 64, 122, 0.15)",
  LOCATION: "rgb(255 5 5 / 17%)",
};

export const icon_colors = {
  IMAGE: "rgb(250, 173, 32)",
  VIDEO: "rgb(95, 102, 205)",
  FILE: "rgb(236, 64, 122)",
  LOCATION: "rgb(239 0 0)",
};

const LOAD_MEDIA_IMAGE = "MEDIA/IMAGE";
const LOAD_MEDIA_AUDIO = "MEDIA/AUDIO";
const LOAD_MEDIA_VIDEO = "MEDIA/VIDEO";
const LOAD_MEDIA_FILE = "MEDIA/FILE";
const LOAD_RECENT_MEDIA_IMAGE = "MEDIA/RECENT_IMAGE";
const LOAD_RECENT_MEDIA_AUDIO = "MEDIA/RECENT_AUDIO";
const LOAD_RECENT_MEDIA_VIDEO = "MEDIA/RECENT_VIDEO";
const LOAD_RECENT_MEDIA_FILE = "MEDIA/RECENT_FILE";
const LOAD_MORE_MEDIA_IMAGE = "MEDIA/MORE_IMAGE";
const LOAD_MORE_MEDIA_AUDIO = "MEDIA/MORE_AUDIO";
const LOAD_MORE_MEDIA_VIDEO = "MEDIA/MORE_VIDEO";
const LOAD_MORE_MEDIA_FILE = "MEDIA/MORE_FILE";
const LOAD_MEDIA_IMAGE_STATUS = "MEDIA/IMAGE_STATUS";
const LOAD_MEDIA_AUDIO_STATUS = "MEDIA/AUDIO_STATUS";
const LOAD_MEDIA_VIDEO_STATUS = "MEDIA/VIDEO_STATUS";
const LOAD_MEDIA_FILE_STATUS = "MEDIA/FILE_STATUS";
const LOAD_MORE_MEDIA_IMAGE_STATUS = "MEDIA/MORE_IMAGE_STATUS";
const LOAD_MORE_MEDIA_AUDIO_STATUS = "MEDIA/MORE_AUDIO_STATUS";
const LOAD_MORE_MEDIA_VIDEO_STATUS = "MEDIA/MORE_VIDEO_STATUS";
const LOAD_MORE_MEDIA_FILE_STATUS = "MEDIA/MORE_FILE_STATUS";
const ADD_UPLOADING_MEDIA = "MEDIA/ADD_UPLOADING_MEDIA";
const UPDATE_UPLOADING_MEDIA = "MEDIA/UPDATE_UPLOADING_MEDIA";
const REMOVE_UPLOADING_MEDIA = "MEDIA/REMOVE_UPLOADING_MEDIA";
const MEDIA_UPLOAD_COMPLETED = "MEDIA/MEDIA_UPLOAD_COMPLETED";

export const loadMedia =
  (assistantId, nameQuery, list) => (dispatch, getState) => {
    (list || ["IMAGE", "VIDEO", "FILE", "AUDIO"]).map((fileType) => {
      dispatch({
        type: `MEDIA/${fileType}_STATUS`,
        payload: {
          loading: true,
        },
      });
      return axios
        .post(URL + "/api/media-library", {
          assistantId,
          skip: 0,
          fileType,
          rowsPerPage: 20,
          nameQuery,
        })
        .then((response) => {
          const { media, totalMediaCount } = response.data;
          dispatch({
            type: `MEDIA/${fileType}`,
            payload: {
              media,
              totalMediaCount,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          dispatch({
            type: `MEDIA/${fileType}_STATUS`,
            payload: {
              loading: false,
            },
          });
        });
    });
  };

export const uploadMedia =
  ({ fileSection, selectedFile, previewImageUrl, projectId, agent }) =>
  async (dispatch, getState) => {
    const { name, type, size } = selectedFile;
    const id = Date.now();
    const uploadingPayload = {
      fileType: fileSection,
      id,
    };
    try {
      // Generate cancel token
      const CancelToken = axios.CancelToken;
      const uploadSource = CancelToken.source();

      //upload file skeleton/Loader
      dispatch({
        type: "MEDIA/ADD_UPLOADING_MEDIA",
        payload: {
          fileType: fileSection,
          publicUrl: fileSection === "IMAGE" ? previewImageUrl : "",
          uploading: uploadSource,
          fileName: name,
          fileSize: size,
          progress: 0,
          id,
        },
      });

      // Get Upload-URL
      const response = await axios.post(URL + "/api/get-upload-url", {
        assistantId: projectId,
        fileName: name,
        contentType: type,
        fileType: fileSection,
        cancelToken: uploadSource.token,
      });

      let form = new FormData();
      Object.entries(response.data.uploadURL.fields).forEach((entry) => {
        form.append(entry[0], entry[1]);
      });
      form.append("file", selectedFile);

      const config = {
        transformRequest: (data, headers) => {
          delete headers.common["Authorization"];
          return data;
        },
        cancelToken: uploadSource.token,
        onUploadProgress: (progressEvent) => {
          //update file upload progress
          dispatch({
            type: "MEDIA/UPDATE_UPLOADING_MEDIA",
            payload: {
              id,
              fileType: fileSection,
              progress: Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              ),
            },
          });
        },
      };

      await axios.post(response.data.uploadURL.url, form, config);

      await axios.post(URL + "/api/save-media-details", {
        assistantId: projectId,
        fileName: name,
        contentType: type,
        fileType: fileSection,
        clientId: agent?.clientId,
        date: new Date(),
        fileSize: size,
        s3Path: response.data.filePath,
        publicUrl: response.data.fullUrl,
        uploadedBy: agent?.displayName,
      });

      dispatch({
        type: "MEDIA/MEDIA_UPLOAD_COMPLETED",
        payload: {
          id,
          assistantId: projectId,
          fileName: name,
          contentType: type,
          fileType: fileSection,
          clientId: agent?.clientId,
          date: new Date(),
          fileSize: size,
          s3Path: response.data.filePath,
          publicUrl: response.data.fullUrl,
          uploadedBy: agent?.displayName,
          success: true,
        },
      });
      dispatch({
        type: "MEDIA/REMOVE_UPLOADING_MEDIA",
        payload: {
          ...uploadingPayload,
        },
      });
    } catch (error) {
      if (error.message === "request_terminated") {
        dispatch({
          type: "MEDIA/REMOVE_UPLOADING_MEDIA",
          payload: {
            ...uploadingPayload,
          },
        });
      } else {
        dispatch({
          type: "MEDIA/UPDATE_UPLOADING_MEDIA",
          payload: {
            ...uploadingPayload,
            success: false,
            reason: error.message,
          },
        });
      }
    }
  };

export const removeFailedUpload = (payload) => (dispatch) => {
  dispatch({
    type: "MEDIA/REMOVE_UPLOADING_MEDIA",
    payload,
  });
};

export const loadRecentMedia = (assistantId, list) => (dispatch, getState) => {
  (list || ["IMAGE", "VIDEO", "FILE", "AUDIO"]).map((fileType) => {
    return axios
      .post(URL + "/api/recent-media-library", {
        assistantId,
        fileType,
      })
      .then((response) => {
        const { media } = response.data;
        dispatch({
          type: `MEDIA/RECENT_${fileType}`,
          payload: {
            media,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  });
};

export const loadMoreMedia =
  (assistantId, fileType, skip, nameQuery) => (dispatch, getState) => {
    dispatch({
      type: `MEDIA/MORE_${fileType}_STATUS`,
      payload: {
        loading: true,
      },
    });
    return axios
      .post(URL + "/api/media-library", {
        assistantId,
        fileType,
        skip,
        rowsPerPage: 20,
        nameQuery,
      })
      .then((response) => {
        const { media } = response.data;
        dispatch({
          type: `MEDIA/MORE_${fileType}`,
          payload: {
            media,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: `MEDIA/MORE_${fileType}_STATUS`,
          payload: {
            loading: false,
          },
        });
      });
  };

export const incrementCount = (fileType, id) => (dispatch, getState) => {
  dispatch({
    type: `MEDIA/INCREMENT_${fileType}`,
    payload: {
      id,
    },
  });
};

export default function MediaReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOAD_MEDIA_IMAGE:
      return {
        ...state,
        media: {
          ...state.media,
          IMAGE: [...payload.media],
        },
        hasMore: {
          ...state.hasMore,
          IMAGE: payload.media.length === 20,
        },
        total: {
          ...state.total,
          IMAGE: payload.totalMediaCount
            ? payload.totalMediaCount
            : state.total.IMAGE,
        },
      };
    case LOAD_MEDIA_VIDEO:
      return {
        ...state,
        media: {
          ...state.media,
          VIDEO: [...payload.media],
        },
        hasMore: {
          ...state.hasMore,
          VIDEO: payload.media.length === 20,
        },
        total: {
          ...state.total,
          VIDEO: payload.totalMediaCount
            ? payload.totalMediaCount
            : state.total.VIDEO,
        },
      };
    case LOAD_MEDIA_AUDIO:
      return {
        ...state,
        media: {
          ...state.media,
          AUDIO: [...payload.media],
        },
        hasMore: {
          ...state.hasMore,
          AUDIO: payload.media.length === 20,
        },
        total: {
          ...state.total,
          AUDIO: payload.totalMediaCount
            ? payload.totalMediaCount
            : state.total.AUDIO,
        },
      };
    case LOAD_MEDIA_FILE:
      return {
        ...state,
        media: {
          ...state.media,
          FILE: [...payload.media],
        },
        hasMore: {
          ...state.hasMore,
          FILE: payload.media.length === 20,
        },
        total: {
          ...state.total,
          FILE: payload.totalMediaCount
            ? payload.totalMediaCount
            : state.total.FILE,
        },
      };
    case LOAD_RECENT_MEDIA_IMAGE:
      return {
        ...state,
        recent: {
          ...state.recent,
          IMAGE: [...payload.media],
        },
      };
    case LOAD_RECENT_MEDIA_VIDEO:
      return {
        ...state,
        recent: {
          ...state.recent,
          VIDEO: [...payload.media],
        },
      };
    case LOAD_RECENT_MEDIA_AUDIO:
      return {
        ...state,
        recent: {
          ...state.recent,
          AUDIO: [...payload.media],
        },
      };
    case LOAD_RECENT_MEDIA_FILE:
      return {
        ...state,
        recent: {
          ...state.recent,
          FILE: [...payload.media],
        },
      };
    case LOAD_MEDIA_IMAGE_STATUS:
      return {
        ...state,
        loading: {
          ...state.loading,
          IMAGE: payload.loading,
        },
      };
    case LOAD_MEDIA_VIDEO_STATUS:
      return {
        ...state,
        loading: {
          ...state.loading,
          VIDEO: payload.loading,
        },
      };
    case LOAD_MEDIA_AUDIO_STATUS:
      return {
        ...state,
        loading: {
          ...state.loading,
          AUDIO: payload.loading,
        },
      };
    case LOAD_MEDIA_FILE_STATUS:
      return {
        ...state,
        loading: {
          ...state.loading,
          FILE: payload.loading,
        },
      };
    case LOAD_MORE_MEDIA_IMAGE:
      return {
        ...state,
        media: {
          ...state.media,
          IMAGE: [...state.media.IMAGE, ...payload.media],
        },
        hasMore: {
          ...state.hasMore,
          IMAGE: payload.media.length === 20,
        },
      };
    case LOAD_MORE_MEDIA_VIDEO:
      return {
        ...state,
        media: {
          ...state.media,
          VIDEO: [...state.media.VIDEO, ...payload.media],
        },
        hasMore: {
          ...state.hasMore,
          VIDEO: payload.media.length === 20,
        },
      };
    case LOAD_MORE_MEDIA_AUDIO:
      return {
        ...state,
        media: {
          ...state.media,
          AUDIO: [...state.media.AUDIO, ...payload.media],
        },
        hasMore: {
          ...state.hasMore,
          AUDIO: payload.media.length === 20,
        },
      };
    case LOAD_MORE_MEDIA_FILE:
      return {
        ...state,
        media: {
          ...state.media,
          FILE: [...state.media.FILE, ...payload.media],
        },
        hasMore: {
          ...state.hasMore,
          FILE: payload.media.length === 20,
        },
      };
    case LOAD_MORE_MEDIA_IMAGE_STATUS:
      return {
        ...state,
        loadingMore: {
          ...state.loadingMore,
          IMAGE: payload.loading,
        },
      };
    case LOAD_MORE_MEDIA_VIDEO_STATUS:
      return {
        ...state,
        loadingMore: {
          ...state.loadingMore,
          VIDEO: payload.loading,
        },
      };
    case LOAD_MORE_MEDIA_AUDIO_STATUS:
      return {
        ...state,
        loadingMore: {
          ...state.loadingMore,
          AUDIO: payload.loading,
        },
      };
    case LOAD_MORE_MEDIA_FILE_STATUS:
      return {
        ...state,
        loadingMore: {
          ...state.loadingMore,
          FILE: payload.loading,
        },
      };
    case ADD_UPLOADING_MEDIA:
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [payload.fileType]: {
            ...state.uploading[payload.fileType],
            [payload.id]: { ...payload },
          },
        },
      };
    case UPDATE_UPLOADING_MEDIA:
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [payload.fileType]: {
            ...state.uploading[payload.fileType],
            [payload.id]: {
              ...state.uploading[payload.fileType][payload.id],
              ...payload,
            },
          },
        },
      };
    case REMOVE_UPLOADING_MEDIA:
      const newPayload = { ...state.uploading[payload.fileType] };
      delete newPayload[payload.id];
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [payload.fileType]: newPayload,
        },
      };
    case MEDIA_UPLOAD_COMPLETED:
      return {
        ...state,
        recent: {
          ...state.recent,
          [payload.fileType]: [payload, ...state.recent[payload.fileType]],
        },
      };
    default:
      return state;
  }
}
