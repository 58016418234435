import React, { Component } from "react";
import { withStyles, Grid, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import NavBar from "./Navbar/NavBar";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import {
  viewModeRoutes,
  topRoutes,
  bottomRoutes,
} from "./Routes/ProjectRoutes";
import FlagNotification from "../Header/notificationBar";

class ProjectsLayout extends Component {
  state = {
    status: "",
    statusMessage: "",
  };

  render() {
    const { classes, partner } = this.props;
    const { type } = partner || {};
    const routes = topRoutes;

    return (
      <div id="projects-layout" className={classes.root}>
        <NavBar />
        <ErrorBoundary>
          <div id="route-container" className={classes.routeContainer}>
            <Switch>
              {routes.map((route, index) => {
                if (!route.excludedTypes?.includes(type)) {
                  return (
                    <Route
                      exact={route.exact}
                      key={route.name}
                      path={route.to}
                      component={route.component}
                    />
                  );
                }
              })}
              {bottomRoutes.map((route) => {
                return (
                  <Route
                    exact
                    key={route.name}
                    path={route.to}
                    component={route.component}
                  />
                );
              })}
              <Route component={() => <Redirect to="/error" />} />
            </Switch>
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflowY: "auto",
      position: "relative",
    },
  },
  routeContainer: {
    width: "calc(100% - 70px)",
    height: "100vh",
    transition: "1s",
    overflow: "hidden",
    marginLeft: 70,
    // background: "green",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100vh - 50px)",
      // height: "100vh",
      marginLeft: 0,
    },
  },
  fullHeight: {
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  preloader: {
    height: 150,
  },
});

const connectedProjectslayout = connect((state) => ({
  agent: state.login.user,
  partner: state.partner.partner,
  isPartnerDetailLoading: state.partner.isLoading,
  tenantDetails: state.tenant.tenant,
}))(ProjectsLayout);

export default withStyles(styles)(connectedProjectslayout);
