const mouldBillingMessages = (props, type) => {
  const { partnerBillingLogs, page, rowsPerPage, isBillingLoading } = props;

  const bills = JSON.parse(
    JSON.stringify(
      partnerBillingLogs.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    )
  );

  if (isBillingLoading) {
    return Array(10)
      .fill(0)
      .map((item) => ({ name: "Loading" }));
  }

  return bills;
};

export default {
  mouldBillingMessages,
};
