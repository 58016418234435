import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  withStyles,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { isoToName } from "../../config/dialCodes";
import axios from "axios";
import { URL } from "../../config/config";
import { connect } from "react-redux";
import { modifyWccPlan } from "../../store/wccPlanState";
import WccRow from "./WccRow";
import BulkUpdate from "./BulkUpdate";

const convertPrice = 100000;
class WccTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_initiated: {},
      business_initiated: {},
      service_initiated: {},
      authentication_initiated: {},
      utility_initiated: {},
      marketing_initiated: {},
      alertType: "",
      alertMsg: "",
      keyValue: 0,
    };
  }

  componentDidMount = () => {
    const {
      user_initiated,
      business_initiated,
      aisensy_business_initiated,
      aisensy_user_initiated,
      aisensy_service,
      aisensy_authentication,
      aisensy_marketing,
      aisensy_utility,
      service_initiated,
      authentication_initiated,
      utility_initiated,
      marketing_initiated,
    } = this.props;
    // <-- UIC -->

    for (const iso in aisensy_user_initiated) {
      if (!aisensy_user_initiated[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_user_initiated[iso]?.INR;
      const aisensyUSDPrice = aisensy_user_initiated[iso]?.USD;

      const partnerINRPrice = user_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = user_initiated[iso]?.USD ?? aisensyUSDPrice;
      user_initiated[iso] = {};
      user_initiated[iso].INR = partnerINRPrice;
      user_initiated[iso].USD = partnerUSDPrice;
      user_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      user_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }
    //<-- BIC -->
    for (const iso in aisensy_business_initiated) {
      if (!aisensy_business_initiated[iso]) {
        continue;
      }

      const aisensyINRPrice = aisensy_business_initiated[iso]?.INR;
      const aisensyUSDPrice = aisensy_business_initiated[iso]?.USD;

      const partnerINRPrice = business_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = business_initiated[iso]?.USD ?? aisensyUSDPrice;
      business_initiated[iso] = {};
      business_initiated[iso].INR = partnerINRPrice;
      business_initiated[iso].USD = partnerUSDPrice;
      business_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      business_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }

    for (const iso in aisensy_service) {
      if (!aisensy_service[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_service[iso]?.INR;
      const aisensyUSDPrice = aisensy_service[iso]?.USD;

      const partnerINRPrice = service_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = service_initiated[iso]?.USD ?? aisensyUSDPrice;
      service_initiated[iso] = {};
      service_initiated[iso].INR = partnerINRPrice;
      service_initiated[iso].USD = partnerUSDPrice;
      service_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      service_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }

    for (const iso in aisensy_authentication) {
      if (!aisensy_authentication[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_authentication[iso]?.INR;
      const aisensyUSDPrice = aisensy_authentication[iso]?.USD;

      const partnerINRPrice =
        authentication_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice =
        authentication_initiated[iso]?.USD ?? aisensyUSDPrice;
      authentication_initiated[iso] = {};
      authentication_initiated[iso].INR = partnerINRPrice;
      authentication_initiated[iso].USD = partnerUSDPrice;
      authentication_initiated[iso].inrMargin =
        partnerINRPrice - aisensyINRPrice;
      authentication_initiated[iso].usdMargin =
        partnerUSDPrice - aisensyUSDPrice;
    }

    for (const iso in aisensy_marketing) {
      if (!aisensy_marketing[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_marketing[iso]?.INR;
      const aisensyUSDPrice = aisensy_marketing[iso]?.USD;

      const partnerINRPrice = marketing_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = marketing_initiated[iso]?.USD ?? aisensyUSDPrice;
      marketing_initiated[iso] = {};
      marketing_initiated[iso].INR = partnerINRPrice;
      marketing_initiated[iso].USD = partnerUSDPrice;
      marketing_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      marketing_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }

    for (const iso in aisensy_utility) {
      if (!aisensy_utility[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_utility[iso]?.INR;
      const aisensyUSDPrice = aisensy_utility[iso]?.USD;

      const partnerINRPrice = utility_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = utility_initiated[iso]?.USD ?? aisensyUSDPrice;
      utility_initiated[iso] = {};
      utility_initiated[iso].INR = partnerINRPrice;
      utility_initiated[iso].USD = partnerUSDPrice;
      utility_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      utility_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }

    this.setState({
      user_initiated,
      business_initiated,
      service_initiated,
      authentication_initiated,
      utility_initiated,
      marketing_initiated,
    });
  };

  updateWcc = () => {
    const id = this.props.reqWccId;
    const user_initiated = this.state.user_initiated;
    const business_initiated = this.state.business_initiated;
    const payload = {
      wccPlanId: id,
      bic: business_initiated,
      uic: user_initiated,
      sc: this.state.service_initiated,
      ac: this.state.authentication_initiated,
      mc: this.state.marketing_initiated,
      uc: this.state.utility_initiated,
    };
    axios
      .patch(URL + `/api/update-partner-wcc-families`, payload)
      .then((response) => {
        this.props.triggerAlert({
          alertType: "success",
          alertMsg: "Wcc updated successfully",
        });
        this.props.toggleDialog();
        this.props.modifyWccPlan(payload);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertType: "error",
          alertMsg:
            err?.response?.data?.message || "something went wrong, try again",
        });
      });
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  updateBIC = (value, key) => {
    const { currency } = this.props;
    const business_initiated = {
      ...this.state.business_initiated,
    };

    business_initiated[key][currency] = value;

    this.setState({
      business_initiated,
    });
  };

  updateUIC = (value, key) => {
    const { currency } = this.props;
    const user_initiated = {
      ...this.state.user_initiated,
    };
    user_initiated[key][currency] = value;

    this.setState({
      user_initiated,
    });
  };

  updateMc = (value, key) => {
    const { currency } = this.props;
    const marketing_initiated = {
      ...this.state.marketing_initiated,
    };
    marketing_initiated[key][currency] = value;

    this.setState({
      marketing_initiated,
    });
  };

  upadteAc = (value, key) => {
    const { currency } = this.props;
    const authentication_initiated = {
      ...this.state.authentication_initiated,
    };
    authentication_initiated[key][currency] = value;

    this.setState({
      authentication_initiated,
    });
  };

  updateUc = (value, key) => {
    const { currency } = this.props;
    const utility_initiated = {
      ...this.state.utility_initiated,
    };
    utility_initiated[key][currency] = value;

    this.setState({
      utility_initiated,
    });
  };

  updateSc = (value, key) => {
    const { currency } = this.props;
    const service_initiated = {
      ...this.state.service_initiated,
    };
    service_initiated[key][currency] = value;

    this.setState({
      service_initiated,
    });
  };

  applyBulkUpdate = (convoType, sign, value) => {
    const {
      currency,
      aisensy_business_initiated,
      aisensy_user_initiated,
      aisensy_service,
      aisensy_authentication,
      aisensy_marketing,
      aisensy_utility,
    } = this.props;
    const {
      business_initiated: bic,
      user_initiated: uic,
      service_initiated,
      marketing_initiated,
      authentication_initiated,
      utility_initiated,
    } = this.state;
    const business_initiated = JSON.parse(JSON.stringify(bic));
    const user_initiated = JSON.parse(JSON.stringify(uic));
    // Bulk update bic of all countries
    if (convoType === "bic") {
      if (sign === "%") {
        value /= 100;
        for (const key in business_initiated) {
          const prevBIC = aisensy_business_initiated[key][currency];
          const newMargin = prevBIC * value;
          business_initiated[key][currency] = prevBIC + newMargin;
          business_initiated[key][currency.toLowerCase() + "Margin"] =
            newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in business_initiated) {
          const prev = aisensy_business_initiated[key][currency];
          business_initiated[key][currency] = prev + value;
          business_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }
    // Bulk update uic of all countries
    if (convoType === "uic") {
      if (sign === "%") {
        value /= 100;
        for (const key in user_initiated) {
          const prevUIC = aisensy_user_initiated[key][currency];
          const newMargin = prevUIC * value;
          user_initiated[key][currency] = prevUIC + newMargin;
          user_initiated[key][currency.toLowerCase() + "Margin"] = newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in user_initiated) {
          const prev = aisensy_user_initiated[key][currency];
          user_initiated[key][currency] = prev + value;
          user_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }

    // for marketing
    if (convoType === "mc") {
      if (sign === "%") {
        value /= 100;
        for (const key in marketing_initiated) {
          const prevUIC = aisensy_marketing[key][currency];
          const newMargin = prevUIC * value;
          marketing_initiated[key][currency] = prevUIC + newMargin;
          marketing_initiated[key][currency.toLowerCase() + "Margin"] =
            newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in marketing_initiated) {
          const prev = aisensy_marketing[key][currency];
          marketing_initiated[key][currency] = prev + value;
          marketing_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }

    //for service
    if (convoType === "sc") {
      if (sign === "%") {
        value /= 100;
        for (const key in service_initiated) {
          const prevUIC = aisensy_service[key][currency];
          const newMargin = prevUIC * value;
          service_initiated[key][currency] = prevUIC + newMargin;
          service_initiated[key][currency.toLowerCase() + "Margin"] = newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in service_initiated) {
          const prev = aisensy_service[key][currency];
          service_initiated[key][currency] = prev + value;
          service_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }

    // for utility
    if (convoType === "uc") {
      if (sign === "%") {
        value /= 100;
        for (const key in utility_initiated) {
          const prevUIC = aisensy_utility[key][currency];
          const newMargin = prevUIC * value;
          utility_initiated[key][currency] = prevUIC + newMargin;
          utility_initiated[key][currency.toLowerCase() + "Margin"] = newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in utility_initiated) {
          const prev = aisensy_utility[key][currency];
          utility_initiated[key][currency] = prev + value;
          utility_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }

    // for authentication

    if (convoType === "ac") {
      if (sign === "%") {
        value /= 100;
        for (const key in authentication_initiated) {
          const prevUIC = aisensy_authentication[key][currency];
          const newMargin = prevUIC * value;
          authentication_initiated[key][currency] = prevUIC + newMargin;
          authentication_initiated[key][currency.toLowerCase() + "Margin"] =
            newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in authentication_initiated) {
          const prev = aisensy_authentication[key][currency];
          authentication_initiated[key][currency] = prev + value;
          authentication_initiated[key][currency.toLowerCase() + "Margin"] =
            value;
        }
      }
    }

    this.setState({
      business_initiated: { ...business_initiated },
      user_initiated: { ...user_initiated },
      marketing_initiated: { ...marketing_initiated },
      service_initiated: { ...service_initiated },
      authentication_initiated: { ...authentication_initiated },
      utility_initiated: { ...utility_initiated },
      keyValue: this.state.keyValue + 1,
    });
  };

  render() {
    const {
      classes,
      openDialog,
      toggleDialog,
      aisensy_user_initiated,
      aisensy_business_initiated,
      currency,
      aisensy_service,
      aisensy_authentication,
      aisensy_utility,
      aisensy_marketing,
    } = this.props;

    const {
      user_initiated,
      business_initiated,
      service_initiated,
      authentication_initiated,
      utility_initiated,
      marketing_initiated,
    } = this.state;

    return Object.keys(business_initiated).length <= 0 ? (
      <CircularProgress />
    ) : (
      <>
        <Dialog open={openDialog} closeDialog={toggleDialog} maxWidth={"md"}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle className={classes.dialogTitle}>
              <Typography variant="h3">WCC Family Details</Typography>
            </DialogTitle>

            {/* <Tabs
              value={currency}
              onChange={this.handleCurrencyChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                className: classes.tabIndicator,
              }}
            >
              <Tab label="₹INR" value="INR" className={classes.tabHeader} />
              <Tab label="$USD" value="USD" className={classes.tabHeader} />
            </Tabs> */}
          </Box>
          <Grid style={{ padding: "24px 24px 16px 46px" }}>
            {!this.props.disableFields && (
              <BulkUpdate applyBulkUpdate={this.applyBulkUpdate} />
            )}
          </Grid>
          <Box
            style={{ padding: "24px 16px 16px 24px", width: 2000 }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <DialogContent>
              <Grid
                container
                alignItems="center"
                className={classes.content}
                spacing={0}
              >
                <Grid item xs={12} md={1}></Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  md={11}
                  style={{ marginBottom: "8px" }}
                >
                  <Grid md={12} style={{ display: "flex", marginBottom: 10 }}>
                    <Grid
                      item
                      md={3}
                      style={{
                        alignItems: "center",
                        borderBottom: "1px solid #c4c4c4",
                        background: "#461486",
                        marginRight: 8,
                        color: "white",
                      }}
                    >
                      <Typography variant="h4" align="center">
                        Marketing Initiated Charges
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      style={{
                        alignItems: "center",
                        borderBottom: "1px solid #c4c4c4",
                        background: "#461486",
                        marginRight: 8,
                        color: "white",
                      }}
                    >
                      <Typography variant="h4" align="center">
                        Service Initiated Charges
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      style={{
                        alignItems: "center",
                        borderBottom: "1px solid #c4c4c4",
                        background: "#461486",
                        marginRight: 8,
                        color: "white",
                      }}
                    >
                      <Typography variant="h4" align="center">
                        Utility Initiated Charges
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      style={{
                        alignItems: "center",
                        borderBottom: "1px solid #c4c4c4",
                        background: "#461486",
                        marginRight: 8,
                        color: "white",
                      }}
                    >
                      <Typography variant="h4" align="center">
                        Authentication Initiated Charges
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box md={2}></Box>
                  <Grid md={12} style={{ display: "flex" }}>
                    <Grid item md={3} style={{ display: "flex" }}>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          {Object.keys(this.props.tenantDetails).length
                            ? this.props.tenantDetails.name
                            : "AiSensy"}{" "}
                          Prices
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          Margin
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          Your Platform Prices
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item md={3} style={{ display: "flex" }}>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          {Object.keys(this.props.tenantDetails).length
                            ? this.props.tenantDetails.name
                            : "AiSensy"}{" "}
                          Prices
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          Margin
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          Your Platform Prices
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item md={3} style={{ display: "flex" }}>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          {Object.keys(this.props.tenantDetails).length
                            ? this.props.tenantDetails.name
                            : "AiSensy"}{" "}
                          Prices
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          Margin
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          Your Platform Prices
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item md={3} style={{ display: "flex" }}>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          {Object.keys(this.props.tenantDetails).length
                            ? this.props.tenantDetails.name
                            : "AiSensy"}{" "}
                          Prices
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          Margin
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="center"
                        >
                          Your Platform Prices
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  style={{
                    maxHeight: "58vh",
                    overflow: "scroll",
                  }}
                >
                  {aisensy_business_initiated &&
                    Object.keys(aisensy_business_initiated).map((key) => {
                      return (
                        <Grid container key={key} xs={12} md={12}>
                          <WccRow
                            countryCode={key}
                            country={isoToName[key]}
                            aisensyBicPrice={
                              aisensy_business_initiated[key][currency] /
                              convertPrice
                            }
                            partnerBicPrice={
                              business_initiated[key][currency] / convertPrice
                            }
                            bicMargin={
                              business_initiated[key][
                                currency?.toLowerCase() + "Margin"
                              ] / convertPrice
                            }
                            aisensyUicPrice={
                              aisensy_user_initiated[key][currency] /
                              convertPrice
                            }
                            partnerUicPrice={
                              user_initiated[key][currency] / convertPrice
                            }
                            uicMargin={
                              user_initiated[key][
                                currency?.toLowerCase() + "Margin"
                              ] / convertPrice
                            }
                            aisensyAuthenticationInitiated={
                              aisensy_authentication[key][currency] /
                              convertPrice
                            }
                            aisensyUtilityInitiated={
                              aisensy_utility[key][currency] / convertPrice
                            }
                            aisensyServiceInitiated={
                              aisensy_service[key][currency] / convertPrice
                            }
                            aisensyMarketingInitiated={
                              aisensy_marketing[key][currency] / convertPrice
                            }
                            partnerAuthenticationInitiated={
                              authentication_initiated[key][currency] /
                              convertPrice
                            }
                            parterUtilityInitiated={
                              utility_initiated[key][currency] / convertPrice
                            }
                            partnerMarketingInitiated={
                              marketing_initiated[key][currency] / convertPrice
                            }
                            partnerServiceInitiated={
                              service_initiated[key][currency] / convertPrice
                            }
                            serviceMargin={
                              service_initiated[key][
                                currency?.toLowerCase() + "Margin"
                              ] / convertPrice
                            }
                            utilityMargin={
                              utility_initiated[key][
                                currency?.toLowerCase() + "Margin"
                              ] / convertPrice
                            }
                            authenticationMargin={
                              authentication_initiated[key][
                                currency?.toLowerCase() + "Margin"
                              ] / convertPrice
                            }
                            marketingMargin={
                              marketing_initiated[key][
                                currency?.toLowerCase() + "Margin"
                              ] / convertPrice
                            }
                            currency={currency}
                            updateBIC={this.updateBIC}
                            updateUIC={this.updateUIC}
                            updateMc={this.updateMc}
                            upadteAc={this.upadteAc}
                            updateUc={this.updateUc}
                            updateSc={this.updateSc}
                            keyValue={this.state.keyValue}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "24px 16px 8px 0px" }}>
              <Button
                variant={!this.props.disableFields ? "outlined" : "contained"}
                size="medium"
                color="primary"
                onClick={() => {
                  toggleDialog();
                }}
              >
                {this.props.disableFields ? "Close" : "Cancel"}
              </Button>
              {!this.props.disableFields && (
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={this.updateWcc}
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const styles = (theme) => ({
  tabIndicator: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    marginBottom: 6,
  },
  tabHeader: {
    textTransform: "none",
    // borderBottom: "2px solid #f0f0f0",
  },
});

const WccTableDetails = connect(
  (state) => ({
    partnerWccFamily: state.wccPlans.allWccPlans,
    tenantDetails: state.tenant.tenant,
  }),
  {
    modifyWccPlan,
  }
)(WccTable);

const WccTableComponent = withStyles(styles)(WccTableDetails);

export default React.memo(WccTableComponent);
