import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import moment from "moment";
import { Alert, Autocomplete } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import {
  fetchTemplateMessages,
  loadNext,
  unfetchTemplateMessages,
  loadSearch,
} from "./templateState";
import {
  withStyles,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Add, Clear, Close } from "@material-ui/icons";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react-dates-custom.css";

import TemplateMsgTable from "./TemplateMsgTable";
import { TablePagination } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CreateTemplateMsgForm from "./CreateTemplateMsgForm";
import Header from "../../commons/Header/Header";
import axios from "axios";
import { partnerURL, URL } from "../../config/config";
import withMediaQuery from "../../helpers/mediaQueryHelper";
class TemplateMsgPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backupSearchTemplates: [],
      totalCount: null,
      timeStamp: new Date(),
      search: "",
      openDialog: false,
      insideAssistantpage: false,
      templateDialog: false,
      filter: "",
      searchdone: "",
      opensnack: false,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focus: null,
        },
      },
      templateFilter: {
        sort: "dec",
      },
      planSubmitLoader: false,
      disableDate: Number(localStorage.getItem("resetSyncField")) + 300000,
    };
  }

  componentDidMount() {
    const assistantId = this.props.match.params.projectId;
    this.props.unfetchTemplateMessages();

    this.props.fetchTemplateMessages(
      assistantId,
      this.state.templateFilter,
      this.props.partner?.type,
      10,
      null
    );

    if (assistantId != null) {
      this.setState({
        insideAssistantpage: true,
      });
    }
  }

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  toggleTemplateDialog = () => {
    this.setState({ templateDialog: !this.state.templateDialog });
  };
  toggleTemplate = () => {
    this.setState({
      templateDialog: !this.state.templateDialog,
      opensnack: true,
    });
  };
  getPageRows = () => {
    const { templates, page, rowsPerPage } = this.props;
    return templates.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };
  handlesearch = (e) => {
    this.setState({ filter: e.target.value });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleEnter = (e) => {
    if (e.key === "Enter") {
      this.props.unfetchTemplateMessages();
      const assistantId = this.props.match.params.projectId;
      if (this.state.filter === "") {
        this.props.fetchTemplateMessages(
          assistantId,
          this.state.templateFilter,
          this.props.partner?.type,
          10,
          this.props.template?.after
        );
        this.setState({
          searchdone: "not done",
        });
      }
      this.props.loadSearch(
        this.state.filter,
        this.props.partner?.type,
        assistantId
      );
      if (this.state.filter !== "") {
        this.setState({
          searchdone: "its done",
        });
      }
    }
  };

  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };
  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };

  applyFilter = () => {
    const { applied, templateFilter } = this.state;
    const filterObj = { ...templateFilter };
    if (applied.createdAt.startDate && applied.createdAt.endDate) {
      filterObj.fromDate = applied.createdAt.startDate._d;
      filterObj.toDate = applied.createdAt.endDate._d;
    } else {
      delete filterObj.fromDate;
      delete filterObj.toDate;
    }
    this.setState(
      {
        templateFilter: filterObj,
        openDialog: false,
      },
      () => {
        this.onTabChange();
      }
    );
  };
  onTabChange = () => {
    const assistantId = this.props.match.params.projectId;
    this.props.unfetchTemplateMessages();
    this.props.fetchTemplateMessages(
      assistantId,
      this.state.templateFilter,
      this.props.partner?.type,
      10,
      this.props.template?.after
    );
  };

  loadnextpage = (event, newPage) => {
    const assistantId = this.props.match.params.projectId;
    this.props.loadNext(
      event,
      newPage,
      assistantId,
      this.state.templateFilter,
      this.props.partner?.type,
      10,
      this.props.template?.after,
      this.props.template?.totalprojects
    );
  };

  handleClear = () => {
    const assistantId = this.props.match.params.projectId;
    this.setState({
      filter: "",
    });
    this.setState({
      searchdone: "not done",
    });
    this.props.unfetchTemplateMessages();
    this.props.fetchTemplateMessages(
      assistantId,
      this.state.templateFilter,
      this.props.partner?.type,
      10,
      this.props.template?.after
    );
  };

  onSnackbarClose = () => {
    this.setState({
      opensnack: false,
      alertType: "",
      alertMsg: "",
    });
  };

  callSnackbar = (type, msg) => {
    this.setState({
      opensnack: true,
      alertType: type,
      alertMsg: msg,
    });
  };

  syncTemplate = () => {
    this.setState({ planSubmitLoader: true });
    const assistantId = this.props.match.params.projectId;
    const partnerId = this.props.partner._id;
    const checkTime = localStorage.getItem("resetSyncField");
    const resetCheck = Number(checkTime) + 300000;
    if (checkTime) {
      if (resetCheck > Date.now()) {
        this.setState({
          opensnack: true,
          alertType: "error",
          alertMsg: "Please try to sync after few miniutes",
        });
        return;
      }
    }
    axios
      .post(partnerURL + `/partner/${partnerId}/sync-templates`, {
        assistantId,
      })
      .then((response) => {
        this.setState({
          opensnack: true,
          alertType: "success",
          alertMsg: "Sync-template successful",
          planSubmitLoader: false,
          disableDate: Date.now() + 300000,
        });
        localStorage.setItem("resetSyncField", Date.now());
      })
      .catch((error) => {
        this.setState({
          opensnack: true,
          alertType: "error",
          alertMsg: "Sync-template failed",
          planSubmitLoader: false,
        });
      });
  };

  // methods for date filter
  render() {
    const { insideAssistant, templateFilter, disableDate } = this.state;
    const disableTemplateButton =
      Number(disableDate) - Date.now() >= 0 || this.state.planSubmitLoader;
    const tooltipTtile =
      Number(disableDate) - Date.now() >= 0 || this.state.planSubmitLoader;

    const { classes, mediaQuery, partner } = this.props;
    const { type } = partner || {};

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        {(!this.props.assistants || this.state.insideAssistantpage) && (
          <Header
            businessTitle={insideAssistant ? "" : "Template Messages"}
            buttonPrefixIcon={<Add />}
            buttonTitle={"Create New Template"}
            onButtonClick={this.toggleTemplateDialog}
            showSearch={true}
            searchPlaceholder={"Search template name"}
            handleSearch={this.handlesearch}
            handleEnter={this.handleEnter}
            searchDone={this.state.searchdone === "its done"}
            handleClear={this.handleClear}
            filter={this.state.filter}
            toggleFilterDialog={type !== "DIRECT" && this.toggleDialog}
            backgroundColor={"transparent"}
            filterColor={
              !!this.state.applied.createdAt.endDate &&
              !!this.state.applied.createdAt.startDate
            }
            syncButtonTitle={type !== "DIRECT" && "Sync Templates"}
            onSyncButtonClick={this.syncTemplate}
            syncButtonPrefixIcon={<SyncIcon />}
            disableTemplateButton={disableTemplateButton}
            tooltipTitle={
              tooltipTtile ? "Retry again a in few mins" : "Sync Templates"
            }
          />
        )}
        <Box my={insideAssistant ? 1 : 3} />
        {/* Layout */}
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11} lg={10}>
            {/* First section */}
            {/* Scrollable (x & y) table container */}
            <div>
              {!this.props.assistants && this.state.insideAssistantpage ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1" color="textSecondary">
                    Waba is not live yet!
                  </Typography>
                </div>
              ) : (
                <div className={classes.tableContainer}>
                  <TemplateMsgTable
                    templates={this.getPageRows()}
                    isLoading={this.props.isLoading}
                    setTemplateId={this.props.setTemplateId}
                    type={type}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        {/* Table footer fixed at bottom */}
        <div className={classes.fixedBottomContainer}>
          {!this.props.assistants && this.state.insideAssistantpage ? (
            <></>
          ) : (
            <>
              <TablePagination
                component="div"
                count={this.props.totalprojects || 0}
                page={this.props.page}
                onPageChange={this.loadnextpage}
                rowsPerPage={this.props.rowsPerPage}
                rowsPerPageOptions={[25]}
              />
            </>
          )}
        </div>

        <Dialog
          open={this.state.templateDialog}
          maxWidth="md"
          aria-labelledby="form-dialog-title"
        >
          <Grid
            container
            style={{
              marginTop: 16,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" style={{ marginLeft: "24px" }}>
              Create Template
            </Typography>
            <IconButton
              style={{
                marginRight: "20px",
              }}
              onClick={this.toggleTemplateDialog}
            >
              <Close />
            </IconButton>
          </Grid>
          <CreateTemplateMsgForm
            callSnackbar={this.callSnackbar}
            close={this.toggleTemplate}
            classes={this.props.classes}
          />
        </Dialog>

        <Dialog
          open={this.state.openDialog}
          maxWidth="xs"
          aria-labelledby="form-dialog-title"
          className={classes.dialogContainer}
          fullScreen={mediaQuery}
        >
          <DialogContent style={{ height: 400 }}>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                style={{ paddingRight: "0px", paddingTop: "0px" }}
              >
                <Typography variant="h3">Filter</Typography>
                <IconButton onClick={this.toggleDialog}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <DateFilter
                  filterKey="createdAt"
                  filterName="Created At"
                  classes={classes}
                  applied={this.state.applied}
                  setDate={this.setDate}
                  setFocus={this.setFocus}
                  checkDateInput={this.checkDateInput}
                  clearDate={this.clearDate}
                />
              </Grid>
              <Grid
                container
                style={{
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <Grid item xs={12} sm={3}>
                  <Typography>Sort</Typography>
                </Grid>
                <Autocomplete
                  disableClearable
                  value={
                    templateFilter.sort === "dec" ? "Descending" : "Ascending"
                  }
                  style={{ width: "254px", marginLeft: "-4px" }}
                  onChange={(event, value) => {
                    const filterObj = {
                      ...this.state.templateFilter,
                      sort: value === "Descending" ? "dec" : "asc",
                    };
                    this.setState({ templateFilter: filterObj });
                  }}
                  name="sort"
                  options={["Descending", "Ascending"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      classes={{
                        root: classes.textFieldSortAutocomplete,
                      }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{ paddingBottom: mediaQuery ? 72 : 16, paddingRight: 24 }}
          >
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this.clearDate("createdAt")}
                style={{ marginRight: 8 }}
              >
                Clear Filter
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.applyFilter}
              >
                Apply
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.opensnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          className={classes.snackbar}
          onClose={this.onSnackbarClose}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName, match } = props;

  const A = applied[filterKey];
  return (
    <Box mt={2} mb={2} className={classes.filterTypeContainer}>
      <Grid container alignItems="center">
        {A.startDate && A.endDate ? (
          <Grid item md={1}>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} md={3}>
          <Typography variant="body1" style={{ marginRight: 8, minWidth: 100 }}>
            {filterName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "#ffffff",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 80,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    //background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  tableContainer: {
    top: "60px",
    height: "calc(100vh - 180px)",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  textField: {
    width: 300,
    marginRight: 8,
    height: 42,
  },

  sortFormControl: {
    width: 120,
    marginRight: 8,
    height: 32,
    borderRadius: "4px",
    border: "1px solid lightgrey",
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  textFieldSortAutocomplete: {
    width: "120",
    height: 32,
    "& input": {
      padding: "0px 12px!important",
    },
    background: "white",
    borderRadius: "4px",
    border: "1px solid #d3d3d4",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  dialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "600px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

const Templateconnect = connect(
  (state) => ({
    template: state.template,
    templates: state.template.templates,
    skip: state.template.skip,
    totalprojects: state.template.totalprojects,
    partner: state.partner.partner,
    isLoading: state.template.isLoading,
    error: state.template.error,
    page: state.template.page,
    rowsPerPage: state.template.rowsPerPage,
    nameQuery: state.template.nameQuery,
    allBusiness: state.business.allBusiness,
    allProjects: state.projects.allProjects,
  }),
  {
    fetchTemplateMessages,
    loadNext,
    unfetchTemplateMessages,
    loadSearch,
  }
)(TemplateMsgPage);
export default withStyles(styles)(
  withMediaQuery("(max-width:600px)")(withRouter(Templateconnect))
);
