import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  Snackbar,
  Switch,
  CircularProgress,
} from "@material-ui/core";
import { ArrowBackOutlined } from "@material-ui/icons";
import { Autocomplete, Alert } from "@material-ui/lab";
import axios from "axios";

import { URL } from "../../config/config";
import { withRouter } from "react-router-dom";

const PLANS = [
  "FREE",
  "LITE",
  "PRO",
  "PLUS",
  "PREMIUM",
  "BASIC_MONTHLY_TIER_1",
  "BASIC_MONTHLY_TIER_2",
  "BASIC_MONTHLY_TIER_3",
  "BASIC_MONTHLY_TIER_4",
  "BASIC_MONTHLY_TIER_5",
  "BASIC_MONTHLY_TIER_6",
  "BASIC_MONTHLY_TIER_7",
  "BASIC_MONTHLY_TIER_8",
  "BASIC_YEARLY_TIER_1",
  "BASIC_YEARLY_TIER_2",
];

const PROJECT_STATUS = [
  "active",
  "unpaid",
  "terminated",
  "pending",
  "suspended",
  "stopped",
  "archived",
];

function getStatusColor() {
  const templateStatus = "PENDING";
  switch (templateStatus) {
    case "PENDING":
      return "grey";
    case "APPROVED":
      return "#08CF65";
    case "REJECTED":
      return "#ff0000";
    default:
      return "pink";
  }
}

class Assistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "PENDING",
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      remainingCredit: 0,
      activePlan: this.props.template,
      whatsappNumber: "",
      appName: "",
      templateCredit: 0,
      apiKey360: "",
      cachedAssistant: null,
      projectStatus: "",
      // verifiedStatus: null,
    };
  }
  handleInput = (e) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) });
  };
  handleNumberInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateTemplateCredit = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const amount = this.state.templateCredit * 100000;

    const totalTemplateCredit = template.templateCredit
      ? template.templateCredit + amount
      : amount;
    this.setTemplateInDb({
      templateCredit: totalTemplateCredit,
    })
      .then((response) => {
        template.templateCredit = totalTemplateCredit;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Template credit updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to add template credit",
          alertSeverity: "error",
        });
      });
  };
  updateApiKey = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    const apiKey360 = this.state.apiKey360;

    this.setTemplateInDb({
      apiKey360: apiKey360,
    })
      .then((response) => {
        template.apiKey360 = apiKey360;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "API Key updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to add API Key",
          alertSeverity: "error",
        });
      });
  };
  updateVerificationStatus = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const checked = e.target.checked;

    this.setTemplateInDb({
      isWhatsappVerified: checked,
    })
      .then((response) => {
        template.isWhatsappVerified = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Assistant verified status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update verified status",
          alertSeverity: "error",
        });
      });
  };
  updateVerificationStatus = (e) => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const checked = e.target.checked;

    this.setTemplateInDb({
      isWhatsappVerified: checked,
    })
      .then((response) => {
        template.isWhatsappVerified = checked;
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Assistant verified status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update verified status",
          alertSeverity: "error",
        });
      });
  };
  updateWhatsAppNumber = () => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const whatsappNumber = this.state.whatsappNumber;
    template.whatsappNumber = whatsappNumber;

    this.setTemplateInDb({
      whatsappNumber,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Whatsapp number updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update whatsapp number",
          alertSeverity: "error",
        });
      });
  };
  updateAppName = () => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const appName = this.state.appName;
    template.appName = appName;

    this.setTemplateInDb({
      appName,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Whatsapp app name updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update whatsapp app name",
          alertSeverity: "error",
        });
      });
  };
  updateProjectStatus = () => {
    let template = this.props.templates[this.props.templateIndex];
    // const amount = template.remainingCredit + this.state.remainingCredit*100
    // console.log({status: e.target.checked});
    const projectStatus = this.state.projectStatus;
    template.status = projectStatus;

    this.setTemplateInDb({
      status: projectStatus,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Project status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update project status",
          alertSeverity: "error",
        });
      });
  };
  updateCredit = () => {
    let template = { ...this.props.templates[this.props.templateIndex] };
    const amount =
      template.remainingCredit + this.state.remainingCredit * 100000;
    const activePlan = this.state.activePlan || template.activePlan;
    // console.log(template.remainingCredit, this.state.remainingCredit)
    // if (this.state.activePlan) {
    const oldPlan = template.activePlan;

    // Set plan name
    template.activePlan = activePlan;

    // Check plan type
    if (activePlan.includes("BASIC_")) {
      if (!oldPlan.includes("BASIC_")) {
        template.planActivatedOn = null;
      }
      // Check if its renewal or first time
      if (template.planActivatedOn) {
        template.planActivatedOn = new Date(template.planActivatedOn);

        // console.log(template.planActivatedOn);

        // template.planActivatedOn.setMonth(
        //   template.planActivatedOn.getMonth() + 8
        // );
        // console.log(template.planActivatedOn);
        // Get the current date
        const currentDate = template.planActivatedOn.getDate();
        // Set to day 1 to avoid forward
        template.planActivatedOn.setDate(1);
        // Increase month by 1
        template.planActivatedOn.setMonth(
          template.planActivatedOn.getMonth() + 1
        );
        // Get max # of days in this new month
        var daysInMonth = new Date(
          template.planActivatedOn.getYear(),
          template.planActivatedOn.getMonth() + 1,
          0
        ).getDate();
        // Set the date to the minimum of current date of days in month
        template.planActivatedOn.setDate(Math.min(currentDate, daysInMonth));
      } else {
        template.planActivatedOn = new Date();
      }
    } else {
      // v1 pricing
      template.planActivatedOn = new Date();
    }
    // }

    template.remainingCredit = amount;

    this.setTemplateInDb({
      remainingCredit: amount,
      activePlan: template.activePlan,
      planActivatedOn: template.planActivatedOn,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Assistant status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update template status",
          alertSeverity: "error",
        });
      });
  };
  handleAutoComplete = (fieldName, value, reason) => {
    if (reason == "select-option") {
      this.setState({ [fieldName]: value });
    }
  };
  handleMark = (mark, temp) => {
    const template = { ...temp };
    if (mark) {
      // set process to new Date()
      this.setTemplateInDb({ processed: new Date() })
        .then((response) => {
          template.processed = new Date();
          this.props.setTemplate(template);
          this.setState({
            alert: true,
            alertMsg: "Template marked processed!",
            alertSeverity: "success",
          });
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertMsg: "Unable to mark, Check your internet connection.",
            alertSeverity: "error",
          });
        });
    } else {
      this.setTemplateInDb({ processed: null })
        .then((response) => {
          template.processed = null;
          this.props.setTemplate(template);
          this.setState({
            alert: true,
            alertMsg: "Template marked un-processed!",
            alertSeverity: "success",
          });
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertMsg:
              "Unable to fulfill request, Check your internet connection.",
            alertSeverity: "error",
          });
        });
    }
  };
  setTemplateInDb = (fields) => {
    // try {
    const t = this.props.templates[this.props.templateIndex];
    const queryObj = {
      assistantId: t._id,
      fields,
      dailyTemplateLimit: t.dailyTemplateLimit,
    };
    return axios.post(URL + "/superadmin/set-assistant-field", { ...queryObj });
  };
  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };
  getAssistantDetailsFromCache = async () => {
    try {
      const { templateIndex, templates } = this.props;
      const assistant = templates[templateIndex];
      if (!assistant) {
        return;
      }
      const redisKey = "assistants/" + assistant._id;

      const { data: cachedAssistant } = await axios.post(
        URL + "/superadmin/get-credits-from-cache",
        {
          assistantId: assistant._id,
        }
      );

      this.setState({ cachedAssistant });
    } catch (err) {
      console.log(err);
    }
  };
  syncTemplateMsg = async () => {
    try {
      const { templateIndex, templates } = this.props;
      const assistant = templates[templateIndex];
      if (!assistant) {
        return;
      }
      const { data: cachedAssistant } = await axios.post(
        URL + "/superadmin/sync-template-messages",
        {
          assistantId: assistant._id,
        }
      );

      this.setState({
        alert: true,
        alertMsg: "Template msg synched",
        alertSeverity: "success",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        alert: true,
        alertMsg: "Unable update template msg",
        alertSeverity: "error",
      });
    }
  };
  migrateTemplateMsg = async () => {
    try {
      const { templateIndex, templates } = this.props;
      const assistant = templates[templateIndex];
      if (!assistant) {
        return;
      }
      const { data: cachedAssistant } = await axios.post(
        URL + "/superadmin/migrate-all-templates",
        {
          assistantId: assistant._id,
        }
      );

      this.setState({
        alert: true,
        alertMsg: "Template msg migrated",
        alertSeverity: "success",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        alert: true,
        alertMsg: "Unable migrate template msg",
        alertSeverity: "error",
      });
    }
  };
  componentDidMount() {
    this.getAssistantDetailsFromCache();
  }
  render() {
    //changes
    const { classes, toggleDialog, templateIndex, templates, setTemplate } =
      this.props || {};
    const template = templates?.[templateIndex];
    return this.props?.templates ? (
      <Box px={2} className={classes.dialogContainer}>
        <Grid container alignItems="center" className={classes.dialogTopbar}>
          <Grid item>
            <IconButton onClick={toggleDialog}>
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid item>{template.assistantName}</Grid>
        </Grid>
        <div className={classes.root}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box py={2} px={2} my={2}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                ></Grid>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Remaining Credit
                      <ul>
                        <li>
                          For v3, Keep the excessMac reading as New Plan will
                          reset it
                        </li>
                        <li>
                          Default currency in INR for Dollar Billing contact
                          developers
                        </li>
                        <li>Max Agents 5 for v3 pricing</li>
                        <li>35p template msg pricing for v3 pricing</li>
                        <li>
                          v3 monthly pricing & renewal can be done from here
                        </li>
                        <li>
                          v3 yearly plan can be added but can't be renewed from
                          here
                        </li>
                        <li>To upgrade monthly to yearly - Contact devs</li>
                      </ul>
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {template.remainingCredit / 100000}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Add Credit
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="remainingCredit"
                        value={this.state.remainingCredit}
                        onChange={this.handleInput}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Change Plan
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        disableClearable
                        options={PLANS.map((option) => option)}
                        onChange={(e, value, reason) =>
                          this.handleAutoComplete("activePlan", value, reason)
                        }
                        value={template.activePlan}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={classes.textField}
                            InputProps={{ ...params.InputProps }}
                            placeholder="Select plan"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={this.updateCredit}
                        variant="contained"
                        color="primary"
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  WhatsApp Verification Status
                </Typography>
                <Box my={2}>
                  <Switch
                    checked={template.isWhatsappVerified}
                    onChange={this.updateVerificationStatus}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Box>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  For GS only - WhatsApp Number - Enter number with dial code
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Number in use - {template.whatsappNumber}
                </Typography>
                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="whatsappNumber"
                        onChange={this.handleNumberInput}
                        value={this.state.whatsappNumber}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.updateWhatsAppNumber}
                      >
                        Update Number
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  WhatsApp App Name - Enter name (default to AiSensy)
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Number in use - {template.appName}
                </Typography>
                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="appName"
                        onChange={this.handleNumberInput}
                        value={this.state.appName}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.updateAppName}
                      >
                        Update App Name
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Template Credit (v2 & v3) - Enter amount in +/-
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Current Balance -{" "}
                  {template.templateCredit
                    ? template.templateCredit / 100000
                    : 0}
                </Typography>
                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="templateCredit"
                        type="number"
                        onChange={this.handleInput}
                        value={this.state.templateCredit}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.updateTemplateCredit}
                      >
                        Update Template Credit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>{" "}
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  360 API Key (Do this at the end)
                </Typography>
                <Typography variant="body1" gutterBottom>
                  API Key in use - {template.apiKey360}
                </Typography>
                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        style={{ width: "100%" }}
                        name="apiKey360"
                        onChange={this.handleNumberInput}
                        value={this.state.apiKey360}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.updateApiKey}
                      >
                        Update Key
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Cached Assistant
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <div>
                    <pre>
                      {this.state.cachedAssistant
                        ? JSON.stringify(this.state.cachedAssistant, null, 2)
                        : ""}
                    </pre>
                  </div>
                </Typography>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Sync Template Msg ( for migration from GS to 360D only)
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.syncTemplateMsg}
                  >
                    Sync Template Messages
                  </Button>
                </Typography>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Suspend/Activate Project
                </Typography>
                <Grid container>
                  <Grid item xs={5} alignItems="center">
                    <Autocomplete
                      disableClearable
                      options={PROJECT_STATUS.map((option) => option)}
                      onChange={(e, value, reason) =>
                        this.handleAutoComplete("projectStatus", value, reason)
                      }
                      value={template.status}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.textField}
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select Status"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" gutterBottom>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.updateProjectStatus}
                      >
                        Update Status
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Migrate Template Msg ( for migration of new clients only)
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.migrateTemplateMsg}
                  >
                    Migrate Template Messages
                  </Button>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </Box>
    ) : (
      <Box textAlign="center" mt={5}>
        <CircularProgress size={30} />
      </Box>
    );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "rgb(70 20 134 / 15%)",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
});

export default withStyles(styles)(withRouter(Assistant));
