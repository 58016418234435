import React, { useEffect } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

import ErrorPage from "./commons/ErrorPage/ErrorPage";
import ProjectsLayout from "./commons/ProjectsLayout/ProjectsLayout";
import Login from "./pages/Login/Login";
import Embad from "./pages/Embed/EmbedSignup";
import AffiliateSignUp from "./pages/SignUp/AffiliateSignUp";
import forgotPassword from "./pages/forgotPassword/forgotPassword";

import { fetchPartnerDetails } from "./store/partnerState";
import newLogin from "./pages/Login/newLogin";

const PrivateRoute = ({
  component,
  isAuthenticated,
  fetchPartnerDetails,
  ...rest
}) => {
  useEffect(() => {
    fetchPartnerDetails();
  });
  document.title = "Affiliate Dashboard";
  return !isAuthenticated ? (
    <Redirect to={{ pathname: "/login" }} />
  ) : (
    <Route {...rest} component={component} />
  );
};
const PublicRoute = ({ component, isAuthenticated, ...rest }) => {
  document.title = "Affiliate Dashboard";
  return <Route {...rest} component={component} />;
};

const App = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/:partnerId/embed/signup" component={Embad} />

        <PublicRoute
          exact
          path="/login"
          component={newLogin}
          isAuthenticated={props.isAuthenticated}
        />
        <PublicRoute
          exact
          path="/signup"
          component={AffiliateSignUp}
          isAuthenticated={props.isAuthenticated}
        />
         <PublicRoute
          exact
          path="/forgot-password"
          component={forgotPassword}
          isAuthenticated={props.isAuthenticated}
        />
        <PrivateRoute
          path="/"
          component={ProjectsLayout}
          isAuthenticated={props.isAuthenticated}
          fetchPartnerDetails={props.fetchPartnerDetails}
        />
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default connect(
  (state) => ({
    isAuthenticated: state.login.isAuthenticated,
  }),
  {
    fetchPartnerDetails,
  }
)(App);
