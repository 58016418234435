import React, { Component } from "react";
import clsx from 'clsx';
import { withStyles, StepConnector } from "@material-ui/core";
import Check from '@material-ui/icons/Check';

const QontoStepIconStyles = (theme)=>({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
});

const QontoStepIcon = withStyles(QontoStepIconStyles) (function(props){
  const classes = props.classes;
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
})

export default QontoStepIcon
