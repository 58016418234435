import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "../../commons/ErrorPage/ErrorPage";
import ClientDetails from "./ClientDetails";
import ClientsPage from "./ClientsPage";

class ClientRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        <Route exact path={"/links"} component={ClientsPage} />
        <Route path={"/links/:_id"} component={ClientDetails} />
        <Route component={ErrorPage} />
      </Switch>
    );
  }
}

export default ClientRoute;
