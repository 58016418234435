const dialCodes = [
  {
    name: "Afghanistan",
    dialCode: "+93",
    isoCode: "AF",
  },
  {
    name: "Aland Islands",
    dialCode: "+358",
    isoCode: "AX",
  },
  {
    name: "Albania",
    dialCode: "+355",
    isoCode: "AL",
  },
  {
    name: "Algeria",
    dialCode: "+213",
    isoCode: "DZ",
  },
  {
    name: "AmericanSamoa",
    dialCode: "+1684",
    isoCode: "AS",
  },
  {
    name: "Andorra",
    dialCode: "+376",
    isoCode: "AD",
  },
  {
    name: "Angola",
    dialCode: "+244",
    isoCode: "AO",
  },
  {
    name: "Anguilla",
    dialCode: "+1264",
    isoCode: "AI",
  },
  {
    name: "Antarctica",
    dialCode: "+672",
    isoCode: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dialCode: "+1268",
    isoCode: "AG",
  },
  {
    name: "Argentina",
    dialCode: "+54",
    isoCode: "AR",
  },
  {
    name: "Armenia",
    dialCode: "+374",
    isoCode: "AM",
  },
  {
    name: "Aruba",
    dialCode: "+297",
    isoCode: "AW",
  },
  {
    name: "Ascension Island",
    dialCode: "+247",
    isoCode: "AC",
  },
  {
    name: "Australia",
    dialCode: "+61",
    isoCode: "AU",
  },
  {
    name: "Austria",
    dialCode: "+43",
    isoCode: "AT",
  },
  {
    name: "Azerbaijan",
    dialCode: "+994",
    isoCode: "AZ",
  },
  {
    name: "Bahamas",
    dialCode: "+1242",
    isoCode: "BS",
  },
  {
    name: "Bahrain",
    dialCode: "+973",
    isoCode: "BH",
  },
  {
    name: "Bangladesh",
    dialCode: "+880",
    isoCode: "BD",
  },
  {
    name: "Barbados",
    dialCode: "+1246",
    isoCode: "BB",
  },
  {
    name: "Belarus",
    dialCode: "+375",
    isoCode: "BY",
  },
  {
    name: "Belgium",
    dialCode: "+32",
    isoCode: "BE",
  },
  {
    name: "Belize",
    dialCode: "+501",
    isoCode: "BZ",
  },
  {
    name: "Benin",
    dialCode: "+229",
    isoCode: "BJ",
  },
  {
    name: "Bermuda",
    dialCode: "+1441",
    isoCode: "BM",
  },
  {
    name: "Bhutan",
    dialCode: "+975",
    isoCode: "BT",
  },
  {
    name: "Bolivia",
    dialCode: "+591",
    isoCode: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    isoCode: "BA",
  },
  {
    name: "Botswana",
    dialCode: "+267",
    isoCode: "BW",
  },
  {
    name: "Brazil",
    dialCode: "+55",
    isoCode: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dialCode: "+246",
    isoCode: "IO",
  },
  {
    name: "Brunei Darussalam",
    dialCode: "+673",
    isoCode: "BN",
  },
  {
    name: "Bulgaria",
    dialCode: "+359",
    isoCode: "BG",
  },
  {
    name: "Burkina Faso",
    dialCode: "+226",
    isoCode: "BF",
  },
  {
    name: "Burundi",
    dialCode: "+257",
    isoCode: "BI",
  },
  {
    name: "Cambodia",
    dialCode: "+855",
    isoCode: "KH",
  },
  {
    name: "Cameroon",
    dialCode: "+237",
    isoCode: "CM",
  },
  {
    name: "Canada",
    dialCode: "+1",
    isoCode: "CA",
  },
  {
    name: "Cape Verde",
    dialCode: "+238",
    isoCode: "CV",
  },
  {
    name: "Cayman Islands",
    dialCode: "+1345",
    isoCode: "KY",
  },
  {
    name: "Central African Republic",
    dialCode: "+236",
    isoCode: "CF",
  },
  {
    name: "Chad",
    dialCode: "+235",
    isoCode: "TD",
  },
  {
    name: "Chile",
    dialCode: "+56",
    isoCode: "CL",
  },
  {
    name: "China",
    dialCode: "+86",
    isoCode: "CN",
  },
  {
    name: "Christmas Island",
    dialCode: "+61",
    isoCode: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dialCode: "+61",
    isoCode: "CC",
  },
  {
    name: "Colombia",
    dialCode: "+57",
    isoCode: "CO",
  },
  {
    name: "Comoros",
    dialCode: "+269",
    isoCode: "KM",
  },
  {
    name: "Congo",
    dialCode: "+242",
    isoCode: "CG",
  },
  {
    name: "Cook Islands",
    dialCode: "+682",
    isoCode: "CK",
  },
  {
    name: "Costa Rica",
    dialCode: "+506",
    isoCode: "CR",
  },
  {
    name: "Croatia",
    dialCode: "+385",
    isoCode: "HR",
  },
  {
    name: "Cuba",
    dialCode: "+53",
    isoCode: "CU",
  },
  {
    name: "Cyprus",
    dialCode: "+357",
    isoCode: "CY",
  },
  {
    name: "Czech Republic",
    dialCode: "+420",
    isoCode: "CZ",
  },
  {
    name: "Democratic Republic of the Congo",
    dialCode: "+243",
    isoCode: "CD",
  },
  {
    name: "Denmark",
    dialCode: "+45",
    isoCode: "DK",
  },
  {
    name: "Djibouti",
    dialCode: "+253",
    isoCode: "DJ",
  },
  {
    name: "Dominica",
    dialCode: "+1767",
    isoCode: "DM",
  },
  {
    name: "Dominican Republic",
    dialCode: "+1849",
    isoCode: "DO",
  },
  {
    name: "Ecuador",
    dialCode: "+593",
    isoCode: "EC",
  },
  {
    name: "Egypt",
    dialCode: "+20",
    isoCode: "EG",
  },
  {
    name: "El Salvador",
    dialCode: "+503",
    isoCode: "SV",
  },
  {
    name: "Equatorial Guinea",
    dialCode: "+240",
    isoCode: "GQ",
  },
  {
    name: "Eritrea",
    dialCode: "+291",
    isoCode: "ER",
  },
  {
    name: "Estonia",
    dialCode: "+372",
    isoCode: "EE",
  },
  {
    name: "Eswatini",
    dialCode: "+268",
    isoCode: "SZ",
  },
  {
    name: "Ethiopia",
    dialCode: "+251",
    isoCode: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dialCode: "+500",
    isoCode: "FK",
  },
  {
    name: "Faroe Islands",
    dialCode: "+298",
    isoCode: "FO",
  },
  {
    name: "Fiji",
    dialCode: "+679",
    isoCode: "FJ",
  },
  {
    name: "Finland",
    dialCode: "+358",
    isoCode: "FI",
  },
  {
    name: "France",
    dialCode: "+33",
    isoCode: "FR",
  },
  {
    name: "French Guiana",
    dialCode: "+594",
    isoCode: "GF",
  },
  {
    name: "French Polynesia",
    dialCode: "+689",
    isoCode: "PF",
  },
  {
    name: "Gabon",
    dialCode: "+241",
    isoCode: "GA",
  },
  {
    name: "Gambia",
    dialCode: "+220",
    isoCode: "GM",
  },
  {
    name: "Georgia",
    dialCode: "+995",
    isoCode: "GE",
  },
  {
    name: "Germany",
    dialCode: "+49",
    isoCode: "DE",
  },
  {
    name: "Ghana",
    dialCode: "+233",
    isoCode: "GH",
  },
  {
    name: "Gibraltar",
    dialCode: "+350",
    isoCode: "GI",
  },
  {
    name: "Greece",
    dialCode: "+30",
    isoCode: "GR",
  },
  {
    name: "Greenland",
    dialCode: "+299",
    isoCode: "GL",
  },
  {
    name: "Grenada",
    dialCode: "+1473",
    isoCode: "GD",
  },
  {
    name: "Guadeloupe",
    dialCode: "+590",
    isoCode: "GP",
  },
  {
    name: "Guam",
    dialCode: "+1671",
    isoCode: "GU",
  },
  {
    name: "Guatemala",
    dialCode: "+502",
    isoCode: "GT",
  },
  {
    name: "Guernsey",
    dialCode: "+44",
    isoCode: "GG",
  },
  {
    name: "Guinea",
    dialCode: "+224",
    isoCode: "GN",
  },
  {
    name: "Guinea-Bissau",
    dialCode: "+245",
    isoCode: "GW",
  },
  {
    name: "Guyana",
    dialCode: "+592",
    isoCode: "GY",
  },
  {
    name: "Haiti",
    dialCode: "+509",
    isoCode: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dialCode: "+379",
    isoCode: "VA",
  },
  {
    name: "Honduras",
    dialCode: "+504",
    isoCode: "HN",
  },
  {
    name: "Hong Kong",
    dialCode: "+852",
    isoCode: "HK",
  },
  {
    name: "Hungary",
    dialCode: "+36",
    isoCode: "HU",
  },
  {
    name: "Iceland",
    dialCode: "+354",
    isoCode: "IS",
  },
  {
    name: "India",
    dialCode: "+91",
    isoCode: "IN",
  },
  {
    name: "Indonesia",
    dialCode: "+62",
    isoCode: "ID",
  },
  {
    name: "Iran",
    dialCode: "+98",
    isoCode: "IR",
  },
  {
    name: "Iraq",
    dialCode: "+964",
    isoCode: "IQ",
  },
  {
    name: "Ireland",
    dialCode: "+353",
    isoCode: "IE",
  },
  {
    name: "Isle of Man",
    dialCode: "+44",
    isoCode: "IM",
  },
  {
    name: "Israel",
    dialCode: "+972",
    isoCode: "IL",
  },
  {
    name: "Italy",
    dialCode: "+39",
    isoCode: "IT",
  },
  {
    name: "Ivory Coast / Cote d'Ivoire",
    dialCode: "+225",
    isoCode: "CI",
  },
  {
    name: "Jamaica",
    dialCode: "+1876",
    isoCode: "JM",
  },
  {
    name: "Japan",
    dialCode: "+81",
    isoCode: "JP",
  },
  {
    name: "Jersey",
    dialCode: "+44",
    isoCode: "JE",
  },
  {
    name: "Jordan",
    dialCode: "+962",
    isoCode: "JO",
  },
  {
    name: "Kazakhstan",
    dialCode: "+77",
    isoCode: "KZ",
  },
  {
    name: "Kenya",
    dialCode: "+254",
    isoCode: "KE",
  },
  {
    name: "Kiribati",
    dialCode: "+686",
    isoCode: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: "+850",
    isoCode: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dialCode: "+82",
    isoCode: "KR",
  },
  {
    name: "Kosovo",
    dialCode: "+383",
    isoCode: "XK",
  },
  {
    name: "Kuwait",
    dialCode: "+965",
    isoCode: "KW",
  },
  {
    name: "Kyrgyzstan",
    dialCode: "+996",
    isoCode: "KG",
  },
  {
    name: "Laos",
    dialCode: "+856",
    isoCode: "LA",
  },
  {
    name: "Latvia",
    dialCode: "+371",
    isoCode: "LV",
  },
  {
    name: "Lebanon",
    dialCode: "+961",
    isoCode: "LB",
  },
  {
    name: "Lesotho",
    dialCode: "+266",
    isoCode: "LS",
  },
  {
    name: "Liberia",
    dialCode: "+231",
    isoCode: "LR",
  },
  {
    name: "Libya",
    dialCode: "+218",
    isoCode: "LY",
  },
  {
    name: "Liechtenstein",
    dialCode: "+423",
    isoCode: "LI",
  },
  {
    name: "Lithuania",
    dialCode: "+370",
    isoCode: "LT",
  },
  {
    name: "Luxembourg",
    dialCode: "+352",
    isoCode: "LU",
  },
  {
    name: "Macau",
    dialCode: "+853",
    isoCode: "MO",
  },
  {
    name: "Madagascar",
    dialCode: "+261",
    isoCode: "MG",
  },
  {
    name: "Malawi",
    dialCode: "+265",
    isoCode: "MW",
  },
  {
    name: "Malaysia",
    dialCode: "+60",
    isoCode: "MY",
  },
  {
    name: "Maldives",
    dialCode: "+960",
    isoCode: "MV",
  },
  {
    name: "Mali",
    dialCode: "+223",
    isoCode: "ML",
  },
  {
    name: "Malta",
    dialCode: "+356",
    isoCode: "MT",
  },
  {
    name: "Marshall Islands",
    dialCode: "+692",
    isoCode: "MH",
  },
  {
    name: "Martinique",
    dialCode: "+596",
    isoCode: "MQ",
  },
  {
    name: "Mauritania",
    dialCode: "+222",
    isoCode: "MR",
  },
  {
    name: "Mauritius",
    dialCode: "+230",
    isoCode: "MU",
  },
  {
    name: "Mayotte",
    dialCode: "+262",
    isoCode: "YT",
  },
  {
    name: "Mexico",
    dialCode: "+52",
    isoCode: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dialCode: "+691",
    isoCode: "FM",
  },
  {
    name: "Moldova",
    dialCode: "+373",
    isoCode: "MD",
  },
  {
    name: "Monaco",
    dialCode: "+377",
    isoCode: "MC",
  },
  {
    name: "Mongolia",
    dialCode: "+976",
    isoCode: "MN",
  },
  {
    name: "Montenegro",
    dialCode: "+382",
    isoCode: "ME",
  },
  {
    name: "Montserrat",
    dialCode: "+1664",
    isoCode: "MS",
  },
  {
    name: "Morocco",
    dialCode: "+212",
    isoCode: "MA",
  },
  {
    name: "Mozambique",
    dialCode: "+258",
    isoCode: "MZ",
  },
  {
    name: "Myanmar",
    dialCode: "+95",
    isoCode: "MM",
  },
  {
    name: "Namibia",
    dialCode: "+264",
    isoCode: "NA",
  },
  {
    name: "Nauru",
    dialCode: "+674",
    isoCode: "NR",
  },
  {
    name: "Nepal",
    dialCode: "+977",
    isoCode: "NP",
  },
  {
    name: "Netherlands",
    dialCode: "+31",
    isoCode: "NL",
  },
  {
    name: "Netherlands Antilles",
    dialCode: "+599",
    isoCode: "AN",
  },
  {
    name: "New Caledonia",
    dialCode: "+687",
    isoCode: "NC",
  },
  {
    name: "New Zealand",
    dialCode: "+64",
    isoCode: "NZ",
  },
  {
    name: "Nicaragua",
    dialCode: "+505",
    isoCode: "NI",
  },
  {
    name: "Niger",
    dialCode: "+227",
    isoCode: "NE",
  },
  {
    name: "Nigeria",
    dialCode: "+234",
    isoCode: "NG",
  },
  {
    name: "Niue",
    dialCode: "+683",
    isoCode: "NU",
  },
  {
    name: "Norfolk Island",
    dialCode: "+672",
    isoCode: "NF",
  },
  {
    name: "North Macedonia",
    dialCode: "+389",
    isoCode: "MK",
  },
  {
    name: "Northern Mariana Islands",
    dialCode: "+1670",
    isoCode: "MP",
  },
  {
    name: "Norway",
    dialCode: "+47",
    isoCode: "NO",
  },
  {
    name: "Oman",
    dialCode: "+968",
    isoCode: "OM",
  },
  {
    name: "Pakistan",
    dialCode: "+92",
    isoCode: "PK",
  },
  {
    name: "Palau",
    dialCode: "+680",
    isoCode: "PW",
  },
  {
    name: "Palestine",
    dialCode: "+970",
    isoCode: "PS",
  },
  {
    name: "Panama",
    dialCode: "+507",
    isoCode: "PA",
  },
  {
    name: "Papua New Guinea",
    dialCode: "+675",
    isoCode: "PG",
  },
  {
    name: "Paraguay",
    dialCode: "+595",
    isoCode: "PY",
  },
  {
    name: "Peru",
    dialCode: "+51",
    isoCode: "PE",
  },
  {
    name: "Philippines",
    dialCode: "+63",
    isoCode: "PH",
  },
  {
    name: "Pitcairn",
    dialCode: "+872",
    isoCode: "PN",
  },
  {
    name: "Poland",
    dialCode: "+48",
    isoCode: "PL",
  },
  {
    name: "Portugal",
    dialCode: "+351",
    isoCode: "PT",
  },
  {
    name: "Puerto Rico",
    dialCode: "+1939",
    isoCode: "PR",
  },
  {
    name: "Qatar",
    dialCode: "+974",
    isoCode: "QA",
  },
  {
    name: "Reunion",
    dialCode: "+262",
    isoCode: "RE",
  },
  {
    name: "Romania",
    dialCode: "+40",
    isoCode: "RO",
  },
  {
    name: "Russia",
    dialCode: "+7",
    isoCode: "RU",
  },
  {
    name: "Rwanda",
    dialCode: "+250",
    isoCode: "RW",
  },
  {
    name: "Saint Barthelemy",
    dialCode: "+590",
    isoCode: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dialCode: "+290",
    isoCode: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dialCode: "+1869",
    isoCode: "KN",
  },
  {
    name: "Saint Lucia",
    dialCode: "+1758",
    isoCode: "LC",
  },
  {
    name: "Saint Martin",
    dialCode: "+590",
    isoCode: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dialCode: "+508",
    isoCode: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dialCode: "+1784",
    isoCode: "VC",
  },
  {
    name: "Samoa",
    dialCode: "+685",
    isoCode: "WS",
  },
  {
    name: "San Marino",
    dialCode: "+378",
    isoCode: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dialCode: "+239",
    isoCode: "ST",
  },
  {
    name: "Saudi Arabia",
    dialCode: "+966",
    isoCode: "SA",
  },
  {
    name: "Senegal",
    dialCode: "+221",
    isoCode: "SN",
  },
  {
    name: "Serbia",
    dialCode: "+381",
    isoCode: "RS",
  },
  {
    name: "Seychelles",
    dialCode: "+248",
    isoCode: "SC",
  },
  {
    name: "Sierra Leone",
    dialCode: "+232",
    isoCode: "SL",
  },
  {
    name: "Singapore",
    dialCode: "+65",
    isoCode: "SG",
  },
  {
    name: "Sint Maarten",
    dialCode: "+1721",
    isoCode: "SX",
  },
  {
    name: "Slovakia",
    dialCode: "+421",
    isoCode: "SK",
  },
  {
    name: "Slovenia",
    dialCode: "+386",
    isoCode: "SI",
  },
  {
    name: "Solomon Islands",
    dialCode: "+677",
    isoCode: "SB",
  },
  {
    name: "Somalia",
    dialCode: "+252",
    isoCode: "SO",
  },
  {
    name: "South Africa",
    dialCode: "+27",
    isoCode: "ZA",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dialCode: "+500",
    isoCode: "GS",
  },
  {
    name: "South Sudan",
    dialCode: "+211",
    isoCode: "SS",
  },
  {
    name: "Spain",
    dialCode: "+34",
    isoCode: "ES",
  },
  {
    name: "Sri Lanka",
    dialCode: "+94",
    isoCode: "LK",
  },
  {
    name: "Sudan",
    dialCode: "+249",
    isoCode: "SD",
  },
  {
    name: "Suriname",
    dialCode: "+597",
    isoCode: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dialCode: "+47",
    isoCode: "SJ",
  },
  {
    name: "Sweden",
    dialCode: "+46",
    isoCode: "SE",
  },
  {
    name: "Switzerland",
    dialCode: "+41",
    isoCode: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dialCode: "+963",
    isoCode: "SY",
  },
  {
    name: "Taiwan",
    dialCode: "+886",
    isoCode: "TW",
  },
  {
    name: "Tajikistan",
    dialCode: "+992",
    isoCode: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dialCode: "+255",
    isoCode: "TZ",
  },
  {
    name: "Thailand",
    dialCode: "+66",
    isoCode: "TH",
  },
  {
    name: "Timor-Leste",
    dialCode: "+670",
    isoCode: "TL",
  },
  {
    name: "Togo",
    dialCode: "+228",
    isoCode: "TG",
  },
  {
    name: "Tokelau",
    dialCode: "+690",
    isoCode: "TK",
  },
  {
    name: "Tonga",
    dialCode: "+676",
    isoCode: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dialCode: "+1868",
    isoCode: "TT",
  },
  {
    name: "Tunisia",
    dialCode: "+216",
    isoCode: "TN",
  },
  {
    name: "Turkey",
    dialCode: "+90",
    isoCode: "TR",
  },
  {
    name: "Turkmenistan",
    dialCode: "+993",
    isoCode: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dialCode: "+1649",
    isoCode: "TC",
  },
  {
    name: "Tuvalu",
    dialCode: "+688",
    isoCode: "TV",
  },
  {
    name: "Uganda",
    dialCode: "+256",
    isoCode: "UG",
  },
  {
    name: "Ukraine",
    dialCode: "+380",
    isoCode: "UA",
  },
  {
    name: "United Arab Emirates",
    dialCode: "+971",
    isoCode: "AE",
  },
  {
    name: "United Kingdom",
    dialCode: "+44",
    isoCode: "GB",
  },
  {
    name: "United States",
    dialCode: "+1",
    isoCode: "US",
  },
  {
    name: "Uruguay",
    dialCode: "+598",
    isoCode: "UY",
  },
  {
    name: "Uzbekistan",
    dialCode: "+998",
    isoCode: "UZ",
  },
  {
    name: "Vanuatu",
    dialCode: "+678",
    isoCode: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dialCode: "+58",
    isoCode: "VE",
  },
  {
    name: "Vietnam",
    dialCode: "+84",
    isoCode: "VN",
  },
  {
    name: "Virgin Islands, British",
    dialCode: "+1284",
    isoCode: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dialCode: "+1340",
    isoCode: "VI",
  },
  {
    name: "Wallis and Futuna",
    dialCode: "+681",
    isoCode: "WF",
  },
  {
    name: "Yemen",
    dialCode: "+967",
    isoCode: "YE",
  },
  {
    name: "Zambia",
    dialCode: "+260",
    isoCode: "ZM",
  },
  {
    name: "Zimbabwe",
    dialCode: "+263",
    isoCode: "ZW",
  },
];

export const isoToName = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "AmericanSamoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AC: "Ascension Island",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CK: "Cook Islands",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  CD: "Democratic Republic of the Congo",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  CI: "Ivory Coast / Cote d'Ivoire",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Korea, Democratic People's Republic of Korea",
  KR: "Korea, Republic of South Korea",
  XK: "Kosovo",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Laos",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macau",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States of Micronesia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MK: "North Macedonia",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russia",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena, Ascension and Tristan Da Cunha",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela, Bolivarian Republic of Venezuela",
  VN: "Vietnam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis and Futuna",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  CW: "599",
  OTHER: "Others",
};

// export const isoMap = {
//   "+93": "AF",
//   "+358": "FI",
//   "+355": "AL",
//   "+213": "DZ",
//   "+1684": "AS",
//   "+376": "AD",
//   "+244": "AO",
//   "+1264": "AI",
//   "+672": "NF",
//   "+1268": "AG",
//   "+54": "AR",
//   "+374": "AM",
//   "+297": "AW",
//   "+247": "AC",
//   "+61": "CC",
//   "+43": "AT",
//   "+994": "AZ",
//   "+1242": "BS",
//   "+973": "BH",
//   "+880": "BD",
//   "+1246": "BB",
//   "+375": "BY",
//   "+32": "BE",
//   "+501": "BZ",
//   "+229": "BJ",
//   "+1441": "BM",
//   "+975": "BT",
//   "+591": "BO",
//   "+387": "BA",
//   "+267": "BW",
//   "+55": "BR",
//   "+246": "IO",
//   "+673": "BN",
//   "+359": "BG",
//   "+226": "BF",
//   "+257": "BI",
//   "+855": "KH",
//   "+237": "CM",
//   "+1": "US",
//   "+238": "CV",
//   "+1345": "KY",
//   "+236": "CF",
//   "+235": "TD",
//   "+56": "CL",
//   "+86": "CN",
//   "+57": "CO",
//   "+269": "KM",
//   "+242": "CG",
//   "+682": "CK",
//   "+506": "CR",
//   "+385": "HR",
//   "+53": "CU",
//   "+357": "CY",
//   "+420": "CZ",
//   "+243": "CD",
//   "+45": "DK",
//   "+253": "DJ",
//   "+1767": "DM",
//   "+1849": "DO",
//   "+593": "EC",
//   "+20": "EG",
//   "+503": "SV",
//   "+240": "GQ",
//   "+291": "ER",
//   "+372": "EE",
//   "+268": "SZ",
//   "+251": "ET",
//   "+500": "GS",
//   "+298": "FO",
//   "+679": "FJ",
//   "+33": "FR",
//   "+594": "GF",
//   "+689": "PF",
//   "+241": "GA",
//   "+220": "GM",
//   "+995": "GE",
//   "+49": "DE",
//   "+233": "GH",
//   "+350": "GI",
//   "+30": "GR",
//   "+299": "GL",
//   "+1473": "GD",
//   "+590": "MF",
//   "+1671": "GU",
//   "+502": "GT",
//   "+44": "GB",
//   "+224": "GN",
//   "+245": "GW",
//   "+592": "GY",
//   "+509": "HT",
//   "+379": "VA",
//   "+504": "HN",
//   "+852": "HK",
//   "+36": "HU",
//   "+354": "IS",
//   "+91": "IN",
//   "+62": "ID",
//   "+98": "IR",
//   "+964": "IQ",
//   "+353": "IE",
//   "+972": "IL",
//   "+39": "IT",
//   "+225": "CI",
//   "+1876": "JM",
//   "+81": "JP",
//   "+962": "JO",
//   "+77": "KZ",
//   "+254": "KE",
//   "+686": "KI",
//   "+850": "KP",
//   "+82": "KR",
//   "+383": "XK",
//   "+965": "KW",
//   "+996": "KG",
//   "+856": "LA",
//   "+371": "LV",
//   "+961": "LB",
//   "+266": "LS",
//   "+231": "LR",
//   "+218": "LY",
//   "+423": "LI",
//   "+370": "LT",
//   "+352": "LU",
//   "+853": "MO",
//   "+261": "MG",
//   "+265": "MW",
//   "+60": "MY",
//   "+960": "MV",
//   "+223": "ML",
//   "+356": "MT",
//   "+692": "MH",
//   "+596": "MQ",
//   "+222": "MR",
//   "+230": "MU",
//   "+262": "RE",
//   "+52": "MX",
//   "+691": "FM",
//   "+373": "MD",
//   "+377": "MC",
//   "+976": "MN",
//   "+382": "ME",
//   "+1664": "MS",
//   "+212": "MA",
//   "+258": "MZ",
//   "+95": "MM",
//   "+264": "NA",
//   "+674": "NR",
//   "+977": "NP",
//   "+31": "NL",
//   "+599": "AN",
//   "+687": "NC",
//   "+64": "NZ",
//   "+505": "NI",
//   "+227": "NE",
//   "+234": "NG",
//   "+683": "NU",
//   "+389": "MK",
//   "+1670": "MP",
//   "+47": "SJ",
//   "+968": "OM",
//   "+92": "PK",
//   "+680": "PW",
//   "+970": "PS",
//   "+507": "PA",
//   "+675": "PG",
//   "+595": "PY",
//   "+51": "PE",
//   "+63": "PH",
//   "+872": "PN",
//   "+48": "PL",
//   "+351": "PT",
//   "+1939": "PR",
//   "+974": "QA",
//   "+40": "RO",
//   "+7": "RU",
//   "+250": "RW",
//   "+290": "SH",
//   "+1869": "KN",
//   "+1758": "LC",
//   "+508": "PM",
//   "+1784": "VC",
//   "+685": "WS",
//   "+378": "SM",
//   "+239": "ST",
//   "+966": "SA",
//   "+221": "SN",
//   "+381": "RS",
//   "+248": "SC",
//   "+232": "SL",
//   "+65": "SG",
//   "+1721": "SX",
//   "+421": "SK",
//   "+386": "SI",
//   "+677": "SB",
//   "+252": "SO",
//   "+27": "ZA",
//   "+211": "SS",
//   "+34": "ES",
//   "+94": "LK",
//   "+249": "SD",
//   "+597": "SR",
//   "+46": "SE",
//   "+41": "CH",
//   "+963": "SY",
//   "+886": "TW",
//   "+992": "TJ",
//   "+255": "TZ",
//   "+66": "TH",
//   "+670": "TL",
//   "+228": "TG",
//   "+690": "TK",
//   "+676": "TO",
//   "+1868": "TT",
//   "+216": "TN",
//   "+90": "TR",
//   "+993": "TM",
//   "+1649": "TC",
//   "+688": "TV",
//   "+256": "UG",
//   "+380": "UA",
//   "+971": "AE",
//   "+598": "UY",
//   "+998": "UZ",
//   "+678": "VU",
//   "+58": "VE",
//   "+84": "VN",
//   "+1284": "VG",
//   "+1340": "VI",
//   "+681": "WF",
//   "+967": "YE",
//   "+260": "ZM",
//   "+263": "ZW",
// };

export const countryMap = {
  1: "United States",
  7: "Russia",
  20: "Egypt",
  27: "South Africa",
  30: "Greece",
  31: "Netherlands",
  32: "Belgium",
  33: "France",
  34: "Spain",
  36: "Hungary",
  39: "Italy",
  40: "Romania",
  41: "Switzerland",
  43: "Austria",
  44: "United Kingdom",
  45: "Denmark",
  46: "Sweden",
  47: "Svalbard and Jan Mayen",
  48: "Poland",
  49: "Germany",
  51: "Peru",
  52: "Mexico",
  53: "Cuba",
  54: "Argentina",
  55: "Brazil",
  56: "Chile",
  57: "Colombia",
  58: "Venezuela, Bolivarian Republic of Venezuela",
  60: "Malaysia",
  61: "Cocos (Keeling) Islands",
  62: "Indonesia",
  63: "Philippines",
  64: "New Zealand",
  65: "Singapore",
  66: "Thailand",
  77: "Kazakhstan",
  81: "Japan",
  82: "Korea, Republic of South Korea",
  84: "Vietnam",
  86: "China",
  90: "Turkey",
  91: "India",
  92: "Pakistan",
  93: "Afghanistan",
  94: "Sri Lanka",
  95: "Myanmar",
  98: "Iran",
  211: "South Sudan",
  212: "Morocco",
  213: "Algeria",
  216: "Tunisia",
  218: "Libya",
  220: "Gambia",
  221: "Senegal",
  222: "Mauritania",
  223: "Mali",
  224: "Guinea",
  225: "Ivory Coast / Cote d'Ivoire",
  226: "Burkina Faso",
  227: "Niger",
  228: "Togo",
  229: "Benin",
  230: "Mauritius",
  231: "Liberia",
  232: "Sierra Leone",
  233: "Ghana",
  234: "Nigeria",
  235: "Chad",
  236: "Central African Republic",
  237: "Cameroon",
  238: "Cape Verde",
  239: "Sao Tome and Principe",
  240: "Equatorial Guinea",
  241: "Gabon",
  242: "Congo",
  243: "Democratic Republic of the Congo",
  244: "Angola",
  245: "Guinea-Bissau",
  246: "British Indian Ocean Territory",
  247: "Ascension Island",
  248: "Seychelles",
  249: "Sudan",
  250: "Rwanda",
  251: "Ethiopia",
  252: "Somalia",
  253: "Djibouti",
  254: "Kenya",
  255: "Tanzania, United Republic of Tanzania",
  256: "Uganda",
  257: "Burundi",
  258: "Mozambique",
  260: "Zambia",
  261: "Madagascar",
  262: "Reunion",
  263: "Zimbabwe",
  264: "Namibia",
  265: "Malawi",
  266: "Lesotho",
  267: "Botswana",
  268: "Eswatini",
  269: "Comoros",
  290: "Saint Helena, Ascension and Tristan Da Cunha",
  291: "Eritrea",
  297: "Aruba",
  298: "Faroe Islands",
  299: "Greenland",
  350: "Gibraltar",
  351: "Portugal",
  352: "Luxembourg",
  353: "Ireland",
  354: "Iceland",
  355: "Albania",
  356: "Malta",
  357: "Cyprus",
  358: "Finland",
  359: "Bulgaria",
  370: "Lithuania",
  371: "Latvia",
  372: "Estonia",
  373: "Moldova",
  374: "Armenia",
  375: "Belarus",
  376: "Andorra",
  377: "Monaco",
  378: "San Marino",
  379: "Holy See (Vatican City State)",
  380: "Ukraine",
  381: "Serbia",
  382: "Montenegro",
  383: "Kosovo",
  385: "Croatia",
  386: "Slovenia",
  387: "Bosnia and Herzegovina",
  389: "North Macedonia",
  420: "Czech Republic",
  421: "Slovakia",
  423: "Liechtenstein",
  500: "South Georgia and the South Sandwich Islands",
  501: "Belize",
  502: "Guatemala",
  503: "El Salvador",
  504: "Honduras",
  505: "Nicaragua",
  506: "Costa Rica",
  507: "Panama",
  508: "Saint Pierre and Miquelon",
  509: "Haiti",
  590: "Saint Martin",
  591: "Bolivia",
  592: "Guyana",
  593: "Ecuador",
  594: "French Guiana",
  595: "Paraguay",
  596: "Martinique",
  597: "Suriname",
  598: "Uruguay",
  599: "Netherlands Antilles",
  670: "Timor-Leste",
  672: "Norfolk Island",
  673: "Brunei Darussalam",
  674: "Nauru",
  675: "Papua New Guinea",
  676: "Tonga",
  677: "Solomon Islands",
  678: "Vanuatu",
  679: "Fiji",
  680: "Palau",
  681: "Wallis and Futuna",
  682: "Cook Islands",
  683: "Niue",
  685: "Samoa",
  686: "Kiribati",
  687: "New Caledonia",
  688: "Tuvalu",
  689: "French Polynesia",
  690: "Tokelau",
  691: "Micronesia",
  692: "Marshall Islands",
  850: "public of Korea",
  852: "Hong Kong",
  853: "Macau",
  855: "Cambodia",
  856: "Laos",
  872: "Pitcairn",
  880: "Bangladesh",
  886: "Taiwan",
  960: "Maldives",
  961: "Lebanon",
  962: "Jordan",
  963: "Syrian Arab Republic",
  964: "Iraq",
  965: "Kuwait",
  966: "Saudi Arabia",
  967: "Yemen",
  968: "Oman",
  970: "Palestine",
  971: "United Arab Emirates",
  972: "Israel",
  973: "Bahrain",
  974: "Qatar",
  975: "Bhutan",
  976: "Mongolia",
  977: "Nepal",
  992: "Tajikistan",
  993: "Turkmenistan",
  994: "Azerbaijan",
  995: "Georgia",
  996: "Kyrgyzstan",
  998: "Uzbekistan",
  1242: "Bahamas",
  1246: "Barbados",
  1264: "Anguilla",
  1268: "Antigua and Barbuda",
  1284: "Virgin Islands, British",
  1340: "Virgin Islands, U.S.",
  1345: "Cayman Islands",
  1441: "Bermuda",
  1473: "Grenada",
  1649: "Turks and Caicos Islands",
  1664: "Montserrat",
  1670: "Northern Mariana Islands",
  1671: "Guam",
  1684: "AmericanSamoa",
  1721: "Sint Maarten",
  1758: "Saint Lucia",
  1767: "Dominica",
  1784: "Saint Vincent and the Grenadines",
  1849: "Dominican Republic",
  1868: "Trinidad and Tobago",
  1869: "Saint Kitts and Nevis",
  1876: "Jamaica",
  1939: "Puerto Rico",
};

export const isoMap = {
  "+93": "AF",
  "+358": "FI",
  "+355": "AL",
  "+213": "DZ",
  "+1684": "AS",
  "+376": "AD",
  "+244": "AO",
  "+1264": "AI",
  "+672": "NF",
  "+1268": "AG",
  "+54": "AR",
  "+374": "AM",
  "+297": "AW",
  "+247": "AC",
  "+61": "CC",
  "+43": "AT",
  "+994": "AZ",
  "+1242": "BS",
  "+973": "BH",
  "+880": "BD",
  "+1246": "BB",
  "+375": "BY",
  "+32": "BE",
  "+501": "BZ",
  "+229": "BJ",
  "+1441": "BM",
  "+975": "BT",
  "+591": "BO",
  "+387": "BA",
  "+267": "BW",
  "+55": "BR",
  "+246": "IO",
  "+673": "BN",
  "+359": "BG",
  "+226": "BF",
  "+257": "BI",
  "+855": "KH",
  "+237": "CM",
  "+1": "US",
  "+238": "CV",
  "+1345": "KY",
  "+236": "CF",
  "+235": "TD",
  "+56": "CL",
  "+86": "CN",
  "+57": "CO",
  "+269": "KM",
  "+242": "CG",
  "+682": "CK",
  "+506": "CR",
  "+385": "HR",
  "+53": "CU",
  "+357": "CY",
  "+420": "CZ",
  "+243": "CD",
  "+45": "DK",
  "+253": "DJ",
  "+1767": "DM",
  "+1849": "DO",
  "+593": "EC",
  "+20": "EG",
  "+503": "SV",
  "+240": "GQ",
  "+291": "ER",
  "+372": "EE",
  "+268": "SZ",
  "+251": "ET",
  "+500": "GS",
  "+298": "FO",
  "+679": "FJ",
  "+33": "FR",
  "+594": "GF",
  "+689": "PF",
  "+241": "GA",
  "+220": "GM",
  "+995": "GE",
  "+49": "DE",
  "+233": "GH",
  "+350": "GI",
  "+30": "GR",
  "+299": "GL",
  "+1473": "GD",
  "+590": "MF",
  "+1671": "GU",
  "+502": "GT",
  "+44": "GB",
  "+224": "GN",
  "+245": "GW",
  "+592": "GY",
  "+509": "HT",
  "+379": "VA",
  "+504": "HN",
  "+852": "HK",
  "+36": "HU",
  "+354": "IS",
  "+91": "IN",
  "+62": "ID",
  "+98": "IR",
  "+964": "IQ",
  "+353": "IE",
  "+972": "IL",
  "+39": "IT",
  "+225": "CI",
  "+1876": "JM",
  "+81": "JP",
  "+962": "JO",
  "+77": "KZ",
  "+254": "KE",
  "+686": "KI",
  "+850": "KP",
  "+82": "KR",
  "+383": "XK",
  "+965": "KW",
  "+996": "KG",
  "+856": "LA",
  "+371": "LV",
  "+961": "LB",
  "+266": "LS",
  "+231": "LR",
  "+218": "LY",
  "+423": "LI",
  "+370": "LT",
  "+352": "LU",
  "+853": "MO",
  "+261": "MG",
  "+265": "MW",
  "+60": "MY",
  "+960": "MV",
  "+223": "ML",
  "+356": "MT",
  "+692": "MH",
  "+596": "MQ",
  "+222": "MR",
  "+230": "MU",
  "+262": "RE",
  "+52": "MX",
  "+691": "FM",
  "+373": "MD",
  "+377": "MC",
  "+976": "MN",
  "+382": "ME",
  "+1664": "MS",
  "+212": "MA",
  "+258": "MZ",
  "+95": "MM",
  "+264": "NA",
  "+674": "NR",
  "+977": "NP",
  "+31": "NL",
  "+599": "AN",
  "+687": "NC",
  "+64": "NZ",
  "+505": "NI",
  "+227": "NE",
  "+234": "NG",
  "+683": "NU",
  "+389": "MK",
  "+1670": "MP",
  "+47": "SJ",
  "+968": "OM",
  "+92": "PK",
  "+680": "PW",
  "+970": "PS",
  "+507": "PA",
  "+675": "PG",
  "+595": "PY",
  "+51": "PE",
  "+63": "PH",
  "+872": "PN",
  "+48": "PL",
  "+351": "PT",
  "+1939": "PR",
  "+974": "QA",
  "+40": "RO",
  "+7": "RU",
  "+250": "RW",
  "+290": "SH",
  "+1869": "KN",
  "+1758": "LC",
  "+508": "PM",
  "+1784": "VC",
  "+685": "WS",
  "+378": "SM",
  "+239": "ST",
  "+966": "SA",
  "+221": "SN",
  "+381": "RS",
  "+248": "SC",
  "+232": "SL",
  "+65": "SG",
  "+1721": "SX",
  "+421": "SK",
  "+386": "SI",
  "+677": "SB",
  "+252": "SO",
  "+27": "ZA",
  "+211": "SS",
  "+34": "ES",
  "+94": "LK",
  "+249": "SD",
  "+597": "SR",
  "+46": "SE",
  "+41": "CH",
  "+963": "SY",
  "+886": "TW",
  "+992": "TJ",
  "+255": "TZ",
  "+66": "TH",
  "+670": "TL",
  "+228": "TG",
  "+690": "TK",
  "+676": "TO",
  "+1868": "TT",
  "+216": "TN",
  "+90": "TR",
  "+993": "TM",
  "+1649": "TC",
  "+688": "TV",
  "+256": "UG",
  "+380": "UA",
  "+971": "AE",
  "+598": "UY",
  "+998": "UZ",
  "+678": "VU",
  "+58": "VE",
  "+84": "VN",
  "+1284": "VG",
  "+1340": "VI",
  "+681": "WF",
  "+967": "YE",
  "+260": "ZM",
  "+263": "ZW",
};

export const isoToDialCode = {
  AF: "+93",
  AX: "+358",
  AL: "+355",
  DZ: "+213",
  AS: "+1684",
  AD: "+376",
  AO: "+244",
  AI: "+1264",
  AQ: "+672",
  AG: "+1268",
  AR: "+54",
  AM: "+374",
  AW: "+297",
  AC: "+247",
  AU: "+61",
  AT: "+43",
  AZ: "+994",
  BS: "+1242",
  BH: "+973",
  BD: "+880",
  BB: "+1246",
  BY: "+375",
  BE: "+32",
  BZ: "+501",
  BJ: "+229",
  BM: "+1441",
  BT: "+975",
  BO: "+591",
  BA: "+387",
  BW: "+267",
  BR: "+55",
  IO: "+246",
  BN: "+673",
  BG: "+359",
  BF: "+226",
  BI: "+257",
  KH: "+855",
  CM: "+237",
  CA: "+1",
  CV: "+238",
  KY: "+1345",
  CF: "+236",
  TD: "+235",
  CL: "+56",
  CN: "+86",
  CX: "+61",
  CC: "+61",
  CO: "+57",
  KM: "+269",
  CG: "+242",
  CK: "+682",
  CR: "+506",
  HR: "+385",
  CU: "+53",
  CY: "+357",
  CZ: "+420",
  CD: "+243",
  DK: "+45",
  DJ: "+253",
  DM: "+1767",
  DO: "+1849",
  EC: "+593",
  EG: "+20",
  SV: "+503",
  GQ: "+240",
  ER: "+291",
  EE: "+372",
  SZ: "+268",
  ET: "+251",
  FK: "+500",
  FO: "+298",
  FJ: "+679",
  FI: "+358",
  FR: "+33",
  GF: "+594",
  PF: "+689",
  GA: "+241",
  GM: "+220",
  GE: "+995",
  DE: "+49",
  GH: "+233",
  GI: "+350",
  GR: "+30",
  GL: "+299",
  GD: "+1473",
  GP: "+590",
  GU: "+1671",
  GT: "+502",
  GG: "+44",
  GN: "+224",
  GW: "+245",
  GY: "+592",
  HT: "+509",
  VA: "+379",
  HN: "+504",
  HK: "+852",
  HU: "+36",
  IS: "+354",
  IN: "+91",
  ID: "+62",
  IR: "+98",
  IQ: "+964",
  IE: "+353",
  IM: "+44",
  IL: "+972",
  IT: "+39",
  CI: "+225",
  JM: "+1876",
  JP: "+81",
  JE: "+44",
  JO: "+962",
  KZ: "+77",
  KE: "+254",
  KI: "+686",
  KP: "+850",
  KR: "+82",
  XK: "+383",
  KW: "+965",
  KG: "+996",
  LA: "+856",
  LV: "+371",
  LB: "+961",
  LS: "+266",
  LR: "+231",
  LY: "+218",
  LI: "+423",
  LT: "+370",
  LU: "+352",
  MO: "+853",
  MG: "+261",
  MW: "+265",
  MY: "+60",
  MV: "+960",
  ML: "+223",
  MT: "+356",
  MH: "+692",
  MQ: "+596",
  MR: "+222",
  MU: "+230",
  YT: "+262",
  MX: "+52",
  FM: "+691",
  MD: "+373",
  MC: "+377",
  MN: "+976",
  ME: "+382",
  MS: "+1664",
  MA: "+212",
  MZ: "+258",
  MM: "+95",
  NA: "+264",
  NR: "+674",
  NP: "+977",
  NL: "+31",
  AN: "+599",
  NC: "+687",
  NZ: "+64",
  NI: "+505",
  NE: "+227",
  NG: "+234",
  NU: "+683",
  NF: "+672",
  MK: "+389",
  MP: "+1670",
  NO: "+47",
  OM: "+968",
  PK: "+92",
  PW: "+680",
  PS: "+970",
  PA: "+507",
  PG: "+675",
  PY: "+595",
  PE: "+51",
  PH: "+63",
  PN: "+872",
  PL: "+48",
  PT: "+351",
  PR: "+1939",
  QA: "+974",
  RE: "+262",
  RO: "+40",
  RU: "+7",
  RW: "+250",
  BL: "+590",
  SH: "+290",
  KN: "+1869",
  LC: "+1758",
  MF: "+590",
  PM: "+508",
  VC: "+1784",
  WS: "+685",
  SM: "+378",
  ST: "+239",
  SA: "+966",
  SN: "+221",
  RS: "+381",
  SC: "+248",
  SL: "+232",
  SG: "+65",
  SX: "+1721",
  SK: "+421",
  SI: "+386",
  SB: "+677",
  SO: "+252",
  ZA: "+27",
  GS: "+500",
  SS: "+211",
  ES: "+34",
  LK: "+94",
  SD: "+249",
  SR: "+597",
  SJ: "+47",
  SE: "+46",
  CH: "+41",
  SY: "+963",
  TW: "+886",
  TJ: "+992",
  TZ: "+255",
  TH: "+66",
  TL: "+670",
  TG: "+228",
  TK: "+690",
  TO: "+676",
  TT: "+1868",
  TN: "+216",
  TR: "+90",
  TM: "+993",
  TC: "+1649",
  TV: "+688",
  UG: "+256",
  UA: "+380",
  AE: "+971",
  GB: "+44",
  US: "+1",
  UY: "+598",
  UZ: "+998",
  VU: "+678",
  VE: "+58",
  VN: "+84",
  VG: "+1284",
  VI: "+1340",
  WF: "+681",
  YE: "+967",
  ZM: "+260",
  ZW: "+263",
};

export default dialCodes;
