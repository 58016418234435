import React from "react";
import { Box, Typography, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  welcomeTextRightContainer: {
    marginBottom: "5px",
    width: "100%",
    textAlign: "left",
  },
});

const WelcomeText = ({ classes }) => (
  <Box alignItems="left" className={classes.welcomeTextRightContainer}>
    <Box>
      <Typography style={{ color: "#4A4A4A" }} variant="h5" gutterBottom>
        WELCOME BACK
      </Typography>
      <Typography style={{ fontSize: "24px", color: "#4A4A4A", marginBottom:"10%"}} variant="h5" gutterBottom>
        Log in to AiSensy Affiliate Account
      </Typography>
    </Box>
  </Box>
);

export default withStyles(styles)(WelcomeText);
