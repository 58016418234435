import React from "react";
import {
  PersonOutlined,
  DashboardOutlined,
  Business,
  AccountTree,
  FlashOn,
  AddBox,
  GroupOutlined,
  GroupWork,
  ListAlt,
  Code,
  SettingsOutlined,
  CropSquareRounded as CurrencyRupeeIcon,
  Build,
  SecurityOutlined as SecurityIcon,
  AddBoxOutlined,
  TuneOutlined,
} from "@material-ui/icons";
import LinkIcon from "@material-ui/icons/Link";

//CurrencyRupeeIcon
import AgentProfilePage from "../../../pages/AgentProfile/AgentProfilePage";
import DashboardPage from "../../../pages/Dashboard/DashboardPage";
import OrdersPage from "../../../pages/Orders/OrdersPage";
import AssistantsRoute from "../../../pages/Assistants/AssistantsRoute";
import TemplateRoute from "../../../pages/TemplateMsg/TemplateRoute";
import ConfigPage from "../../../pages/Config/ConfigPage";
import ClientRoute from "../../../pages/Clients/ClientRoute";
import BillingRoute from "../../../pages/BillingProfile/BillingRoute";
import PreviewPage from "../../../pages/Preview/PreviewPage";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BlurLinearSharpIcon from "@material-ui/icons/BlurLinearSharp";
import BusinessCenterSharpIcon from "@material-ui/icons/BusinessCenterSharp";
import BorderAllSharpIcon from "@material-ui/icons/BorderAllSharp";
import PlanDetails from "../../../pages/PlanDetails/PlanDetails";
import PlanFamilyPage from "../../../pages/Manage/PlanFamilyPage";
import ManageLayout from "../ManageLayout";
import AgentsPage from "../../../pages/Manage/AgentsPage";
import ProfilePage from "../../../pages/Manage/ProfilePage";
import SSOPage from "../../../pages/Manage/SSOPage";
import addonsPage from "../../../pages/Manage/addonsPage";
import DefaultsPage from "../../../pages/Manage/DefaultsPage";
import LinkRoute from "../../../pages/Link/Link";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ContactlessIcon from "@material-ui/icons/Contactless";
import PaymentIcon from "@material-ui/icons/Payment";
import BlurOnIcon from "@material-ui/icons/BlurOn";

const viewModeRoutes = [
  {
    name: "Dashboard",
    to: "/",
    component: DashboardPage,
    icon: <BorderAllSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 1,
    exact: true,
  },
  // {
  //   name: "Link",
  //   to: "/links",
  //   component: ClientRoute,
  //   icon: <LinkIcon style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  // },
  {
    name: "Projects",
    to: "/assistants",
    component: AssistantsRoute,
    icon: <BlurLinearSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
];

const topRoutes = [
  {
    name: "Dashboard",
    to: "/",
    component: DashboardPage,
    icon: <DashboardIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 1,
    exact: true,
  },
  // {
  //   name: "Links",
  //   to: "/links",
  //   component: ClientRoute,
  //   icon: <LinkIcon style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  // },
  {
    name: "Customers",
    to: "/customers",
    component: AssistantsRoute,
    icon: <BlurOnIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
  // {
  //   name: "Plan Details",
  //   to: "/plan-details",
  //   component: PlanDetails,
  //   icon: <ListAlt style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  //   excludedTypes: ["DIRECT"],
  // },
  {
    name: "Payouts",
    to: "/payouts",
    component: BillingRoute,
    icon: <ContactlessIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 1,
  },
  // {
  //   to: "/preview",
  //   component: PreviewPage,
  //   icon: <Code style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  // },
  // {
  //   name: "Manage",
  //   to: "/manage",
  //   component: ManageLayout,
  //   icon: <SettingsOutlined style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  //   excludedTypes: ["DIRECT"],
  // },
];

const renderManageRoute = [
  {
    name: "Configure Plan",
    to: "/config",
    component: PlanFamilyPage,
    icon: <Build style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
    excludedTypes: ["DIRECT"],
  },
  // {
  //   name: "Onboarding",
  //   to: "/onboarding",
  //   component: OnboardingPage,
  //   icon: <AddBox style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  //   excludedTypes: ["DIRECT"],
  // },
  // {
  //   name: "Agents",
  //   to: "/agents",
  //   component: AgentsPage,
  //   icon: <GroupOutlined style={{ fontSize: "20px" }} />,
  //   power: 1,
  //   manage: 1,
  //   integrations: 0,
  // },
  // {
  //   name: "Single Sign On",
  //   to: "/sso",
  //   component: SSOPage,
  //   icon: <SecurityIcon style={{ fontSize: "20px" }} />,
  //   power: 1,
  //   manage: 1,
  //   integrations: 0,
  // },
  // {
  //   name: "Profile",
  //   to: "/profiles",
  //   component: ProfilePage,
  //   icon: <GroupOutlined style={{ fontSize: "20px" }} />,
  //   power: 1,
  //   manage: 1,
  //   integrations: 0,
  // },
  // {
  //   name: "Manage Addons",
  //   to: "/manage-addons",
  //   component: addonsPage,
  //   icon: <AddBoxOutlined style={{ fontSize: "20px" }} />,
  //   power: 1,
  //   manage: 1,
  //   integrations: 0,
  // },
  {
    name: "Defaults",
    to: "/defaults",
    component: DefaultsPage,
    icon: <TuneOutlined style={{ fontSize: "20px" }} />,
    power: 1,
    manage: 1,
    integrations: 0,
  },
];

const bottomRoutes = [
  {
    name: "Profile",
    to: "/agentProfile",
    component: AgentProfilePage,
    icon: <PersonOutlined />,
  },
];

export { viewModeRoutes, topRoutes, bottomRoutes, renderManageRoute };
