import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Checkbox,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import clsx from "clsx";
import AssistantsDetails from "./AssistantsDetails";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Skeleton } from "@material-ui/lab";

const leads = [
  {
    name: "Name",
    size: 2,
    key: "agentName",
  },
  {
    name: "Project Name",
    size: 2,
    key: "assistantName",
  },
  {
    name: "Phone Number",
    size: 2,
    key: "agentPhone",
  },
  {
    name: "Company Name",
    size: 2,
    key: "agentCompanyName",
  },
  {
    name: "Industry",
    size: 2,
    key: "agentIndustry",
  },
  {
    name: "Created At",
    size: 2,
    key: "createdAt",
  },
];

const wabaLive = [
  {
    name: "Name",
    size: 2,
    key: "agentName",
  },
  {
    name: "Project Name",
    size: 2,
    key: "assistantName",
  },
  {
    name: "Phone Number",
    size: 2,
    key: "agentPhone",
  },
  {
    name: "WABA Number",
    size: 2,
    key: "whatsappNumber",
  },
  {
    name: "Company Name",
    size: 2,
    key: "agentCompanyName",
  },
  {
    name: "Industry",
    size: 2,
    key: "agentIndustry",
  },
  {
    name: "Created At",
    size: 2,
    key: "createdAt",
  },
];

const conversions = [
  {
    name: "Project Name",
    key: "assistantName",
    size: 2,
  },
  {
    name: "Plan Purchased",
    key: "planPurchased",
    size: 2,
  },
  {
    name: "Currency",
    key: "currency",
    size: 2,
  },
  {
    name: "Amount",
    key: "amountPaid",
    size: 2,
  },
  {
    name: "Commision Earned",
    key: "commision",
    size: 2,
  },
  {
    name: "Commision Percentage",
    key: "commisionPercent",
    size: 2,
  },
  {
    name: "Purchase Date",
    key: "createdAt",
    size: 2,
  },
];

class AssistantsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedTemplate: null,
    };
  }

  listScrolled = (event) => {
    if (!this.offsetPosition) {
      this.offsetPosition = this.rootEle.current.offsetTop;
    }
    const container = event.currentTarget;
    const currentPos = container.scrollTop;
    this.setState({
      sticky: currentPos > this.offsetPosition,
    });
  };
  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  openTemplate = (e) => {
    const templates = [...this.props.templates];
    const foundIndex = templates.findIndex((x) => x._id == e._id);
    this.props.history.push("/assistants/" + templates[foundIndex]._id);
    // this.setState({ openDialog: true, selectedTemplate: foundIndex });
  };
  render() {
    const { classes, templates, tab } = this.props;

    const fields =
      tab === "Signups" ? leads : tab === "WABA Live" ? wabaLive : conversions;

    return (
      <>
        <div className={classes.root}>
          <Grid container>
            <Grid container item xs={12} md={12} lg={12}>
              <Grid container>
                <Box
                  className={classes.tableWrapper}
                  mb={{ xs: 2, md: 4 }}
                  mx={{ xs: 2, md: 0 }}
                >
                  <Grid item container className={classes.tableContainer}>
                    <Grid
                      item
                      xs={12}
                      container
                      className={classes.tableHeader}
                    >
                      {fields.map((e) => (
                        <Grid
                          item
                          xs={e.size}
                          key={e.key}
                          style={{
                            width: "20%",
                          }}
                        >
                          <Box py={2} pl={2}>
                            <Typography variant="h5" color="primary">
                              {e.name}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    {templates.map((e) => (
                      <Grid
                        item
                        xs={12}
                        container
                        onScroll={this.listScrolled}
                        className={
                          !!e.partnerArchived
                            ? classes.tableItemNoHover
                            : classes.tableItem
                        }
                      >
                        {fields.map((field) => (
                          <Grid
                            item
                            xs={field.size}
                            style={{
                              width: "20%",
                              overflow: "hidden",
                            }}
                            // onClick={
                            //   !!e.partnerArchived
                            //     ? null
                            //     : () => this.openTemplate(e)
                            // }
                          >
                            <Cell
                              classes={classes}
                              item={e}
                              field={
                                this.props.isLoading ? "Loading" : field.key
                              }
                              allBusiness={this.props.allBusiness}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Dialog
          open={this.state.openDialog}
          onClose={this.toggleDialog}
          aria-labelledby="form-dialog-title"
          fullScreen
        >
          {/* Assistants Details */}
          <AssistantsDetails
            templateIndex={this.state.selectedTemplate}
            templates={this.props.templates}
            toggleDialog={this.toggleDialog}
            setTemplate={this.props.setTemplate}
          />
        </Dialog>
      </>
    );
  }
}

function Cell({ classes, item, field, allBusiness, openCampaign, ...props }) {
  switch (field) {
    case "commision":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {item.currency === "USD" ? "$" : "₹"}
              {(item[field] ? item[field] / 100000 : 0).toFixed(2)}
            </Typography>
          </Box>
        </Grid>
      );
      break;

    case "amountPaid":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {item.currency === "USD" ? "$" : "₹"}
              {(item[field] ? item[field] / 100000 : 0).toFixed(2)}
            </Typography>
          </Box>
        </Grid>
      );
      break;

    case "commisionPercent":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {item[field] ? item[field] / 100000 : 0}%
            </Typography>
          </Box>
        </Grid>
      );

    case "displayNameVerified":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {item[field] ? "Verified" : "Not Verified"}
            </Typography>
          </Box>
        </Grid>
      );

    case "assistantName":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field]}</Typography>
          </Box>
        </Grid>
      );
      break;
    case "createdAt":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {getFormattedDate(item[field])}
            </Typography>
          </Box>
        </Grid>
      );

      break;

    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            {Array.from(Array(20).keys()).map((i) => {
              return (
                <div key={i} className={classes.tableItem1}>
                  <Skeleton className={classes.skeleton} />
                </div>
              );
            })}
          </Box>
        </Grid>
      );
      break;

    default:
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field]}</Typography>
          </Box>
        </Grid>
      );
  }
}

export function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // marginBottom: "5em",
    // paddingBottom: "80px"
  },
  tableWrapper: {
    overflowX: "auto",
    width: "100%",
  },

  tableContainer: {
    marginTop: "1.5em",
    minWidth: 1800,
  },

  tableItem: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
    // cursor: "pointer",
    // "&:hover": {
    //   background: "#efefef",
    // },
  },
  tableItemNoHover: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
  },
  tableItem1: {
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
  },

  tableHeader: {
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
    flexWrap: "nowrap",
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "rgb(70 20 134 / 15%)",
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  column: {
    // width: "4400px",
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
});

// export default withStyles(styles)(withRouter(AssistantsTable));

const connectAssistantsTable = connect((state) => ({
  allBusiness: state.business.allBusiness,
  isLoading: state.assistants.isLoading,
}))(AssistantsTable);

export default withStyles(styles)(withRouter(connectAssistantsTable));
