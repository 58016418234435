import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  withStyles,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Tabs,
  Tab,
  DialogContent,
  Dialog,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Snackbar,
  CircularProgress,
  Popper,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import {
  NavigateNext,
  NavigateBefore,
  Clear,
  FilterList,
  Add,
  Visibility,
  VisibilityOff,
  InfoOutlined,
  CheckCircleOutlined,
  GetApp,
} from "@material-ui/icons";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react-dates-custom.css";

import AssistantsTable, { fields, getFormattedDate } from "./AssistantsTable";
import { TablePagination } from "@material-ui/core";
// import { assistants } from "../../config/assistants";
import { URL, partnerURL } from "../../config/config";
import axios from "axios";
import {
  fetchAssistants,
  loadNextAssistants,
  loadPrevAssistants,
  changeRowsPerPage,
  fetchAssistantsCounts,
} from "../../store/assistantState";
import { fetchBusiness } from "../../store/businessState";
import { Alert, Autocomplete, Skeleton } from "@material-ui/lab";
import { timeZones } from "../../config/timezone";
import dialCodes, {
  countryMap,
  isoMap,
  isoToDialCode,
} from "../../config/dialCodes";
import Header from "../../commons/Header/Header";
import CreateProjectDialog from "../../commons/Dialog/CreateProjectDialog";
import plansHelper from "../../helpers/plansHelper";
import withMediaQuery from "../../helpers/mediaQueryHelper";
import { minBusinessFieldsPartners } from "../Clients/BusinessDetailsForm";
// import { loadNext, loadClient, unfetchclients, SortClient, checkDateInput, setFocus, setDate, clearDate, loadSorts, createnewClient, loadSearch } from "../clients/Clientstore";

class AssistantsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "",
      alertMsg: "",
      rowsPerPage: 15,
      totalCount: null,
      skip: 0,
      timeStamp: new Date(),
      search: "",
      searchDone: false,
      templates: [],
      backupTemplates: [],
      backupSearchTemplates: [],
      err: null,
      isLoading: false,
      page: 0,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focus: null,
        },
        planActivatedOn: {
          startDate: null,
          endDate: null,
          focus: null,
        },
      },
      tab: "Signups",
      openDialog: false,
      assistantFilter: {
        filter: "Signups",
        sort: "dec",
      },
      allCount: 0,
      wabaCount: 0,
      countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
      liveCount: 0,
      draftCount: 0,
      trialPlanCount: 0,
      monthlyPlanCount: 0,
      yearlyPlanCount: 0,
      deletedCount: 0,
      stoppedCount: 0,
      openProjectDialog: false,
      selectBusiness: "",
      newProjectName: "",
      projectSubmitLoader: false,
      clientDialog: false,
      company: "",
      companySize: "",
      industry: "",
      display_name: "",
      contact: "",
      buttonDisable: false,
      timezone: "Asia/Calcutta GMT+05:30",
      currency: this.props.partner?.currency || "INR",
    };
  }
  componentDidMount() {
    this.props.fetchAssistantsCounts();
    this.props.fetchAssistants(this.state.assistantFilter);
  }

  toggleDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openDialog: !this.state.openDialog });
  };

  handleChangePage = (event, newPage) => {
    const { page } = this.props;
    const step = newPage - page;
    if (step === -1) {
      this.props.loadPrevAssistants();
    } else if (step === 1) {
      this.props.loadNextAssistants(this.state.assistantFilter);
    }
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    this.props.changeRowsPerPage(rowsPerPage);
    this.onTabChange();
  };

  setTemplate = (template) => {
    const templates = [...this.state.templates];
    const foundIndex = templates.findIndex((x) => x._id == template._id);
    // console.log({foundIndex})
    // if(foundIndex > -1) {
    templates[foundIndex] = template;
    this.setState({ templates });
    // } else {
    // }
    // templates.forEach
  };

  handleBusinessInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleEnter = (e) => {
    // const { backupSearchTemplates, search } = this.state;
    if (e.key === "Enter") {
      const { assistantFilter } = this.state;
      const newObj = { ...assistantFilter };
      if (e.target.value) {
        newObj.assistantName = e.target.value;
      } else {
        delete newObj.assistantName;
      }
      this.setState(
        { assistantFilter: newObj, searchDone: true },
        this.onTabChange
      );
    }
  };
  handleSearch = (e) => {
    const { assistantFilter } = this.state;
    const newObj = { ...assistantFilter, assistantName: e.target.value };
    this.setState({
      assistantFilter: newObj,
      search: e.target.value,
    });
  };
  handleClear = () => {
    const { assistantFilter } = this.state;
    delete assistantFilter.assistantName;
    this.setState(
      {
        search: "",
        searchDone: false,
        assistantFilter,
      },
      this.onTabChange
    );
  };

  // methods for date filter
  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };
  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };
  applyFilter = () => {
    const { applied, assistantFilter } = this.state;
    const filterObj = { ...assistantFilter };
    if (applied.createdAt.startDate && applied.createdAt.endDate) {
      filterObj.fromDate = applied.createdAt.startDate._d;
      filterObj.toDate = applied.createdAt.endDate._d;
    } else {
      delete filterObj.fromDate;
      delete filterObj.toDate;
    }

    if (applied.planActivatedOn.startDate && applied.planActivatedOn.endDate) {
      filterObj.fromPlanActivatedDate = applied.planActivatedOn.startDate._d;
      filterObj.toPlanActivatedDate = applied.planActivatedOn.endDate._d;
    } else {
      delete filterObj.fromPlanActivatedDate;
      delete filterObj.toPlanActivatedDate;
    }
    this.setState(
      {
        assistantFilter: filterObj,
        openDialog: false,
      },
      () => {
        this.onTabChange();
      }
    );
  };

  onTabChange = () => {
    this.props.fetchAssistants(this.state.assistantFilter);
  };

  projectDialog = (event) => {
    this.setState({ openProjectDialog: !this.state.openProjectDialog });
  };

  toggleclientDialog = () => {
    this.setState({ clientDialog: !this.state.clientDialog });
  };

  buttondisbale = () => {
    this.setState({ buttonDisable: !this.state.buttonDisable });
  };

  createclient = async () => {
    try {
      const partnerId = this.props.user.id;
      const {
        email,
        display_name,
        company,
        contact,
        currency,
        timezone,
        password,
      } = this.state;
      const queryObj = {
        email,
        password,
        display_name,
        company,
        contact,
        timezone,
        currency,
      };

      let response = await axios.post(
        partnerURL + `/partner/${partnerId}/business/`,
        queryObj
      );
      // this.props.createnewClient(response.data)
      // this.props.fetchBusiness();
      this.setState({
        clientDialog: !this.state.clientDialog,
        openProjectDialog: true,
      });
      this.setState({
        status: "success",
        statusMessage: "Business Created Succesfully",
      });
      this.setState({
        email: null,
        display_name: null,
        company: null,
        contact: null,
        currency: this.props.partner?.currency || "INR",
        timezone: "Asia/Calcutta GMT+05:30",
        countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
        industry: null,
        companySize: null,
        password: null,
      });
    } catch (error) {
      this.setState({
        buttonDisable: false,
      });
      const errorResponse = error.response;
      console.error(error);
      const errorMessage = errorResponse?.data?.message;
      this.setState({
        status: "error",
        statusMessage: errorMessage,
      });
    }
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  selectBusiness = (e, value, reason) => {
    if (reason === "select-option") {
      const assistantValue = Object.keys(this.props.allBusiness).find(
        (key) => this.props.allBusiness[key].name === value.split(" - ")[1]
      );
      this.setState({ selectBusiness: assistantValue });
    }
  };

  render() {
    const {
      classes,
      partner,
      partnerPlanFamily,
      partnerWccFamily,
      mediaQuery,
      partnerId,
      user,
    } = this.props;
    const { backupTemplates, tab, assistantFilter, applied } = this.state;
    const { assistants, page, rowsPerPage, totalAssistants } = this.props;

    const affiliateTabs = [
      {
        display: "Signups",
        state: "draftCount",
        theme: "#00b4bf59",
        list: backupTemplates.filter((i) => !i.isWhatsappVerified),
      },
      {
        display: "WABA Live",
        state: "wabaCount",
        theme: "#f2c14e3d",
        list: backupTemplates,
      },
      {
        display: "Conversions",
        state: "liveCount",
        theme: "#08cf6533",
        list: backupTemplates.filter((i) => !!i.isWhatsappVerified),
      },
    ];

    const tabs = affiliateTabs;

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const isPasswordValid = passwordRegex.test(this.state.password);

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item xs={12}>
            <Box className={classes.tabContainer}>
              <Header
                businessTitle={"Customers"}
                buttonPrefixIcon={<Add />}
                // buttonTitle={user.isReferral ? "" : "Create New Project"}
                onButtonClick={this.projectDialog}
                showSearch={true}
                searchPlaceholder={"Search project name"}
                handleSearch={this.handleSearch}
                handleEnter={this.handleEnter}
                searchDone={this.state.searchDone}
                handleClear={this.handleClear}
                filter={this.state.search}
                toggleFilterDialog={this.toggleDialog}
                filterColor={
                  (!!applied.createdAt.startDate &&
                    !!applied.createdAt.endDate) ||
                  (!!applied.planActivatedOn.endDate &&
                    !!applied.planActivatedOn.startDate)
                }
              />
              <Box px={{ xs: 2, md: 3 }}>
                <Tabs
                  value={tab}
                  onChange={(event, newValue) => {
                    if (this.state.tab === newValue) return;
                    const tab = tabs.find((i) => i.display === newValue);
                    this.setState(
                      {
                        tab: newValue,
                        templates: tab.list,
                        totalCount: tab.list.length,
                        assistantFilter: {
                          ...this.state.assistantFilter,
                          filter: newValue,
                        },
                      },
                      this.onTabChange
                    );
                  }}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  TabIndicatorProps={{
                    className: classes.tabIndicator,
                  }}
                >
                  {tabs.map((i) => (
                    <Tab
                      label={
                        <>
                          <Grid container justifyContent="space-between">
                            <Grid item xs={2}>
                              <Box
                                fontSize="16px"
                                fontWeight="bold"
                                textAlign="left"
                                width="100%"
                                style={{ marginTop: "5px" }}
                              >
                                {this.props[i.state]}
                              </Box>
                            </Grid>
                          </Grid>
                          <Box fontSize={"14px"} width="100%" textAlign="left">
                            {`${i.display}`}
                          </Box>
                        </>
                      }
                      value={i.display}
                      style={{
                        textTransform: "none",
                        alignItems: "end",
                        minWidth: 190,
                        // marginLeft: 16,
                        paddingBottom: 8,
                        background: `linear-gradient(180deg, white, ${
                          this.state.tab === i.display ? i.theme : "white"
                        })`,
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box mt={15} />
        {/* Layout */}
        <Grid container justify="center">
          <Grid item xs={12} md={11} lg={10}>
            {/* First section */}
            {/* Scrollable (x & y) table container */}
            <div className={classes.tableContainer}>
              <AssistantsTable
                tab={tab}
                templates={Object.values(assistants).slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )}
                isLoading={this.state.isLoading}
                // setTemplate={this.setTemplate}
              />
            </div>
          </Grid>
        </Grid>
        {/* Table footer fixed at bottom */}
        <div className={classes.fixedBottomContainer}>
          <TablePagination
            component="div"
            count={totalAssistants}
            page={this.props.page}
            onChangePage={this.handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
        {/* Filter dialog */}
        <Dialog
          open={this.state.openDialog}
          maxWidth={"xs"}
          onClose={this.toggleDialog}
          aria-labelledby="form-dialog-title"
          className={classes.dialogContainer}
          fullScreen={mediaQuery}
          back
        >
          <DialogContent style={{ height: 500 }}>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ paddingRight: 0, paddingTop: 0 }}
                xs={12}
              >
                <Typography variant="h3">Filter</Typography>
                <IconButton onClick={this.toggleDialog}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <DateFilter
                  filterKey="createdAt"
                  filterName="Created At"
                  classes={classes}
                  applied={this.state.applied}
                  setDate={this.setDate}
                  setFocus={this.setFocus}
                  checkDateInput={this.checkDateInput}
                  clearDate={this.clearDate}
                />
              </Grid>
              <Grid
                container
                style={{ alignItems: "center", paddingLeft: "10px" }}
              ></Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              style={{ paddingRight: 24, paddingBottom: mediaQuery ? 64 : 16 }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={this.applyFilter}
                style={{ margin: 0 }}
              >
                Apply
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>

        {/* project dialog */}
        {this.state.openProjectDialog && (
          <CreateProjectDialog
            showDialog={this.state.openProjectDialog}
            closeDialog={this.projectDialog}
            allBusiness={this.props.allBusiness}
            selectBusiness={this.selectBusiness}
            setProjectName={(e) =>
              this.setState({ newProjectName: e.target.value })
            }
            createBusiness={() =>
              this.setState({
                openProjectDialog: false,
                clientDialog: true,
              })
            }
            onSubmit={this.projectSubmit}
            disableSubmit={this.state.projectSubmitLoader}
            planFamilies={partnerPlanFamily}
            defaultPlanFamily={partner?.defaultPlanFamily}
            defaultPlanName={partner?.defaultPlanName}
            wccPlans={partnerWccFamily}
            defaultWccPlan={partner?.defaultWccPlan}
            type={partner?.type}
            partner={partner}
          />
        )}

        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

// Navigattion icons used in date fliters
function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box mt={1} mb={1} className={classes.filterTypeContainer}>
      <Grid container alignItems="center">
        {A.startDate && A.endDate ? (
          <Grid item xs={12} md={1}>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} md={3}>
          <Typography variant="body1" style={{ marginRight: 8, minWidth: 100 }}>
            {filterName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  tableContainer: {
    top: "60px",
    height: "calc(100vh - 260px)",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("xs")]: {
      // position top + bottombar height + bottom padding + navbar
      marginTop: 48,
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  textField: {
    width: "100%",
    height: 42,
  },
  sortFormControl: {
    width: 120,
    marginRight: 8,
    height: 32,
    borderRadius: "4px",
    border: "1px solid lightgrey",
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  tabContainer: {
    background: "white",
    boxShadow: "0 0 12px rgb(171 170 170)",
    // padding: "24px",
  },
  tabIndicator: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
  },
  textFieldRootAutocomplete: {
    width: "100%",
    marginTop: "4px",
    marginBottom: "20px",
    height: 32,
    background: "white",
    border: "2px solid grey",
    "& input": {
      padding: "0px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  dialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "600px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
  projectdialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },

  inputRoot: {
    '&[class*="MuiOutlinedInput-root"].MuiAutocomplete-input': {
      padding: 12,
    },
    '&&[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
  popper: {
    width: "fit-content",
    zIndex: 10000,
    height: "10px",
  },
  snackbar: {
    // marginTop:"20px",
    // [theme.breakpoints.down("sm")]: {
    //   marginTop:"40px",
    // },
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)", marginLeft: "80px" },
    background: "white",
    border: "2px solid grey",
  },
  codeTextFieldRoot: {
    width: "90px",
    padding: "0 0 0 14px",
    position: "absolute",
    zIndex: 1,
    margin: "10px 0 0 2px",
    background: "white",
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "12px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
});

const connectAssistants = connect(
  (state) => ({
    user: state.login.user,
    assistants: state.assistants.assistants,
    page: state.assistants.page,
    isAssistantsLoading: state.assistants.isLoading,
    assistantError: state.assistants.error,
    rowsPerPage: state.assistants.rowsPerPage,
    totalAssistants: state.assistants.total,
    allCount: state.assistants.allCount,
    liveCount: state.assistants.liveCount,
    wabaCount: state.assistants.wabaCount,
    draftCount: state.assistants.draftCount,
    trialPlanCount: state.assistants.trialPlanCount,
    monthlyPlanCount: state.assistants.monthlyPlanCount,
    yearlyPlanCount: state.assistants.yearlyPlanCount,
    deletedCount: state.assistants.deletedCount,
    stoppedCount: state.assistants.stoppedCount,
    allBusiness: state.business.allBusiness,
    partnerId: state.partner.partner._id,
    partner: state.partner.partner,
    partnerSecretHash: state.partner.partner.passwordHash,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    partnerWccFamily: state.wccPlans.allWccPlans,
  }),
  {
    fetchAssistants,
    loadNextAssistants,
    loadPrevAssistants,
    changeRowsPerPage,
    fetchAssistantsCounts,
    fetchBusiness,
  }
)(AssistantsPage);

export default withStyles(styles)(
  withMediaQuery("(max-width:600px)")(withRouter(connectAssistants))
);
