import { Box, Grid, Link, Typography, withStyles } from "@material-ui/core";
import { ArrowForwardRounded } from "@material-ui/icons";
import React, { Component } from "react";

class TrainingSessionCardWithHelpCenter extends Component {
  render() {
    const {
      gridSize,
      link,
      themeBackground,
      title,
      subTitle,
      Description,
      buttonTitle,
      iconLink,
      classes,
      maxImageSize,
      height,
      bottomPadding,
    } = this.props;

    return (
      <Grid item xs={12} md={gridSize}>
        <Box
          mt={1}
          pl={4}
          pr={1}
          pt={2}
          zIndex={2}
          pb={bottomPadding}
          position="relative"
          borderRadius="16px"
          style={{ background: themeBackground }}
        >
          <Grid container className={height ? classes.cardTop : ""}>
            <Grid item xs={8}>
              <Typography variant="h4" className={classes.cardHeading}>
                {title}
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                style={{
                  marginBottom: "12px",
                  fontSize: 13,
                  letterSpacing: "0.5px",
                  fontFamily: "Roboto",
                  lineHeight: 1.4,
                }}
              >
                {subTitle}
              </Typography>
              <Description />
            </Grid>
            <Grid item xs={4} className={classes.iconImageContainer}>
              <img
                src={iconLink}
                alt="speaker"
                style={{ maxWidth: maxImageSize }}
                className={`${classes.iconImage}`}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Wrap only the button with Link */}
        <Link href={link} target="_blank" className={`${classes.affilateCard}`}>
          <Box className={classes.cardCTA}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{ fontFamily: "Roboto" }}
            >
              {buttonTitle}
            </Typography>
            <ArrowForwardRounded className={classes.arrowForward} />
          </Box>
        </Link>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  iconImageContainer: {
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  iconImage: {
    width: "100%",
    transition: "all 0.25s ease-out",
  },
  arrowForward: {
    fontSize: 16,
    padding: "2px",
    transition: "all 0.25s",
    border: "1px solid #09A12A",
    color: "#09A12A",
    borderRadius: "20px",
  },
  affilateCard: {
    "&:hover $arrowForward": {
      fontSize: 20,
    },
    "&:hover $iconImage": {
      transform: "rotate(-3deg)",
    },
    "&:hover": {
      marginBottom: 0,
    },
    display: "flex",
    flexDirection: "column",
    transformStyle: "preserve-3d",
    marginBottom: 4,
    transition: "margin 0.25s",
  },
  cardHeading: {
    color: "black",
    marginBottom: "16px",
    fontWeight: "bold",
    fontFamily: "Roboto",
    letterSpacing: "0.5px",
  },
  cardCTA: {
    boxShadow: "0 0 11px rgb(230,230,230)",
    borderRadius: "0 0 16px 16px",
    zIndex: 1,
    display: "flex",
    padding: "16px 32px 12px",
    position: "relative",
    bottom: "8px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "white",
  },

  cardTop: {
    minHeight: "175px",

    [theme.breakpoints.down("1400")]: {
      minHeight: "215px",
    },
  },
});

export default withStyles(styles)(TrainingSessionCardWithHelpCenter);
