import { combineReducers } from "redux";

import login from "../pages/Login/loginstate";
import client from "../pages/Clients/Clientstore";
import template from "../pages/TemplateMsg/templateState";
import partner from "./partnerState";
import assistants from "./assistantState";
import billing from "../pages/BillingProfile/BillingStore";
import business from "./businessState";
import projects from "./projectState";
import planFamilies from "./planFamilyState";
import wccPlans from "./wccPlanState";
import tenant from "./tenantState";

export default combineReducers({
  login,
  client,
  template,
  partner,
  assistants,
  billing,
  business,
  projects,
  planFamilies,
  wccPlans,
  tenant,
});
