import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Snackbar,
  Button,
} from "@material-ui/core";
import Header from "../../commons/Header/Header";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { URL } from "../../config/config";
import axios from "axios";
import PopUp from "./Popup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Add, Edit as EditIcon } from "@material-ui/icons";
import WccTable from "./WccTable";
import { Alert } from "@material-ui/lab";
import { fetchPartnerDetails } from "../../store/partnerState";
import { fetchPlanFamilies, addNewPlan } from "../../store/planFamilyState";
import { fetchWccPlans, addWccPlan } from "../../store/wccPlanState";
import CreateWccPlan from "./CreateWccPlan";
import CreatePlanFamily from "./CreatePlanFamily";

const fields = [
  {
    name: "Name",
    size: 4,
    key: "name",
  },
  {
    name: "Created At",
    size: 4,
    key: "createdAt",
  },
  {
    name: "Actions",
    size: 4,
    key: "actions",
  },
];

const fields2 = [
  {
    name: "Name",
    size: 4,
    key: "name",
  },
  {
    name: "Created At",
    size: 4,
    key: "createdAt",
  },
  {
    name: "Actions",
    size: 4,
    key: "actionsWcc",
  },
];

const quickFilters = [
  "Last active - 24 hr window",
  "Created - This week",
  "Created - This month",
];

class PlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      openCreateFamilyDialog: false,
      partnerPlanFamily: [],
      tab: "",
      aisensyPlans: {},
      currency: "",
      activeId: "",
      partnerWccFamily: [],
      openWccDialog: false,
      openCreateWccDialog: false,
      user_initiated: {},
      business_initiated: {},
      wholeWccItem: {},
      aisensyWcc: {},
      partner_user_initiated: {},
      partner_business_initiated: {},
      aisensy_user_initiated: {},
      aisensy_business_initiated: {},
      aisensy_service: {},
      aisensy_authentication: {},
      aisensy_marketing: {},
      aisensy_utility: {},
      reqWccId: "",
      alertType: "",
      alertMsg: "",
      disableFields: "",
    };
  }
  listScrolled = (event) => {
    if (!this.offsetPosition) {
      this.offsetPosition = this.rootEle.current.offsetTop;
    }
    const container = event.currentTarget;
    const currentPos = container.scrollTop;
    this.setState({
      sticky: currentPos > this.offsetPosition,
    });
  };

  getAisenyPrice = (projectId) => {
    axios
      .get(URL + `/api/get-aisensy-plan-family-pricing/:projectId`)
      .then((response) => {
        const data = response.data;

        this.setState({
          aisensyPlans: data.plans.plans,
        });
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg: "can not fetch aisensy plans",
        });
      });
  };

  editPlan = (isTrue, id, isFalse) => {
    this.setState({
      openDialog: isTrue,
      activeId: id,
      disableFields: isFalse,
    });
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  editWccPlan = (
    isTrue,
    business,
    user,
    item,
    id,
    isFalse,
    authentication_initiated,
    service_initiated,
    utility_initiated,
    marketing_initiated
  ) => {
    this.setState({
      user_initiated: user,
      business_initiated: business,
      openWccDialog: isTrue,
      wholeWccItem: item,
      reqWccId: id,
      authentication_initiated: authentication_initiated,
      service_initiated: service_initiated,
      utility_initiated: utility_initiated,
      marketing_initiated: marketing_initiated,
      disableFields: isFalse,
    });
  };

  componentDidMount() {
    // this.props.fetchPartnerDetails();
    this.getAisenyPrice();

    const reqCurrency = this.props.partner.currency;
    this.setState({
      tab: reqCurrency,
      currency: reqCurrency,
    });

    axios
      .get(URL + `/api/get-aisensy-wcc-pricing/xyz`)
      .then((response) => {
        const data = response.data.aisensyWccPlan;

        this.setState({
          aisensyWcc: data,
          aisensy_user_initiated: data.user_initiated,
          aisensy_business_initiated: data.business_initiated,
          aisensy_service: data.service,
          aisensy_authentication: data.authentication,
          aisensy_marketing: data.marketing,
          aisensy_utility: data.utility,
        });
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg: "can not fetch aisensy wcc plans",
        });
      });
  }

  triggerAlert = (obj) => {
    const { alertType, alertMsg } = obj;
    this.setState({
      alertType: alertType,
      alertMsg: alertMsg,
    });
  };

  openPlanDialog = (isTrue) => {
    this.setState({
      openDialog: isTrue,
      disableFields: isTrue,
    });
  };

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  togglWccDialog = () => {
    this.setState({ openWccDialog: !this.state.openWccDialog });
  };

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue });
    this.setState({ currency: newValue });
  };

  createPlanFamily = (payload) => {
    payload.partnerId = this.props.partner._id;
    payload.planType = this.props.partner.planType;
    axios
      .post(URL + `/api/create-partner-plan-family`, payload)
      .then((response) => {
        const data = response.data;
        const { planFamily } = data;
        this.props.addNewPlan(planFamily);
        this.setState({
          openCreateFamilyDialog: false,
          alertType: "success",
          alertMsg: "Plan Family created successfully",
        });
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg: "Unable to create Plan Family. Something went wrong.",
        });
      });
  };

  createWccPlan = (wccPlan) => {
    wccPlan.partnerId = this.props.partner._id;
    axios
      .post(URL + `/api/create-partner-wcc-plan`, wccPlan)
      .then((response) => {
        const data = response.data.partnerWccPlan;
        this.props.addWccPlan(data);
        this.setState({
          openCreateWccDialog: false,
          alertType: "success",
          alertMsg: "Wcc Plan created successfully",
        });
      })
      .catch((error) => {
        this.setState({
          openCreateWccDialog: false,
          alertType: "error",
          alertMsg: "Unable to create Wcc Plan. Something went wrong.",
        });
      });
  };

  toggleCreateFamilyDialog = () => {
    this.setState({
      openCreateFamilyDialog: !this.state.openCreateFamilyDialog,
    });
  };

  toggleCreateWccDialog = () => {
    this.setState({ openCreateWccDialog: !this.state.openCreateWccDialog });
  };

  render() {
    const { classes, partnerPlanFamily, partnerWccFamily } = this.props;
    const convertPrice = 100000;

    return (
      <>
        <div className={classes.root}>
          <Header businessTitle={"Plan Details"} />

          <Grid container justify="center">
            <Grid
              item
              xs={12}
              md={11}
              lg={10}
              style={{
                marginTop: "30px",
              }}
            >
              <Box
                mb={{ xs: 2, md: 4 }}
                mx={{ xs: 2, md: 0 }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3" style={{ flex: 1 }}>
                  Subscription Plan Family
                </Typography>
                <Button
                  onClick={this.toggleCreateFamilyDialog}
                  color="primary"
                  variant="contained"
                  startIcon={<Add />}
                  style={{
                    marginRight: 8,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Create
                </Button>
              </Box>
              {/* First section */}
              {/* Scrollable (x & y) table container */}
              <div className={classes.tableContainer}>
                <Grid container>
                  <Grid container item xs={12} md={12} lg={12}>
                    <Grid container>
                      <Box
                        className={classes.tableWrapper}
                        mb={{ xs: 2, md: 4 }}
                        mx={{ xs: 2, md: 0 }}
                      >
                        <Grid
                          item
                          container
                          className={classes.billTableContainer}
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            className={classes.tableHeader}
                          >
                            {fields.map((e) => (
                              <Grid
                                item
                                xs={e.size}
                                key={e.key}
                                style={{
                                  width: "20%",
                                }}
                              >
                                <Box py={2} pl={2}>
                                  <Typography variant="h5" color="primary">
                                    {e.name}
                                  </Typography>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                          {Object.keys(partnerPlanFamily).map((e) => (
                            <Grid
                              item
                              xs={12}
                              container
                              onScroll={this.listScrolled}
                              className={classes.tableItem}
                            >
                              {fields.map((field) => {
                                return (
                                  <Grid
                                    item
                                    xs={field.size}
                                    style={{
                                      width: "20%",
                                    }}
                                  >
                                    <Cell
                                      classes={classes}
                                      item={partnerPlanFamily[e]}
                                      field={
                                        this.props.isLoading
                                          ? "Loading"
                                          : field.key
                                      }
                                      partnerPlans={partnerPlanFamily[e]}
                                      activeId={e}
                                      //   getAisenyPrice={this.getAisenyPrice}
                                      editPlan={this.editPlan}
                                      openPlanDialog={this.openPlanDialog}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid
              item
              xs={12}
              md={11}
              lg={10}
              style={{
                marginTop: "30px",
              }}
            >
              <Box
                mb={{ xs: 2, md: 4 }}
                mx={{ xs: 2, md: 0 }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3" style={{ flex: 1 }}>
                  WhatsApp Conversation Charges (WCC) Family
                </Typography>
                <Button
                  onClick={this.toggleCreateWccDialog}
                  color="primary"
                  variant="contained"
                  startIcon={<Add />}
                  style={{
                    marginRight: 8,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Create
                </Button>
              </Box>
              {/* First section */}
              {/* Scrollable (x & y) table container */}
              <div className={classes.tableContainer}>
                <Grid container>
                  <Grid container item xs={12} md={12} lg={12}>
                    <Grid container>
                      <Box
                        className={classes.tableWrapper}
                        mb={{ xs: 2, md: 4 }}
                        mx={{ xs: 2, md: 0 }}
                      >
                        <Grid
                          item
                          container
                          className={classes.billTableContainer}
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            className={classes.tableHeader}
                          >
                            {fields2.map((e) => (
                              <Grid
                                item
                                xs={e.size}
                                key={e.key}
                                style={{
                                  width: "20%",
                                }}
                              >
                                <Box py={2} pl={2}>
                                  <Typography variant="h5" color="primary">
                                    {e.name}
                                  </Typography>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                          {Object.keys(partnerWccFamily).map((e) => (
                            <Grid
                              item
                              xs={12}
                              container
                              onScroll={this.listScrolled}
                              className={classes.tableItem}
                            >
                              {fields2.map((field) => (
                                <Grid
                                  item
                                  xs={field.size}
                                  style={{
                                    width: "20%",
                                  }}
                                >
                                  <Cell
                                    classes={classes}
                                    item={partnerWccFamily[e]}
                                    field={
                                      this.props.isLoading
                                        ? "Loading"
                                        : field.key
                                    }
                                    editWccPlan={this.editWccPlan}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

        {this.state.openDialog && (
          <PopUp
            handleChange={this.handleChange}
            tab={this.props.partner.currency}
            classes={classes}
            aisensyPlans={this.state.aisensyPlans}
            partnerPlans={partnerPlanFamily[this.state.activeId].plans}
            currency={this.props.partner.currency}
            convertPrice={convertPrice}
            openDialog={this.state.openDialog}
            toggleDialog={this.toggleDialog}
            disableFields={this.state.disableFields}
            activeId={this.state.activeId}
            triggerAlert={this.triggerAlert}
          />
        )}
        {this.state.openWccDialog && (
          <WccTable
            currency={this.props.partner.currency}
            classes={classes}
            triggerAlert={this.triggerAlert}
            openDialog={this.state.openWccDialog}
            toggleDialog={this.togglWccDialog}
            aisensyWcc={this.state.aisensyWcc}
            business_initiated={this.state.business_initiated}
            user_initiated={this.state.user_initiated}
            service_initiated={this.state.service_initiated}
            authentication_initiated={this.state.authentication_initiated}
            utility_initiated={this.state.utility_initiated}
            marketing_initiated={this.state.marketing_initiated}
            wholeWccItem={this.state.wholeWccItem}
            aisensy_user_initiated={this.state.aisensy_user_initiated}
            aisensy_business_initiated={this.state.aisensy_business_initiated}
            aisensy_service={this.state.aisensy_service}
            aisensy_authentication={this.state.aisensy_authentication}
            aisensy_utility={this.state.aisensy_utility}
            aisensy_marketing={this.state.aisensy_marketing}
            reqWccId={this.state.reqWccId}
            disableFields={this.state.disableFields}
          />
        )}
        {this.state.openCreateFamilyDialog && (
          <CreatePlanFamily
            classes={classes}
            aisensyPlans={this.state.aisensyPlans}
            currency={this.props.partner.currency}
            convertPrice={convertPrice}
            openDialog={this.state.openCreateFamilyDialog}
            toggleDialog={this.toggleCreateFamilyDialog}
            disableFields={this.state.disableFields}
            createPlanFamily={this.createPlanFamily}
          />
        )}
        {this.state.openCreateWccDialog && (
          <CreateWccPlan
            aisensy_user_initiated={this.state.aisensy_user_initiated}
            aisensy_business_initiated={this.state.aisensy_business_initiated}
            currency={this.props.partner.currency}
            openDialog={this.state.openCreateWccDialog}
            createWccPlan={this.createWccPlan}
            toggleDialog={this.toggleCreateWccDialog}
            aisensy_service={this.state.aisensy_service}
            aisensy_authentication={this.state.aisensy_authentication}
            aisensy_utility={this.state.aisensy_utility}
            aisensy_marketing={this.state.aisensy_marketing}
            classes={classes}
          />
        )}
        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

function Cell({
  classes,
  item,
  field,
  allBusiness,
  openCampaign,
  editPlan,
  editWccPlan,
  openPlanDialog,
  ...props
}) {
  switch (field) {
    case "remainingCredit":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100000}</Typography>
          </Box>
        </Grid>
      );
      break;

    case "templateCredit":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {item[field] ? item[field] / 100000 : 0}
            </Typography>
          </Box>
        </Grid>
      );
      break;
    case "createdAt":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {getFormattedDate(item[field])}
            </Typography>
          </Box>
        </Grid>
      );

      break;
    case "clientId":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1" noWrap>
            {item[field] && allBusiness[item[field]]?.name}
            {/* {columnData[key]} */}
            {/* {agents.find((i) => i._id === columnData[key])?.displayName || "-"} */}
          </Typography>
        </Box>
      );
      break;
    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            {Array.from(Array(20).keys()).map((i) => {
              return (
                <div key={i} className={classes.tableItem1}>
                  <Skeleton className={classes.skeleton} />
                </div>
              );
            })}
          </Box>
        </Grid>
      );
      break;
    case "whatsappNumber":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1" noWrap>
            {item[field] ? "+" + item[field] : "-"}
          </Typography>
        </Box>
      );
      break;

    case "excessUsage":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100000}</Typography>
          </Box>
        </Grid>
      );
      break;
    case " company":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100}</Typography>
          </Box>
        </Grid>
      );
      break;

    case "actions":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <VisibilityIcon
              onClick={() => {
                return editPlan(true, item._id, true);
              }}
              color="primary"
            />
            <EditIcon
              color="primary"
              style={{ marginLeft: 16 }}
              onClick={() => editPlan(true, item._id, false)}
            />
          </Box>
        </Grid>
      );
    case "actionsWcc":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <VisibilityIcon
              onClick={() => {
                return editWccPlan(
                  true,
                  item.business_initiated,
                  item.user_initiated,
                  item,
                  item._id,
                  true,
                  item.authentication,
                  item.service,
                  item.utility,
                  item.marketing
                );
              }}
              color="primary"
            />
            <EditIcon
              color="primary"
              style={{ marginLeft: 16 }}
              onClick={() => {
                return editWccPlan(
                  true,
                  item.business_initiated,
                  item.user_initiated,
                  item,
                  item._id,
                  false,
                  item.authentication,
                  item.service,
                  item.utility,
                  item.marketing
                );
              }}
            />
          </Box>
        </Grid>
      );
    default:
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field]}</Typography>
          </Box>
        </Grid>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // marginBottom: "5em",
    // paddingBottom: "80px"
  },
  tableWrapper: {
    overflowX: "auto",
    width: "100%",
  },

  billTableContainer: {
    marginTop: "1.5em",
    width: "100%",
  },
  tableContainer: {
    top: "60px",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      // height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  tabIndicator: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    marginBottom: 6,
  },
  tableItem: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
    },
  },
  tableItem1: {
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
  },
  tabHeader: {
    textTransform: "none",
    // borderBottom: "2px solid #f0f0f0",
  },
  tableHeader: {
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
    flexWrap: "nowrap",
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "rgb(70 20 134 / 15%)",
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  overRideStyling: {
    border: "1px solid white",
    borderRadius: "8px",
  },

  column: {
    // width: "4400px",
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
});

// export default withStyles(styles)(withRouter(AssistantsTable));
const ConnectPlanDetails = connect(
  (state) => ({
    allBusiness: state.business.allBusiness,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    partnerWccFamily: state.wccPlans.allWccPlans,
    isLoading: state.assistants.isLoading,
    partner: state.partner.partner,
  }),
  {
    fetchPartnerDetails,
    fetchPlanFamilies,
    fetchWccPlans,
    addNewPlan,
    addWccPlan,
  }
)(PlanDetails);

export default withStyles(styles)(withRouter(ConnectPlanDetails));
