import React, { Component } from "react";
import {
  withStyles,
  Box,
  Grid,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  Snackbar,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Header from "../../commons/Header/Header";

class LinkRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Header businessTitle={"Affiliate Dashboard"} />
        <Box my={3} />
        {/* Layout */}
        <Grid container justify-content="center">
          <Box
            className={classes.container}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "25px",
            }}
          >
            <Typography variant="h4" color="primary">
              Referral Link :
            </Typography>
            <Typography variant="h3" color="primary">
              Link
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              // onClick={() =>
              //   navigator.clipboard.writeText(agent.referralLink)
              // }
              style={{
                maxWidth: "100px",
              }}
            >
              Copy Link
            </Button>
          </Box>
        </Grid>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
});

export default withStyles(styles)(LinkRoute);
