export const URL =
  process.env.REACT_APP_PARTNER_REST_API_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://backend.aisensy.com/partner/t1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/partner/t1"
    : "http://localhost:5099/partner/t1");

export const partnerURL =
  process.env.REACT_APP_PARTNER_APIS_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://apis.aisensy.com/partner-apis/v1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/client/t1/partner-apis/v1"
    : "http://localhost:5001/client/t1/partner-apis/v1");

export const projectURL =
  process.env.REACT_APP_PROJECT_APIS_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://apis.aisensy.com/project-apis/v1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/client/t1/project-apis/v1"
    : "http://localhost:5001/client/t1/project-apis/v1");

export const APIURL =
  process.env.REACT_APP_WHATSAPP_REST_API_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://backend.aisensy.com/client/t1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/client/t1"
    : "http://localhost:5001/client/t1");

export const TENANT_ID = process.env.REACT_APP_TENANT_ID;
