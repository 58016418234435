import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "../../commons/ErrorPage/ErrorPage";
import BillingPage from "./BillingPage";
import BillingDetails from "./BillingDetails";
class BillingRoute extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={"/payouts"} component={BillingPage} />
        {/* <Route path={"/payouts/:payoutId"} component={BillingDetails} /> */}
      </Switch>
    );
  }
}

export default BillingRoute;
