import React, { Component } from "react";
import clsx from 'clsx';
import { withStyles, StepConnector } from "@material-ui/core";
import Check from '@material-ui/icons/Check';

const QontoConnector = withStyles((theme)=>({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);



export default QontoConnector