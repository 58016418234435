import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Grid, Box, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailIcon from "@material-ui/icons/Email";

const fields = [
  {
    name: "Name",
    size: 2,
    key: "linkTitle",
  },
  {
    name: "Link URL",
    size: 3,
    key: "link",
  },
  {
    name: "Total Clicks",
    size: 2,
    key: "totalClicks",
  },
  {
    name: "Unique Clicks",
    size: 2,
    key: "uniqueClicks",
  },
  {
    name: "Created On",
    size: 2,
    key: "createdAt",
  },
];

class ClientsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedTemplate: null,
      openLinkDialog: false,
      selectedLink: "",
    };
  }

  handleRowClick = (link) => {
    this.setState({ selectedLink: link, openLinkDialog: true });
  };

  handleClose = () => {
    this.setState({ openLinkDialog: false });
  };

  handleCopy = () => {
    navigator.clipboard.writeText(this.state.selectedLink);
  };

  listScrolled = (event) => {
    if (!this.offsetPosition) {
      this.offsetPosition = this.rootEle.current.offsetTop;
    }
    const container = event.currentTarget;
    const currentPos = container.scrollTop;
    this.setState({
      sticky: currentPos > this.offsetPosition,
    });
  };
  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  render() {
    const { classes, business, isLoading } = this.props;

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid container item xs={12} md={12} lg={12}>
            <Grid container>
              <Box
                className={classes.tableWrapper}
                mb={{ xs: 2, md: 4 }}
                mx={{ xs: 2, md: 0 }}
              >
                <Grid item container className={classes.tableContainer}>
                  <Grid item xs={12} container className={classes.tableHeader}>
                    {fields.map((e) => (
                      <Grid item xs={e.size} key={e.key}>
                        <Box py={2} pl={2}>
                          <Typography variant="h5" color="primary">
                            {e.name}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  {business.map((ads) => (
                    <Grid
                      item
                      xs={12}
                      container
                      onScroll={this.listScrolled}
                      className={classes.tableItem}
                    >
                      {fields.map((field) => (
                        <Grid
                          item
                          xs={field.size}
                          style={{
                            width: "20%",
                          }}
                          onClick={() => {
                            this.handleRowClick(ads.link);
                          }}
                        >
                          <Cell
                            classes={classes}
                            item={ads}
                            field={this.props.isLoading ? "Loading" : field.key}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.openLinkDialog}
          onClose={this.handleClose}
          maxWidth="xl"
          PaperProps={{
            style: {
              width: "40%",
              height: "26%",
            },
          }}
        >
          <DialogTitle>
            <Typography style={{ fontSize: "14px" }}>
              Your referral link
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" style={{ marginBottom: "10px" }}>
              <b>Share your link to refer new customers to AiSensy!</b>
            </Typography>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                style={{ padding: 0 }}
                variant="outlined"
                margin="dense"
                value={this.state.selectedLink}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.handleCopy}>
                        <FileCopyIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                component="a"
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  this.state.selectedLink
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon style={{ color: "#1DA1F2" }} />
              </IconButton>
              <IconButton
                component="a"
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  "Hey👋!\n\nWish to generate 3 times more Revenue for your brand?💰\n\nUse AiSensy, a Smart WhatsApp Engagement Platform⚡️.\nTrusted by 25,000+ brands like Cosco, Skullcandy, and Vivo for Official WhatsApp Marketing & Automation.\n\nCheck it out now - " +
                    this.state.selectedLink
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsAppIcon style={{ color: "#25D366" }} />
              </IconButton>
              <IconButton
                component="a"
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                  this.state.selectedLink
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon style={{ color: "#0762C8" }} />
              </IconButton>
              {/* <IconButton
                component="a"
                href={`mailto:?body=${encodeURIComponent(
                  this.state.selectedLink
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EmailIcon />
              </IconButton> */}
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
function Cell({ classes, item, field, openCampaign, ...props }) {
  switch (field) {
    case "linkTitle":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {item[field]}
            </Typography>
          </Box>
        </Grid>
      );
      break;

    case "link":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography
              variant="h5"
              style={{
                wordWrap: "break-word",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item[field].length > 35
                ? item[field].substring(0, 35) + "..."
                : item[field]}
            </Typography>
          </Box>
        </Grid>
      );
      break;
    case "createdAt":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {getFormattedDate(item[field])}
            </Typography>
          </Box>
        </Grid>
      );
      break;

    case "totalClicks":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {item[field]}
            </Typography>
          </Box>
        </Grid>
      );
      break;

    case "uniqueClicks":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {item[field]}
            </Typography>
          </Box>
        </Grid>
      );
    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Skeleton
              variant="rect"
              width="100%"
              style={{ borderRadius: "15px" }}
            />
          </Box>
        </Grid>
      );
      break;
    default:
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              <FileCopyOutlinedIcon
                style={{ cursor: "pointer", color: "grey" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(item.link);
                }}
              />
            </Typography>
          </Box>
        </Grid>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
  },
  tableWrapper: {
    overflowX: "auto",
    width: "100%",
  },

  tableContainer: {
    marginTop: "1.5em",
    minWidth: 1100,
  },

  tableItem: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
    },
  },

  tableHeader: {
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
  },
});
const ClientTableconnect = connect((state) => ({
  isLoading: state.client.isLoading,
}))(ClientsTable);
export default withStyles(styles)(withRouter(ClientTableconnect));
