// Header.js
import React, { useState } from "react";
import {
  withStyles,
  Typography,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Box,
  IconButton,
  Badge,
  Tooltip,
} from "@material-ui/core";
import {
  FilterList,
  Clear,
  ArrowBackOutlined,
  Search,
  GetApp,
} from "@material-ui/icons";
import DownloadReport from "../../pages/Assistants/WccAnalytics/DownloadReport";

function Header(props) {
  const {
    classes,
    businessTitle,
    buttonPrefixIcon,
    buttonTitle,
    onButtonClick,
    handleSearch,
    handleEnter,
    searchDone,
    handleClear,
    showSearch,
    searchPlaceholder,
    filter,
    disableFilterBtn,
    toggleFilterDialog,
    onBackPress,
    backgroundColor,
    filterColor,
    syncButtonTitle,
    onSyncButtonClick,
    syncButtonPrefixIcon,
    tooltipTitle,
    disableTemplateButton,
    businessArchived,
    convoReportDownload,
    getConvoReport,
    reportLoading,
    partnerId,
    clientReport,
    projectReport,
    onOnboardingCallClick,
    onPlatformDemoClick,
    showDashboardButtons, // New prop to control button visibility
  } = props;

  const [showReport, setShowReport] = useState(false);

  return (
    <Grid
      container
      alignItems="center"
      className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
      style={{ height: window.innerWidth < 768 && 160, backgroundColor }}
    >
      <Grid item xs={12}>
        <Box
          px={{ xs: 2, md: 2 }}
          justifyContent="space-between"
          py={2}
          display="flex"
          alignItems="center"
        >
          {onBackPress && (
            <IconButton onClick={onBackPress}>
              <ArrowBackOutlined />
            </IconButton>
          )}
          {/* business title */}
          <Typography variant="h3" style={{ flex: 1 }}>
            {businessTitle}
          </Typography>

          {window.innerWidth >= 768 && (
            <>
              {showDashboardButtons && (
                <>
                  {/* Onboarding Call Button */}
                  <Button
                    onClick={onOnboardingCallClick}
                    color="primary"
                    variant="contained"
                    className={classes.fixedWidthButton}
                  >
                    Onboarding Call
                  </Button>
                  {/* Platform Demo Button */}
                  <Button
                    onClick={onPlatformDemoClick}
                    color="primary"
                    variant="contained"
                    className={classes.fixedWidthButton}
                  >
                    Platform Demo
                  </Button>
                </>
              )}
              {/* Show button */}
              {buttonTitle && (
                <Button
                  onClick={onButtonClick}
                  color="primary"
                  variant="contained"
                  startIcon={buttonPrefixIcon}
                  disabled={disableTemplateButton}
                  style={{
                    marginRight: 8,
                    height: 42,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {buttonTitle}
                </Button>
              )}
              {/* Show search textfield */}
              {showSearch && (
                <TextField
                  name="search"
                  placeholder={searchPlaceholder}
                  className={classes.textField}
                  onChange={handleSearch}
                  onKeyPress={handleEnter}
                  value={filter}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            searchDone
                              ? handleClear()
                              : handleEnter({
                                  key: "Enter",
                                  target: { value: filter },
                                })
                          }
                        >
                          {searchDone ? <Clear /> : <Search />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </>
          )}

          {/* Show Download Report */}
          {convoReportDownload && (
            <Box>
              <Button
                style={{ right: "5px" }}
                color="primary"
                size="small"
                onClick={() => setShowReport(true)}
                variant="outlined"
                startIcon={<GetApp />}
              >
                {window.innerWidth < 400 ? "Report" : "Download Report"}
              </Button>

              <DownloadReport
                classes={classes}
                showReport={showReport}
                setShowReport={setShowReport}
                getConvoReport={getConvoReport}
                reportLoading={reportLoading}
                convoReportDownload={convoReportDownload}
                dialogHeading={"Download Partner Report"}
                dialogBody={
                  "Select the date range to download partner conversation report"
                }
                partnerId={partnerId}
              />
            </Box>
          )}

          {projectReport && (
            <Box>
              <Button
                style={{ right: "5px" }}
                color="primary"
                size="small"
                onClick={() => setShowReport(true)}
                variant="outlined"
                startIcon={<GetApp />}
              >
                {window.innerWidth < 400 ? "Report" : "Download Report"}
              </Button>

              <DownloadReport
                classes={classes}
                showReport={showReport}
                setShowReport={setShowReport}
                getConvoReport={getConvoReport}
                reportLoading={reportLoading}
                projectReport={projectReport}
                convoReportDownload={true}
                dialogHeading={"Download Project Report"}
                dialogBody={
                  "Select the date range to download project conversation report"
                }
              />
            </Box>
          )}

          {/* Show Filter icon */}
          {toggleFilterDialog && (
            <Badge color="primary" variant="dot" invisible={!filterColor}>
              <IconButton
                disable={disableFilterBtn}
                onClick={toggleFilterDialog}
                style={{ color: filterColor ? "#461486" : "" }}
              >
                <FilterList />
              </IconButton>
            </Badge>
          )}
        </Box>
      </Grid>
      {window.innerWidth < 768 && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0 16px",
          }}
        >
          {/* Show button */}
          {syncButtonTitle && (
            <Tooltip title={tooltipTitle}>
              <span>
                <Button
                  onClick={onSyncButtonClick}
                  color="primary"
                  variant="contained"
                  disabled={disableTemplateButton}
                  startIcon={syncButtonPrefixIcon}
                  style={{
                    marginRight: 8,
                    height: 42,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {syncButtonTitle}
                </Button>
              </span>
            </Tooltip>
          )}

          {buttonTitle && !businessArchived && (
            <Button
              onClick={onButtonClick}
              color="primary"
              variant="contained"
              style={{
                marginRight: 8,
                justifyContent: window.innerWidth <= 320 && "flex-start",
                height: 42,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {buttonTitle}
            </Button>
          )}

          {/* Show search textfield */}
          {showSearch && (
            <TextField
              name="search"
              placeholder={searchPlaceholder}
              className={classes.textField}
              onChange={handleSearch}
              onKeyPress={handleEnter}
              value={filter}
              InputProps={
                searchDone && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear}>
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              }
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}

const styles = (theme) => ({
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 80,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  fixedWidthButton: {
    width:"9%",
    marginRight: 8,
    height: 42,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis", // Fixed width for the buttons
  },
  dialogContainer: {
    "& .MuiDialogContent-root": { overflow: "hidden" },
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "450px",
        minWidth: "100px",
        height: "500px",
        margin: "0",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "450px",
        height: "550px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },

  textField: {
    width: 210,
    // marginRight: 8,
    height: 42,
    "& .MuiIconButton-root": {
      padding: 6,
      background: "rgb(70 20 134 / 15%)",
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 15,
    },
  },
});

export default withStyles(styles)(Header);
