import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles, Grid, Box, Typography, Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import { APIURL, URL } from "../../config/config";
import { Assistant } from "@material-ui/icons";
const fields = [
  {
    name: "Commision Paid",
    key: "amountPaid",
    size: 2,
  },
  {
    name: "Currency",
    key: "currency",
    size: 2,
  },
  {
    name: "Period",
    key: "dateRange",
    size: 3,
  },
  {
    name: "Paid On",
    key: "createdAt",
    size: 2,
  },
  {
    name: "Remarks",
    key: "remarks",
    size: 3,
  },
];

class BillingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedTemplate: null,
    };
  }

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  openBills = (log) => {
    if (log.assistantId) {
      axios
        .get(`${APIURL}/affiliates/open-billing-log/${log.assistantId}`)
        .then((response) => {
          const assistantName = response.data.assistantName;
          this.props.history.push({
            pathname: "/billings/" + log._id,
            state: { ...log, assistantName },
          });
        });
    }
  };
  render() {
    const currency = this.props.user.currency;
    const { classes, bills, isLoading } = this.props;
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid container item xs={12} md={12} lg={12}>
            <Grid container>
              <Box
                className={classes.tableWrapper}
                mb={{ xs: 2, md: 4 }}
                mx={{ xs: 2, md: 0 }}
              >
                <Grid item container className={classes.tableContainer}>
                  <Grid item xs={12} container className={classes.tableHeader}>
                    {fields.map((e) => (
                      <Grid
                        item
                        xs={e.size}
                        key={e.key}
                        style={{
                          width: "25%",
                        }}
                      >
                        <Box py={2} pl={2}>
                          <Typography variant="h5" color="primary">
                            {e.name}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  {bills.map((log) => (
                    <Grid
                      item
                      xs={12}
                      container
                      onScroll={this.listScrolled}
                      className={classes.tableItem}
                    >
                      {fields.map((field) => (
                        <Grid
                          item
                          xs={field.size}
                          style={{
                            width: "25%",
                          }}
                        >
                          <Cell
                            classes={classes}
                            item={log}
                            field={this.props.isLoading ? "Loading" : field.key}
                            currency={currency}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function Cell({ classes, item, field, openCampaign, currency, ...props }) {
  switch (field) {
    case "amountPaid":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1">
            {`${
              item.currency === "INR" ? "₹" : item.currency === "USD" ? "$" : ""
            } ${(item[field] / 100000).toFixed(2)}`}
          </Typography>
        </Box>
      );

      break;
    case "commision":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1">
            {`${
              item.currency === "INR" ? "₹" : item.currency === "USD" ? "$" : ""
            } ${(item[field] / 100000).toFixed(2)}`}
          </Typography>
        </Box>
      );
      break;
    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Skeleton
              variant="rect"
              width="100%"
              style={{ borderRadius: "15px" }}
            />
          </Box>
        </Grid>
      );

    case "createdAt":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1">
            {getFormattedDate(item[field])}
          </Typography>
        </Box>
      );
      break;
    case "dateRange":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1">
            {getFormattedDate(item.startDate)} -{" "}
            {getFormattedDate(item.endDate)}
          </Typography>
        </Box>
      );
      break;
    default:
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1">{item[field]}</Typography>
        </Box>
      );
  }
}
function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "scroll",
    position: "relative",
    // marginBottom: "5em",
    // paddingBottom: "80px"
  },
  tableWrapper: {
    // overflowX: "auto",
    width: "100%",
  },

  tableContainer: {
    marginTop: "1.5em",
    minWidth: 1000,
  },

  tableItem: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    // flexWrap: "nowrap",
    borderRadius: 8,
    // cursor: "pointer",
    // "&:hover": {
    //   background: "#efefef",
    // },
  },

  tableHeader: {
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
    // flexWrap: "nowrap",
  },
});
const BillingTableconnect = connect(
  (state) => ({
    isLoading: state.billing.isLoading,
    user: state.login.user,
  }),
  {}
)(BillingTable);
export default withStyles(styles)(withRouter(BillingTableconnect));
