// Wrapper: desktop & mobile versions of navigation bar

import React from "react";

import NavBarDesktop from "./NavBarDesktop";
import NavBarMobile from "./NavBarMobile";

const OPTIONS = [
  {
    name: "Home",
    url: "/dashboard",
    icon: "home",
  },
  {
    name: "Analytics",
    url: "/dashboard/analytics",
  },
];

function NavBar(props) {
  return (
    <React.Fragment>
      <NavBarDesktop option={OPTIONS} />
      <NavBarMobile option={OPTIONS} />
    </React.Fragment>
  );
}

export default NavBar;
