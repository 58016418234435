import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  withStyles,
  TextField,
  Grid,
  Typography,
  Button,
  Box,
  InputAdornment,
  Tooltip,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { createnewClient } from "./Clientstore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dialCodes, { countryMap, isoMap } from "../../config/dialCodes";
import { timeZones } from "../../config/timezone";

export const minBusinessFieldsPartners = process.env
  .MIN_BUSINESS_FIELDS_PARTNERS
  ? process.env.MIN_BUSINESS_FIELDS_PARTNERS.split(",")
  : ["646357594e6a060aa94cb99f"];

class AdDetailStep extends Component {
  handleInput = (e) => {
    // this.setState({ [e.target.name]: e.target.value });
    this.props.updateState(e.target.name, e.target.value);
  };

  render() {
    const { classes } = this.props;

    const DOMAINS = ["app.aisensy.com", "m.aisensy.com"];

    return (
      <Grid xs container marginTop="3em" justify="center">
        <Box style={{ width: "100%" }}>
          <Typography
            variant="h3"
            style={{
              marginBottom: "16px",
            }}
            color="primary"
          >
            Create Your Affiliate Link
          </Typography>

          <TextField
            id="name"
            label="Link Name"
            variant="outlined"
            type="text"
            name="link_name"
            onChange={this.handleInput}
            value={this.props.link_name}
            className={classes.textFieldRoot}
            required
          />

          <Autocomplete
            disableClearable
            options={DOMAINS.map((option) => option)}
            onChange={(e, value, reason) => {
              const domain = "domain";
              this.props.updateState(domain, value);
            }}
            value={this.props.domain}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Domain"
                variant="outlined"
                classes={{ root: classes.textFieldRootAutocomplete }}
                InputProps={{ ...params.InputProps }}
                required
              />
            )}
          />
        </Box>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: "20px 10px",
    display: "flex",
  },
  codeTextFieldRoot: {
    width: "90px",
    padding: "0 0 0 14px",
    position: "absolute",
    zIndex: 1,
    margin: "10px 0 0 2px",
    background: "white",
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "12px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  bottomMargin: {
    marginBottom: theme.spacing(4),
  },
  TextFieldRoot: {
    width: "100%",
    padding: "2px",
    marginTop: "4px",
    // marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)" },
    background: "rgb(240,240,240)",
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)", marginLeft: "80px" },
    background: "white",
    border: "2px solid grey",
  },
  passwordIcon: {
    cursor: "pointer",
    fontSize: 20,
  },
});

const Adsconnect = connect(
  (state) => ({
    partner: state.partner.partner,
  }),
  { createnewClient }
)(AdDetailStep);

export default withStyles(styles)(withRouter(Adsconnect));
