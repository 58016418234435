import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Tooltip,
  CircularProgress,
  IconButton,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { isoToName } from "../../config/dialCodes";
import { CheckBoxRounded, Clear } from "@material-ui/icons";
import WccRow from "./WccRow";
import QontoStepIcon from "../../commons/Stepper/QontoStepIcon";
import QontoConnector from "../../commons/Stepper/QontoConnector";
import BulkUpdate from "./BulkUpdate";
import { connect } from "react-redux";

const convertPrice = 100000;
const STEPS = ["Pricing Details", "Create Plan"];

class CreateWccPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_initiated: {},
      business_initiated: {},
      service_initiated: {},
      marketing_initiated: {},
      authentication_initiated: {},
      utility_initiated: {},
      name: "",
      activeStep: 0,
      keyValue: 0,
    };
  }

  componentDidMount = () => {
    const {
      aisensy_business_initiated,
      aisensy_user_initiated,
      aisensy_service,
      aisensy_authentication,
      aisensy_marketing,
      aisensy_utility,
    } = this.props;
    const user_initiated = {};
    const business_initiated = {};
    const service_initiated = {};
    const authentication_initiated = {};
    const marketing_initiated = {};
    const utility_initiated = {};

    // <-- UIC -->
    for (const iso in aisensy_user_initiated) {
      if (!aisensy_user_initiated[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_user_initiated[iso]?.INR;
      const aisensyUSDPrice = aisensy_user_initiated[iso]?.USD;

      const partnerINRPrice = user_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = user_initiated[iso]?.USD ?? aisensyUSDPrice;
      user_initiated[iso] = {};
      user_initiated[iso].INR = partnerINRPrice;
      user_initiated[iso].USD = partnerUSDPrice;
      user_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      user_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }
    //<-- BIC -->
    for (const iso in aisensy_business_initiated) {
      if (!aisensy_business_initiated[iso]) {
        continue;
      }

      const aisensyINRPrice = aisensy_business_initiated[iso]?.INR;
      const aisensyUSDPrice = aisensy_business_initiated[iso]?.USD;

      const partnerINRPrice = business_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = business_initiated[iso]?.USD ?? aisensyUSDPrice;
      business_initiated[iso] = {};
      business_initiated[iso].INR = partnerINRPrice;
      business_initiated[iso].USD = partnerUSDPrice;
      business_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      business_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }

    for (const iso in aisensy_service) {
      if (!aisensy_service[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_service[iso]?.INR;
      const aisensyUSDPrice = aisensy_service[iso]?.USD;

      const partnerINRPrice = service_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = service_initiated[iso]?.USD ?? aisensyUSDPrice;
      service_initiated[iso] = {};
      service_initiated[iso].INR = partnerINRPrice;
      service_initiated[iso].USD = partnerUSDPrice;
      service_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      service_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }

    for (const iso in aisensy_authentication) {
      if (!aisensy_authentication[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_authentication[iso]?.INR;
      const aisensyUSDPrice = aisensy_authentication[iso]?.USD;

      const partnerINRPrice =
        authentication_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice =
        authentication_initiated[iso]?.USD ?? aisensyUSDPrice;
      authentication_initiated[iso] = {};
      authentication_initiated[iso].INR = partnerINRPrice;
      authentication_initiated[iso].USD = partnerUSDPrice;
      authentication_initiated[iso].inrMargin =
        partnerINRPrice - aisensyINRPrice;
      authentication_initiated[iso].usdMargin =
        partnerUSDPrice - aisensyUSDPrice;
    }

    for (const iso in aisensy_marketing) {
      if (!aisensy_marketing[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_marketing[iso]?.INR;
      const aisensyUSDPrice = aisensy_marketing[iso]?.USD;

      const partnerINRPrice = marketing_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = marketing_initiated[iso]?.USD ?? aisensyUSDPrice;
      marketing_initiated[iso] = {};
      marketing_initiated[iso].INR = partnerINRPrice;
      marketing_initiated[iso].USD = partnerUSDPrice;
      marketing_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      marketing_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }

    for (const iso in aisensy_utility) {
      if (!aisensy_utility[iso]) {
        continue;
      }
      const aisensyINRPrice = aisensy_utility[iso]?.INR;
      const aisensyUSDPrice = aisensy_utility[iso]?.USD;

      const partnerINRPrice = utility_initiated[iso]?.INR ?? aisensyINRPrice;
      const partnerUSDPrice = utility_initiated[iso]?.USD ?? aisensyUSDPrice;
      utility_initiated[iso] = {};
      utility_initiated[iso].INR = partnerINRPrice;
      utility_initiated[iso].USD = partnerUSDPrice;
      utility_initiated[iso].inrMargin = partnerINRPrice - aisensyINRPrice;
      utility_initiated[iso].usdMargin = partnerUSDPrice - aisensyUSDPrice;
    }

    this.setState({
      user_initiated,
      business_initiated,
      service_initiated,
      marketing_initiated,
      authentication_initiated,
      utility_initiated,
    });
  };

  setStep = (value) => {
    this.setState({ activeStep: value });
  };

  updateBIC = (value, key) => {
    const { currency } = this.props;
    const business_initiated = {
      ...this.state.business_initiated,
    };

    business_initiated[key][currency] = value;

    this.setState({
      business_initiated,
    });
  };

  updateMc = (value, key) => {
    const { currency } = this.props;
    const marketing_initiated = {
      ...this.state.marketing_initiated,
    };
    marketing_initiated[key][currency] = value;

    this.setState({
      marketing_initiated,
    });
  };

  upadteAc = (value, key) => {
    const { currency } = this.props;
    const authentication_initiated = {
      ...this.state.authentication_initiated,
    };
    authentication_initiated[key][currency] = value;

    this.setState({
      authentication_initiated,
    });
  };

  updateUc = (value, key) => {
    const { currency } = this.props;
    const utility_initiated = {
      ...this.state.utility_initiated,
    };
    utility_initiated[key][currency] = value;

    this.setState({
      utility_initiated,
    });
  };

  updateSc = (value, key) => {
    const { currency } = this.props;
    const service_initiated = {
      ...this.state.service_initiated,
    };
    service_initiated[key][currency] = value;

    this.setState({
      service_initiated,
    });
  };

  updateUIC = (value, key) => {
    const { currency } = this.props;
    const user_initiated = {
      ...this.state.user_initiated,
    };
    user_initiated[key][currency] = value;

    this.setState({
      user_initiated,
    });
  };

  applyBulkUpdate = (convoType, sign, value) => {
    const {
      currency,
      aisensy_business_initiated,
      aisensy_user_initiated,
      aisensy_service,
      aisensy_authentication,
      aisensy_marketing,
      aisensy_utility,
    } = this.props;
    const {
      business_initiated: bic,
      user_initiated: uic,
      service_initiated,
      marketing_initiated,
      authentication_initiated,
      utility_initiated,
    } = this.state;
    const business_initiated = JSON.parse(JSON.stringify(bic));
    const user_initiated = JSON.parse(JSON.stringify(uic));
    // Bulk update bic of all countries
    if (convoType === "bic") {
      if (sign === "%") {
        value /= 100;
        for (const key in business_initiated) {
          const prevBIC = aisensy_business_initiated[key][currency];
          const newMargin = prevBIC * value;
          business_initiated[key][currency] = prevBIC + newMargin;
          business_initiated[key][currency.toLowerCase() + "Margin"] =
            newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in business_initiated) {
          const prev = aisensy_business_initiated[key][currency];
          business_initiated[key][currency] = prev + value;
          business_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }
    // Bulk update uic of all countries
    if (convoType === "uic") {
      if (sign === "%") {
        value /= 100;
        for (const key in user_initiated) {
          const prevUIC = aisensy_user_initiated[key][currency];
          const newMargin = prevUIC * value;
          user_initiated[key][currency] = prevUIC + newMargin;
          user_initiated[key][currency.toLowerCase() + "Margin"] = newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in user_initiated) {
          const prev = aisensy_user_initiated[key][currency];
          user_initiated[key][currency] = prev + value;
          user_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }

    // for marketing
    if (convoType === "mc") {
      if (sign === "%") {
        value /= 100;
        for (const key in marketing_initiated) {
          const prevUIC = aisensy_marketing[key][currency];
          const newMargin = prevUIC * value;
          marketing_initiated[key][currency] = prevUIC + newMargin;
          marketing_initiated[key][currency.toLowerCase() + "Margin"] =
            newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in marketing_initiated) {
          const prev = aisensy_marketing[key][currency];
          marketing_initiated[key][currency] = prev + value;
          marketing_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }

    //for service
    if (convoType === "sc") {
      if (sign === "%") {
        value /= 100;
        for (const key in service_initiated) {
          const prevUIC = aisensy_service[key][currency];
          const newMargin = prevUIC * value;
          service_initiated[key][currency] = prevUIC + newMargin;
          service_initiated[key][currency.toLowerCase() + "Margin"] = newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in service_initiated) {
          const prev = aisensy_service[key][currency];
          service_initiated[key][currency] = prev + value;
          service_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }

    // for utility
    if (convoType === "uc") {
      if (sign === "%") {
        value /= 100;
        for (const key in utility_initiated) {
          const prevUIC = aisensy_utility[key][currency];
          const newMargin = prevUIC * value;
          utility_initiated[key][currency] = prevUIC + newMargin;
          utility_initiated[key][currency.toLowerCase() + "Margin"] = newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in utility_initiated) {
          const prev = aisensy_utility[key][currency];
          utility_initiated[key][currency] = prev + value;
          utility_initiated[key][currency.toLowerCase() + "Margin"] = value;
        }
      }
    }

    // for authentication

    if (convoType === "ac") {
      if (sign === "%") {
        value /= 100;
        for (const key in authentication_initiated) {
          const prevUIC = aisensy_authentication[key][currency];
          const newMargin = prevUIC * value;
          authentication_initiated[key][currency] = prevUIC + newMargin;
          authentication_initiated[key][currency.toLowerCase() + "Margin"] =
            newMargin;
        }
      }
      if (sign === "+") {
        value *= 100000;
        for (const key in authentication_initiated) {
          const prev = aisensy_authentication[key][currency];
          authentication_initiated[key][currency] = prev + value;
          authentication_initiated[key][currency.toLowerCase() + "Margin"] =
            value;
        }
      }
    }

    this.setState({
      business_initiated: { ...business_initiated },
      user_initiated: { ...user_initiated },
      marketing_initiated: { ...marketing_initiated },
      service_initiated: { ...service_initiated },
      authentication_initiated: { ...authentication_initiated },
      utility_initiated: { ...utility_initiated },
      keyValue: this.state.keyValue + 1,
    });
  };

  render() {
    const {
      classes,
      openDialog,
      toggleDialog,
      aisensy_user_initiated,
      aisensy_business_initiated,
      currency,
      createWccPlan,
      aisensy_service,
      aisensy_authentication,
      aisensy_marketing,
      aisensy_utility,
    } = this.props;

    const {
      user_initiated,
      business_initiated,
      name,
      service_initiated,
      authentication_initiated,
      marketing_initiated,
      utility_initiated,
    } = this.state;

    return Object.keys(business_initiated).length <= 0 ? (
      <CircularProgress />
    ) : (
      <>
        <Dialog
          open={openDialog}
          closeDialog={toggleDialog}
          maxWidth={"md"}
          fullWidth
        >
          <DialogTitle>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">Create Wcc Plan</Typography>
              <IconButton
                color="primary"
                size="small"
                variant="filled"
                onClick={toggleDialog}
              >
                <Clear
                  style={{
                    position: "fixed",
                  }}
                />
              </IconButton>
            </Box>
          </DialogTitle>

          {/* <Tabs
              value={currency}
              onChange={this.handleCurrencyChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                className: classes.tabIndicator,
              }}
            >
              <Tab label="₹INR" value="INR" className={classes.tabHeader} />
              <Tab label="$USD" value="USD" className={classes.tabHeader} />
            </Tabs> */}
          <Grid item xs={12} alignItems="center">
            <Stepper
              style={{ paddingTop: 0, paddingBottom: 0 }}
              alternativeLabel
              activeStep={this.state.activeStep}
              connector={<QontoConnector />}
            >
              {STEPS.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          {!this.state.activeStep && (
            <Grid style={{ padding: "24px 24px 16px 46px" }}>
              <BulkUpdate applyBulkUpdate={this.applyBulkUpdate} />
            </Grid>
          )}
          <Box
            style={{
              padding: "24px 16px 16px 24px",
              width: this.state.activeStep ? "auto" : 2000,
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {this.state.activeStep ? (
              <DialogContent>
                <Typography
                  variant="h3"
                  style={{
                    marginBottom: "16px",
                  }}
                  color="primary"
                >
                  Plan Name
                </Typography>
                <Tooltip
                  enterTouchDelay={0}
                  style={{ fontSize: "1.2em", width: "100%" }}
                  title="Your plan name must be unique."
                >
                  <TextField
                    id="name"
                    placeholder="Your wcc plan name here"
                    variant="outlined"
                    type="text"
                    name="display_name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                    className={this.props.classes.textFieldRoot}
                  />
                </Tooltip>
              </DialogContent>
            ) : (
              <DialogContent>
                <Grid
                  container
                  alignItems="center"
                  className={classes.content}
                  spacing={0}
                  style={{ marginTop: 24 }}
                >
                  <Grid item xs={12} md={1}></Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    md={11}
                    style={{ marginBottom: "8px" }}
                  >
                    <Grid md={12} style={{ display: "flex", marginBottom: 10 }}>
                      <Grid
                        item
                        md={3}
                        style={{
                          alignItems: "center",
                          borderBottom: "1px solid #c4c4c4",
                          background: "#461486",
                          marginRight: 8,
                          color: "white",
                        }}
                      >
                        <Typography variant="h4" align="center">
                          Marketing Initiated Charges
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        style={{
                          alignItems: "center",
                          borderBottom: "1px solid #c4c4c4",
                          background: "#461486",
                          marginRight: 8,
                          color: "white",
                        }}
                      >
                        <Typography variant="h4" align="center">
                          Service Initiated Charges
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        style={{
                          alignItems: "center",
                          borderBottom: "1px solid #c4c4c4",
                          background: "#461486",
                          marginRight: 8,
                          color: "white",
                        }}
                      >
                        <Typography variant="h4" align="center">
                          Utility Initiated Charges
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        style={{
                          alignItems: "center",
                          borderBottom: "1px solid #c4c4c4",
                          background: "#461486",
                          marginRight: 8,
                          color: "white",
                        }}
                      >
                        <Typography variant="h4" align="center">
                          Authentication Initiated Charges
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box md={2}></Box>
                    <Grid md={12} style={{ display: "flex" }}>
                      <Grid item md={3} style={{ display: "flex" }}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            {Object.keys(this.props.tenantDetails).length
                              ? this.props.tenantDetails.name
                              : "AiSensy"}{" "}
                            Prices
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            Margin
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            Your Platform Prices
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={3} style={{ display: "flex" }}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            {Object.keys(this.props.tenantDetails).length
                              ? this.props.tenantDetails.name
                              : "AiSensy"}{" "}
                            Prices
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            Margin
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            Your Platform Prices
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={3} style={{ display: "flex" }}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            {Object.keys(this.props.tenantDetails).length
                              ? this.props.tenantDetails.name
                              : "AiSensy"}{" "}
                            Prices
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            Margin
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            Your Platform Prices
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={3} style={{ display: "flex" }}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            {Object.keys(this.props.tenantDetails).length
                              ? this.props.tenantDetails.name
                              : "AiSensy"}{" "}
                            Prices
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            Margin
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                          >
                            Your Platform Prices
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      maxHeight: "50vh",
                      overflow: "scroll",
                    }}
                  >
                    {aisensy_business_initiated &&
                      Object.keys(aisensy_business_initiated).map((key) => {
                        return (
                          <Grid container key={key} xs={12} md={12}>
                            <WccRow
                              countryCode={key}
                              country={isoToName[key]}
                              aisensyBicPrice={
                                aisensy_business_initiated[key][currency] /
                                convertPrice
                              }
                              partnerBicPrice={
                                business_initiated[key][currency] / convertPrice
                              }
                              bicMargin={
                                business_initiated[key][
                                  currency?.toLowerCase() + "Margin"
                                ] / convertPrice
                              }
                              aisensyUicPrice={
                                aisensy_user_initiated[key][currency] /
                                convertPrice
                              }
                              partnerUicPrice={
                                user_initiated[key][currency] / convertPrice
                              }
                              uicMargin={
                                user_initiated[key][
                                  currency?.toLowerCase() + "Margin"
                                ] / convertPrice
                              }
                              partnerServiceInitiated={
                                service_initiated[key][currency] / convertPrice
                              }
                              partnerAuthenticationInitiated={
                                authentication_initiated[key][currency] /
                                convertPrice
                              }
                              parterUtilityInitiated={
                                utility_initiated[key][currency] / convertPrice
                              }
                              partnerMarketingInitiated={
                                marketing_initiated[key][currency] /
                                convertPrice
                              }
                              serviceMargin={
                                service_initiated[key][
                                  currency?.toLowerCase() + "Margin"
                                ] / convertPrice
                              }
                              utilityMargin={
                                utility_initiated[key][
                                  currency?.toLowerCase() + "Margin"
                                ] / convertPrice
                              }
                              authenticationMargin={
                                authentication_initiated[key][
                                  currency?.toLowerCase() + "Margin"
                                ] / convertPrice
                              }
                              marketingMargin={
                                marketing_initiated[key][
                                  currency?.toLowerCase() + "Margin"
                                ] / convertPrice
                              }
                              updateMc={this.updateMc}
                              upadteAc={this.upadteAc}
                              updateUc={this.updateUc}
                              updateSc={this.updateSc}
                              aisensyAuthenticationInitiated={
                                aisensy_authentication[key][currency] /
                                convertPrice
                              }
                              aisensyUtilityInitiated={
                                aisensy_utility[key][currency] / convertPrice
                              }
                              aisensyServiceInitiated={
                                aisensy_service[key][currency] / convertPrice
                              }
                              aisensyMarketingInitiated={
                                aisensy_marketing[key][currency] / convertPrice
                              }
                              currency={currency}
                              updateBIC={this.updateBIC}
                              updateUIC={this.updateUIC}
                              keyValue={this.state.keyValue}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </DialogContent>
            )}
            <DialogActions
              style={{
                padding: "24px 16px 8px 0px",
                justifyContent: "flex-start",
              }}
            >
              <Button
                variant={"outlined"}
                size="medium"
                color="primary"
                onClick={() => {
                  if (this.state.activeStep) this.setStep(0);
                  else toggleDialog();
                }}
              >
                {this.state.activeStep ? "Back" : "Cancel"}
              </Button>
              {!this.props.disableFields && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={this.state.activeStep && !this.state.name}
                  onClick={() =>
                    this.state.activeStep
                      ? createWccPlan({
                          name,
                          business_initiated,
                          user_initiated,
                          service: service_initiated,
                          authentication: authentication_initiated,
                          marketing: marketing_initiated,
                          utility: utility_initiated,
                        })
                      : this.setStep(1)
                  }
                >
                  {!this.state.activeStep ? "Next" : "Create"}
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </>
    );
  }
}

const connectWccPlan = connect((state) => ({
  tenantDetails: state.tenant.tenant,
}))(CreateWccPlan);

export default React.memo(connectWccPlan);
