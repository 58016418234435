const OPTIONS = [
	"Afrikaans",
	"Albanian",
	"Arabic",
	"Azerbaijani",
	"Bengali",
	"Bulgarian",
	"Catalan",
	"Chinese (CHN)",
	"Chinese (HKG)",
	"Chinese (TAI)",
	"Croatian",
	"Czech",
	"Danish",
	"Dutch",
	"English",
	"English (UK)",
	"English (US)",
	"Estonian",
	"Filipino",
	"Finnish",
	"French",
	"German",
	"Greek",
	"Gujarati",
	"Hausa",
	"Hebrew",
	"Hindi",
	"Hungarian",
	"Indonesian",
	"Irish",
	"Italian",
	"Japanese",
	"Kannada",
	"Kazakh",
	"Korean",
	"Lao",
	"Latvian",
	"Lithuanian",
	"Macedonian",
	"Malay",
	"Malayalam",
	"Marathi",
	"Norwegian",
	"Persian",
	"Polish",
	"Portuguese (BR)",
	"Portuguese (POR)",
	"Punjabi",
	"Romanian",
	"Russian",
	"Serbian",
	"Slovak",
	"Slovenian",
	"Spanish",
	"Spanish (ARG)",
	"Spanish (SPA)",
	"Spanish (MEX)",
	"Swahili",
	"Swedish",
	"Tamil",
	"Telugu",
	"Thai",
	"Turkish",
	"Ukrainian",
	"Urdu",
	"Uzbek",
	"Vietnamese",
	"Zulu"
]

export default OPTIONS