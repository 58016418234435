import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  Link,
  InputAdornment,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import axios from "axios";
import { APIURL as url } from "../../config/config";

const styles = (theme) => ({
  formContainer: {
    width: "100%",
    maxWidth: 400,
    padding: "0 50px 50px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  backToLoginContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(2),
  },
  nextButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    width: "100%",
  },
  nextButton: {
    width: "100%",
  },
  resetPasswordButton: {
    width: "100%",
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 2px",
    marginTop: "4px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  otpInput: {
    width: "60px",
    height: "60px",
    background: "white",
    padding: "4px 6px",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 5px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  otpContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  passwordFieldContainer: {
    alignItems: "center",
    position: "relative",
    width: "100%",
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      formValues: {
        email: "",
        loginPassword: "",
        confirmPassword: "",
        otp: ["", "", "", ""],
      },
      errors: {
        email: "",
        loginPassword: "",
        confirmPassword: "",
        otp: "",
      },
      showPassword: false,
      showConfirmPassword: false,
      successDialogOpen: false,
      isSubmitting: false,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  };

  handleOtpChange = (index, event) => {
    const otp = [...this.state.formValues.otp];
    otp[index] = event.target.value;
    if (event.target.value.length === 1 && index < 3) {
      this[`otpInput${index + 1}`].focus();
    }
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        otp,
      },
    }));
  };

  handleNext = () => {
    const { step } = this.state;
    if (step === 1) {
      this.searchAccount();
    } else {
      this.setState({ step: step + 1 });
    }
  };

  handleBack = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  handleVerifyOtp = () => {
    const { formValues } = this.state;
    axios
      .post(url + "/affiliates/verify-otp", {
        email: formValues.email,
        otp: formValues.otp.join(""),
      })
      .then((response) => {
        if (response.data) {
          this.setState({ step: 4 });
        } else {
          this.setState({
            showSnackbar: true,
            snackbarMessage: "Invalid OTP. Please try again.",
          });
        }
      })
      .catch((error) => {
        console.error("There was an error verifying the OTP!", error);
        this.setState({
          showSnackbar: true,
          snackbarMessage: "Invalid OTP. Please try again.",
        });
      });
  };

  handleSubmit = () => {
    const { formValues } = this.state;
    this.setState({ isSubmitting: true });
    axios
      .post(url + "/affiliates/reset-password", formValues)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ successDialogOpen: true });
        }
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  searchAccount = () => {
    const { formValues } = this.state;
    axios
      .post(url + "/affiliates/search-account", {
        forgottenDetail: formValues.email,
      })
      .then((response) => {
        if (response.data.email) {
          this.setState({ step: 2 });
        }
      })
      .catch((error) => {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            email: "Sorry! No account found. Please contact your project owner.",
          },
        }));
      });
  };

  generateOtp = () => {
    const { formValues } = this.state;
    this.handleNext();
    axios
      .post(url + "/affiliates/reset-password-otp", { email: formValues.email })
      .then((response) => {
        if (response.data.success) {
          this.setState({ step: 3 });
          this.startOtpTimer();
        }
      })
      .catch((error) => {
        console.error("There was an error generating the OTP!", error);
      });
  };

  generateResendOtp = () => {
    const { formValues } = this.state;
    axios
      .post(url + "/affiliates/reset-password-otp", { email: formValues.email })
      .then((response) => {
        if (response.data.success) {
          this.setState({ step: 3 });
          this.startOtpTimer();
        }
      })
      .catch((error) => {
        console.error("There was an error generating the OTP!", error);
      });
  };

  handleCloseSuccessDialog = () => {
    window.location.href = "/login";
  };

  renderStep = () => {
    const { step, formValues, errors, isSubmitting } = this.state;
    const { classes } = this.props;

    switch (step) {
      case 1:
        return (
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <TextField
                  error={!!errors.email}
                  id="outlined-error-helper-text"
                  fullWidth
                  variant="outlined"
                  label="Email"
                  name="email"
                  value={formValues.email}
                  onChange={this.handleInputChange}
                  className={classes.textFieldRootAutocomplete}
                  InputProps={{
                    endAdornment: errors.email && (
                      <InputAdornment position="end">
                        <Tooltip title={errors.email} placement="left">
                          <ErrorOutlineIcon color="error" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Typography
              style={{ textAlign: "center", color: "#4A4A4A" }}
              variant="h5"
              gutterBottom
            >
              Enter your email to help us find your account.
            </Typography>

            <Box className={classes.nextButtonContainer}>
              <Button
                onClick={this.searchAccount}
                color="primary"
                variant="contained"
                disabled={!formValues.email}
                className={classes.nextButton}
              >
                Search Account
              </Button>
            </Box>
            <Box className={classes.forgotPassword}>
              <Link onClick={this.props.handleBackToLogin} variant="body1">
                Back to Login
              </Link>
            </Box>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography
                style={{ textAlign: "center" }}
                variant="body1"
                gutterBottom
              >
                Account Verification
              </Typography>
              <Typography
                style={{ textAlign: "center" }}
                variant="body2"
                gutterBottom
              >
                Yay! An account has been found. Please verify email for reset
                password. OTP will be sent over email.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={this.generateOtp}
                color="primary"
                variant="contained"
                className={classes.resetPasswordButton}
              >
                Send OTP
              </Button>
            </Grid>
            <Box className={classes.forgotPassword}>
              <Link onClick={this.props.handleBackToLogin} variant="body1">
                Back to Login
              </Link>
            </Box>
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={2} direction="column" alignItems="center">
            <Grid item style={{ marginTop: "10%" }}>
              <Typography
                style={{ textAlign: "center" }}
                variant="body1"
                gutterBottom
              >
                OTP sent over email.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.otpContainer}>
              {[0, 1, 2, 3].map((index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  placeholder="0"
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center", fontSize: "24px" },
                  }}
                  value={formValues.otp[index]}
                  onChange={(event) => this.handleOtpChange(index, event)}
                  className={classes.otpInput}
                  inputRef={(input) => (this[`otpInput${index}`] = input)}
                />
              ))}
            </Grid>
            <Grid item style={{ marginRight: "20%" }}>
              <Typography variant="body2" gutterBottom>
                Didn't receive code?{" "}
                <Link onClick={this.generateResendOtp}>Resend OTP</Link>
              </Typography>
            </Grid>
            <Box className={classes.nextButtonContainer}>
              <Button
                onClick={this.handleVerifyOtp}
                color="primary"
                variant="contained"
                disabled={!formValues.otp.every((otp) => otp !== "")}
                className={classes.nextButton}
              >
                Verify OTP
              </Button>
            </Box>
            <Box className={classes.forgotPassword}>
              <Link onClick={this.props.handleBackToLogin} variant="body1">
                Back to Login
              </Link>
            </Box>
          </Grid>
        );
      case 4:
        return (
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              className={classes.passwordFieldContainer}
              style={{ marginTop: "10%" }}
            >
              <TextField
                fullWidth
                variant="outlined"
                label="New Password"
                name="loginPassword"
                type={this.state.showPassword ? "text" : "password"}
                value={formValues.loginPassword}
                onChange={this.handleInputChange}
                error={!!errors.loginPassword}
                helperText={errors.loginPassword}
                className={classes.textFieldRootAutocomplete}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() =>
                          this.setState({
                            showPassword: !this.state.showPassword,
                          })
                        }
                      >
                        {this.state.showPassword ? (
                          <Visibility style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        ) : (
                          <VisibilityOff style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.passwordFieldContainer}>
              <TextField
                fullWidth
                variant="outlined"
                label="Confirm Password"
                name="confirmPassword"
                type={this.state.showConfirmPassword ? "text" : "password"}
                value={formValues.confirmPassword}
                onChange={this.handleInputChange}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                className={classes.textFieldRootAutocomplete}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() =>
                          this.setState({
                            showConfirmPassword: !this.state.showConfirmPassword,
                          })
                        }
                      >
                        {this.state.showConfirmPassword ? (
                          <Visibility style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        ) : (
                          <VisibilityOff style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Box className={classes.nextButtonContainer}>
              <Button
                onClick={this.handleSubmit}
                color="primary"
                variant="contained"
                disabled={
                  !formValues.loginPassword ||
                  !formValues.confirmPassword ||
                  formValues.loginPassword !== formValues.confirmPassword ||
                  isSubmitting
                }
                className={classes.nextButton}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Reset Password"}
              </Button>
            </Box>
            <Box className={classes.backToLoginContainer}>
              <Link onClick={this.props.handleBackToLogin} variant="body1">
                Back to Login
              </Link>
            </Box>
          </Grid>
        );
      default:
        return null;
    }
  };

  render() {
    const { successDialogOpen } = this.state;

    return (
      <Box className={this.props.classes.formContainer}>
        {this.renderStep()}
        <Dialog
          open={successDialogOpen}
          onClose={this.handleCloseSuccessDialog}
        >
          <DialogTitle>Password Reset Successful</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your password has been successfully reset. Please log in with your
              new password.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseSuccessDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default withStyles(styles)(ResetPassword);
