import axios from "axios";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BusinessDetailsForm from "./BusinessDetailsForm";
import { APIURL } from "../../config/config";
import {
  createnewClient,
  loadClient,
  unfetchclients,
  fetchBusinessCounts,
} from "./Clientstore";
import { Clear } from "@material-ui/icons";
import {
  withStyles,
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  Tooltip,
  IconButton,
  Checkbox,
} from "@material-ui/core";

class CreateBusiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      snack: false,
      snackMessage: "",
      snackStatus: "",
      link_name: "",
      buttonDisable: false,
      domain: "",
      clientFilter: {
        filter: "All",
        sort: "dec",
      },
    };
  }

  createAffiliateLink = () => {
    this.setState({ isLoading: true });
    const { link_name, domain, clientFilter } = this.state;

    axios
      .post(APIURL + `/affiliates/create-new-affiliate-link`, {
        linkTitle: link_name,
        domain,
      })
      .then((response) => {
        const snackStatus = "success";
        const snackMessage = "Successfully created";
        this.props.unfetchclients();
        this.props.loadClient(clientFilter);
        this.props.fetchBusinessCounts();
        this.props.businessSnackDialog(snackStatus, snackMessage);
        this.props.businessDialog();
        this.setState({
          link_name: null,
          domain: null,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        const snackStatus = "error";
        const snackMessage = error?.response?.data?.message;
        this.props.businessSnackDialog(snackStatus, snackMessage);
      });
  };

  updateState = (keys, value) => {
    const returnObj = {};
    returnObj[keys] = value;
    this.setState(returnObj);
  };

  render() {
    const { classes, tags } = this.props;
    const { activeStep, snackStatus } = this.state;

    return (
      <div className={classes.root}>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            color="primary"
            size="small"
            variant="filled"
            onClick={() => this.props.businessDialog()}
          >
            <Clear
              style={{
                position: "fixed",
              }}
            />
          </IconButton>
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <Grid
            xs={12}
            md={12}
            lg={12}
            container
            justify="center"
            className={classes.formContainer}
          >
            <Grid
              container
              item
              xs={12}
              md={12}
              style={{ height: 450, width: 400 }}
              className={classes.stepContainer}
              alignItems="flex-start"
            >
              <BusinessDetailsForm
                updateState={this.updateState}
                link_name={this.state.link_name}
                domain={this.state.domain}
              />
            </Grid>

            <Grid
              container
              item
              xs={12}
              md={12}
              className={classes.buttonContainer}
              alignItems="center"
              justify="space-between"
            >
              {
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    margin={0}
                    color="primary"
                    onClick={this.createAffiliateLink}
                    disabled={
                      this.state.isLoading ||
                      !this.state.domain ||
                      !this.state.link_name
                    }
                  >
                    Create
                  </Button>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    // width: "100%",
    height: "100%",
    overflowY: "scroll",
  },

  formContainer: {
    borderRadius: "8px",
    // minHeight: 200,
    justifyContent: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "disabled",
    border: "2px solid disabled",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "disabled",
      // color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },

  formContainer1: {
    background: "white",
    borderRadius: "8px",
    // minHeight: 200,
    boxSizing: "border-box",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    marginBottom: "20px",
  },

  stepContainer: {
    overflow: "auto",
    marginBottom: "1em",
    display: "block",
  },

  buttonContainer: {
    // margin: "0% 8%",
    width: "100%",
    padding: "1em 0em",
    overflowX: "none",
    display: "flex",
    alignItems: "flex-end",
  },

  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    background: "white",
    marginBottom: "20px",
  },
  option: {
    // backgroundColor: "white",
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
    myTextfield: {
      "&  MuiOutlinedInput-adornedEnd": {
        paddingRight: 0,
      },
    },
  },
  myStateClass: {
    "&::before": {
      content: '"some content"',
      display: "block",
      height: 60,
      marginTop: -60,
    },
    "&::after": {
      content: '"some content"',
      display: "block",
      height: 60,
      marginTop: -60,
    },
  },
});

const BusinessConnect = connect(
  (state) => ({
    user: state.login.user,
    partner: state.partner.partner,
  }),
  { createnewClient, loadClient, unfetchclients, fetchBusinessCounts }
)(CreateBusiness);

export default withStyles(styles)(withRouter(BusinessConnect));
