import React, { Component } from "react";
import axios from "axios";
import { URL } from "../../config/config.js";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import billingsHelper from "../../helpers/billingsHelper.js";
import Header from "../../commons/Header/Header.js";

class BillingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commision: 0,
      amountPaid: 0,
      currency: "",
      planPurchased: "",
      createdAt: "",
      isLoading: true,
      assistantName: "",
      commisionPercent: 0,
    };
  }

  getBillingLog = () => {
    const partnerId = this.props.user.id;
    const _id = this.props.match.params.billingId;
    const { partnerPlanFamily, isPlansLoading, partner } = this.props;
    const { type } = partner || {};
    if (!isPlansLoading)
      axios
        .get(URL + `/api/${partnerId}/partnerbilling-lists/${_id}`)
        .then((response) => {
          const assistantName = response.data[1]?.assistantName;
          const modifiedBill =
            type !== "DIRECT"
              ? billingsHelper.mouldBill(response.data[0], partnerPlanFamily)
              : billingsHelper.mouldDirectBill(response.data[0]);
          const { commision, amountPaid, currency, planPurchased, createdAt } =
            modifiedBill;

          this.setState({
            commision,
            amountPaid,
            currency,
            planPurchased,
            createdAt,
            isLoading: false,
            assistantName: assistantName,
          });
        })
        .catch((error) => {
          console.error(error);
        });
  };
  //merge error
  componentDidMount() {
    // if (!this.props.location?.state?.log) this.getBillingLog();
    const log = this.props.location?.state;

    if (log) {
      const {
        commision,
        amountPaid,
        currency,
        planPurchased,
        createdAt,
        assistantName,
        commisionPercent,
      } = log;
      this.setState({
        commision,
        amountPaid,
        currency,
        planPurchased,
        createdAt,
        isLoading: false,
        assistantName,
        commisionPercent,
      });
    }
  }
  render() {
    const { classes } = this.props;
    const toggleDialog = () => {
      this.props.history.goBack();
    };

    const log = this.props.location?.state;

    const { currency } = log;

    return (
      <Box px={2} className={classes.dialogContainer}>
        <Header businessTitle={"Billing Details"} onBackPress={toggleDialog} />
        <>
          {" "}
          {this.state.isLoading ? (
            <Box my={4} display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <div className={classes.root}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={8}>
                  {this.state.assistantName && (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Project Name
                      </Typography>
                      <Typography variant="h5" paragraph noWrap={false}>
                        {this.state.assistantName}
                      </Typography>
                    </Box>
                  )}
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      COMMISION
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {`${
                        currency === "INR"
                          ? `₹ `
                          : currency === "USD"
                          ? `$ `
                          : ""
                      } ${this.state.commision / 100000}`}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      COMMISION PERCENTAGE
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.commisionPercent / 100000} {" %"}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      AMOUNT PAID
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {`${
                        currency === "INR"
                          ? `₹ `
                          : currency === "USD"
                          ? `$ `
                          : ""
                      } ${this.state.amountPaid / 100000}`}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      CURRENCY
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.currency}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      PLAN PURCHASED
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.planPurchased}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Created At
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {getFormattedDate(this.state.createdAt)}
                    </Typography>
                  </Box>

                  <Box my={8}></Box>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      </Box>
    );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
    "& pre": {
      whiteSpace: "pre-wrap",
      // whiteSpace: "-moz-pre-wrap",
      // whiteSpace: "-pre-wrap",
      // whiteSpace: "-o-pre-wrap",
      wordWrap: "break-word",
      wordBreak: "keep-all",
    },
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "rgb(237, 226, 252)",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
});
const billingconnect = connect(
  (state) => ({
    user: state.login.user,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    isPlansLoading: state.planFamilies.isLoading,
    partner: state.partner.partner,
  }),
  {}
)(BillingDetails);
export default withStyles(styles)(withRouter(billingconnect));
