import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser, loginWithToken } from "./loginstate";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  Link,
  TextField,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import Toast from "../../commons/Toast/Toast";
import leftImage from "../../static/9978094-new.png";
import logo from "../../static/AiSensy_Logo_Dark_PNG.png";
import WelcomeTextComponent from "./welcomeTextComponent";
import ResetPassword from "../forgotPassword/forgotPassword";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down(767)]: {
      padding: "3%",
    },
  },
  loginContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    margin: 0,
  },
  leftContainer: {
    backgroundColor: "#cbe7e6",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    height: "100vh",
    position: "relative",
    padding: "0 20px",
    boxSizing: "border-box",
    [theme.breakpoints.down(767)]: {
      display: "none",
    },
  },
  logoLink: {
    position: "absolute",
    top: 20,
    left: 20,
  },
  rightLogoLink: {
    width: "100%",
    position: "absolute",
    top: "-1.5%",
    left: "-5%",
    [theme.breakpoints.up(767)]: {
      display: "none",
    },
  },
  logo: {
    width: 250,
    [theme.breakpoints.down(767)]: {
      width: 150,
    },
  },
  leftImageContainer: {
    width: "100%",
    height: "auto",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    position: "relative",
  },
  leftImage: {
    width: "60%",
    maxWidth: "100%",
    height: "auto",
    marginTop: "5%",
    position: "relative",
  },
  rightContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    height: "100vh",
    position: "relative",
    padding: "20px",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0px",
    },
  },
  formContainer: {
    width: "100%",
    maxWidth: 400,
    padding: "0 0 50px",
    marginTop: "5%",
    height: "50vh",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  signUpLink: {
    position: "absolute",
    top: 20,
    right: 20,
    [theme.breakpoints.down(767)]: {
      position: "absolute",
      top: "9%",
      right: 20,
    },
  },
  welcomeText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  welcomeTextContainer: {
    width: "100%",
    textAlign: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(767)]: {
      marginBottom: theme.spacing(2),
    },
  },
  nextButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    width: "100%",
  },
  nextButton: {
    width: "100%",
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 2px",
    marginTop: "4px",
    background: "white",
    marginBottom: "10px",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
});


class newLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      status: "",
      statusMessage: "",
      showSnackbar: false,
      alertMsg: "",
      alertSeverity: "",
      forgotPassword: false,
    };
  }

  componentDidMount() {
    this.tokenLogin();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.error) {
      this.setAlert("Invalid login credentials!", "error");
    }
  }

  handleChange = (event, value, name) => {
    this.setState({ [name]: value });
  };

  closeSnackbar = () => {
    this.setState({ showSnackbar: false });
  };

  setAlert = (msg, severity) => {
    this.setState({
      alertMsg: msg,
      alertSeverity: severity,
      showSnackbar: true,
    });
  };

  tokenLogin() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    if (!token) return;

    this.props.loginWithToken(token);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    this.props.loginUser(username, password);
  };

  handleForgotPassword = () => {
    this.setState({ forgotPassword: true });
  };

  handleBackToLogin = () => {
    this.setState({ forgotPassword: false });
  };

  render() {
    const { classes, isAuthenticated } = this.props;
    const {
      username,
      password,
      showSnackbar,
      alertMsg,
      alertSeverity,
      forgotPassword,
    } = this.state;
    const isFormValid = username && password;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <div className={classes.root}>
        <Grid container className={classes.loginContainer}>
          <Grid item xs={12} sm={5} className={classes.leftContainer}>
            <Box alignItems="left">
              <Link href="/" className={classes.logoLink}>
                <img src={logo} alt="AiSensy Logo" className={classes.logo} />
              </Link>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.welcomeText}
                style={{
                  color: "#4A4A4A",
                  fontSize: "30px",
                  fontWeight: "bolder",
                  textAlign: "left",
                  marginLeft: "8%",
                }}
              >
                AiSensy Affiliate Dashboard
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                style={{ textAlign: "left", marginLeft: "8%" }}
              >
                Start with 20% commission and Grow More !!!
              </Typography>
              <Box className={classes.leftImageContainer}>
                <img
                  src={leftImage}
                  alt="Left Graphic"
                  className={classes.leftImage}
                />
              </Box>

              <Typography variant="h4" style={{ marginTop: "10px" }}>
                Trusted by 50000 + Brands
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} className={classes.rightContainer}>
            <Box className={classes.rightLogoLink}>
              <Link href="/">
                <img src={logo} alt="AiSensy Logo" className={classes.logo} />
              </Link>
            </Box>
            <Box className={classes.signUpLink}>
              <Typography variant="h5">
                Not a member yet?{" "}
                <Link variant="h5" href="/signup">
                  Sign up
                </Link>
              </Typography>
            </Box>

            <Box className={classes.formContainer}>
              <Box className={classes.welcomeTextContainer}>
                <WelcomeTextComponent />
              </Box>
              {forgotPassword ? (
                <ResetPassword handleBackToLogin={this.handleBackToLogin} />
              ) : (
                <Box
                  component="form"
                  onSubmit={this.handleSubmit}
                  className={classes.formContainer}
                >
                  <Autocomplete
                    disableClearable
                    freeSolo
                    options={[]}
                    value={username}
                    onInputChange={(event, value) =>
                      this.handleChange(event, value, "username")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Username or email"
                        variant="outlined"
                        className={classes.textFieldRootAutocomplete}
                      />
                    )}
                  />
                  <Autocomplete
                    disableClearable
                    freeSolo
                    options={[]}
                    value={password}
                    onInputChange={(event, value) =>
                      this.handleChange(event, value, "password")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Password"
                        type="password"
                        variant="outlined"
                        className={classes.textFieldRootAutocomplete}
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={`${classes.signInButton} ${
                      isFormValid ? "enabled" : "disabled"
                    }`}
                    onClick={this.handleSubmit}
                    disabled={!isFormValid}
                  >
                    Continue
                  </Button>
                  <Box className={classes.forgotPassword}>
                    <Link onClick={this.handleForgotPassword} variant="body1">
                      Forgot Password?
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Toast
          open={showSnackbar}
          closeSnackbar={this.closeSnackbar}
          severity={alertSeverity}
          msg={alertMsg}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  error: state.login.error,
});

export default connect(mapStateToProps, { loginUser, loginWithToken })(
  withStyles(styles)(newLogin)
);
