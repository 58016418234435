import axios from "axios";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../helpers/setAuthToken";
import { APIURL as url } from "../../config/config";

export const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {},
  error: null,
  errmsg: "",
};

export const START_LOGIN = "Login/START_LOGIN";
export const LOGIN_SUCCESS = "Login/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "Login/LOGIN_FAILURE";
export const RESET_ERROR = "Login/RESET_ERROR";
export const LOGIN_USER = "Login/LOGIN_USER";
export const SIGN_OUT_SUCCESS = "Login/SIGN_OUT_SUCCESS";

export const startLogin = () => ({
  type: START_LOGIN,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const loginFailure = (payload) => ({
  type: LOGIN_FAILURE,
  payload: payload,
});

export const resetError = (payload) => ({
  type: RESET_ERROR,
  payload: payload,
});

export const loginWithToken = (jwtToken) => async (dispatch) => {
  const data = await jwt_decode(jwtToken);
  localStorage.setItem("jwtToken", jwtToken);
  setAuthToken(jwtToken);
  dispatch(loginSuccess(data));
  window.location.reload();
};

export const loginUser = (userName, password, email) => (dispatch) => {
  dispatch(startLogin());
  dispatch(resetError());

  if (!!userName && !!password) {
    const user = {
      userName,
      password,
      email,
    };
    axios
      .post(url + "/auth/affiliate-login", user)
      .then(async (res) => {
        const { token } = res.data;
        localStorage.setItem("jwtToken", token);
        const decoded = await jwt_decode(token);
        setAuthToken(token);
        dispatch(loginSuccess(decoded));
      })
      .catch((err) => {
        console.error(err);
        dispatch(loginFailure({ errmsg: "Unable to setup connection" }));
      });
  } else {
    dispatch(loginFailure({ errmsg: "fields cant be empty" }));
  }
};

export const signOut = (_id) => (dispatch) => {
  localStorage.removeItem("jwtToken");
  dispatch(signOutSuccess());
  window.location.reload();
};

export const modifyUser = (token) => (dispatch) => {
  const decoded = jwt_decode(token);
  dispatch(loginSuccess(decoded));
};

export default function LoginReducer(state = initialState, { type, payload }) {
  switch (type) {
    case START_LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        error: null,
        user: { ...payload },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errmsg: payload.errmsg,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: false,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
