import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  IconButton,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import { Alert, Autocomplete } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import axios from "axios";
import { URL } from "../../config/config";
import AgentLimitDialog from "./AgentLimitDialog";
import dialCodes, { isoToName } from "../../config/dialCodes";
import DefaultsDialog from "./DefaultsDialog";
import { act } from "react-dom/test-utils";

class DefaultsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      openDialog: false,
      activeKey: null,
      whatsAppAbout:
        this.props.whatsAppAbout ||
        this.props.partner?.defaultConfigs?.whatsAppAbout ||
        null,
      isoCode:
        this.props.isoCode ||
        this.props.partner?.defaultConfigs?.isoCode ||
        this.props.partner?.isoCode ||
        null,
    };
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  toggleDialog = (key) => {
    const { openDialog } = this.state;
    if (openDialog) {
      this.setState({ openDialog: false, activeKey: null });
    } else {
      this.setState({ openDialog: true, activeKey: key });
    }
  };

  updateDefaultConfig = (key) => {
    axios
      .post(URL + `/api/set-default-configs`, {
        assistantId: this.props?.assistantId,
        [key]: this.state[key],
      })
      .then((_) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "Updated successfully",
          openDialog: false,
        });
      })
      .catch((error) => {
        const { message } = error?.response?.data?.error || error || {};
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: message || "Update failed!",
        });
      });
  };

  countrySelection = () => {
    const { classes } = this.props;
    const defaultValue = dialCodes.find(
      (e) =>
        e.isoCode ===
        (this.state.isoCode || this.props.partner?.isoCode || "IN")
    );
    return (
      <Autocomplete
        disableClearable
        options={dialCodes}
        defaultValue={defaultValue}
        onChange={(_, value) => {
          this.setState({
            isoCode: value.isoCode,
            country: value,
          });
        }}
        value={this.state.value}
        getOptionLabel={(option) => option.name}
        renderOption={(option) => (
          <React.Fragment>{option.name}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            classes={{ root: classes.codeTextFieldRoot }}
            InputProps={{ ...params.InputProps }}
          />
        )}
      />
    );
  };

  whatsAppAboutSelection = () => {
    return (
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Enter about"
        InputProps={{
          inputProps: { min: 1, max: 100 },
        }}
        name="whatsAppAbout"
        onChange={(e) => {
          this.setState({ whatsAppAbout: e.target.value });
        }}
        defaultValue={this.state.whatsAppAbout}
        style={{ width: "calc(100%)" }}
      />
    );
  };

  render() {
    const { classes, assistantId } = this.props;
    const { isoCode, whatsAppAbout, activeKey } = this.state;
    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        {!assistantId && <Header businessTitle={"Defaults"} />}
        <Box my={3} />
        {/* Layout */}
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <Grid container spacing={assistantId ? 0 : 2}>
              <Grid item xs={12} md={12}>
                <Box className={classes.container}>
                  <Typography variant="h4">Set Defaults</Typography>
                  <Box mt={3} />
                  <Box>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Country
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {isoToName[isoCode] ?? "Set country"}
                      </Grid>

                      <Grid item xs={12} md={4} className={classes.cells}>
                        <IconButton
                          onClick={() => this.toggleDialog("isoCode")}
                        >
                          <Edit />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2} />

                  <Grid container style={{ marginBottom: "30px" }}>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={classes.lastCell}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      About
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={classes.lastCell}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {whatsAppAbout ?? "Set WhatsApp About"}
                    </Grid>

                    <Grid item xs={12} md={4} className={classes.lastCell}>
                      <IconButton
                        onClick={() => this.toggleDialog("whatsAppAbout")}
                      >
                        <Edit />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Box mt={2} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box my={8} />
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
        {this.state.openDialog && (
          <DefaultsDialog
            openDialog={this.state.openDialog}
            closeDialog={this.toggleDialog}
            classes={classes}
            currentValue={activeKey === "isoCode" ? isoCode : whatsAppAbout}
            changeValueFn={this.updateDefaultConfig}
            title={
              activeKey === "isoCode" ? "Set Country" : "Set WhatsApp About"
            }
            activeKey={activeKey}
            CustomComponent={
              activeKey === "isoCode"
                ? this.countrySelection
                : this.whatsAppAboutSelection
            }
          />
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  detailContainer: {
    marginTop: "20px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerPlans: {
    marginTop: 24,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerButton: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "15px 15px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  cells: {
    padding: 24,
    color: "grey",
    borderBottom: "1px solid #ecebeb",
  },
  lastCell: {
    padding: 24,
    color: "grey",
  },
  plandialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

const connectDefaultsPage = connect((state) => ({
  partner: state.partner.partner,
}))(DefaultsPage);

export default withStyles(styles)(connectDefaultsPage);
