import axios from "axios";
import { APIURL } from "../config/config";

const initialState = {
  partner: {},
  isLoading: false,
  error: null,
};

const LOAD_START = "PARTNER/LOAD_START";
const LOAD_SUCCESS = "PARTNER/LOAD_SUCCESS";
const LOAD_FAILURE = "PARTNER/LOAD_FAILURE";

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = (partner) => ({
  type: LOAD_SUCCESS,
  payload: partner,
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const fetchPartnerDetails = () => (dispatch, getState) => {
  dispatch(loadStart());
  axios
    .post(`${APIURL}/affiliates/get-affiliate-profile`)
    .then((response) => {
      const affiliateData = response.data.data;
      dispatch(loadSuccess({ affiliateData }));
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export default function partnerReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        partner: { ...payload.affiliateData },
        isLoading: false,
        error: null,
      };

    case LOAD_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
