import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "../../commons/ErrorPage/ErrorPage";
import TemplateDetails from "./TemplateDetails";
import TemplateMsgPage from "./TemplateMsgPage";

class TemplateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateId: null,
    };
  }

  setTemplateId = (id) => this.setState({ templateId: id });

  render() {
    return !this.state.templateId ? (
      <TemplateMsgPage
        assistants={!!this.props.isWhatsappVerified}
        setTemplateId={this.setTemplateId}
      />
    ) : (
      <TemplateDetails
        templateId={this.state.templateId}
        setTemplateId={this.setTemplateId}
      />
    );
  }
}

export default TemplateRoute;
