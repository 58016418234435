import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import {
  FiberManualRecord,
  FileCopyOutlined,
  FilterNone,
  VisibilityOutlined,
  Edit,
  Clear,
  Flag,
} from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import { connect } from "react-redux";
import { getUnavailablePlanFamilies } from "../../store/planFamilyState";
import { toHaveStyle } from "@testing-library/jest-dom";
import { fetchWccPlans } from "../../store/wccPlanState";
import { URL } from "../../config/config";
import axios from "axios";
import plansHelper from "../../helpers/plansHelper";
class PlanFamilyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      isLoading: true,
      openPlanDialog: false,
      planFamily: {},
      changePlan: "",
      slectedPlans: "",
      openSelectPlanDialog: false,
      openWccDialog: false,
      changePlanFamily: "",
      wccPlanFamily: {},
      changeWccPlanFamily: "",
      planSubmitLoader: false,
      defaultWccPlan: "",
      defaultPlanName: "",
      defaultPlanFamily: "",
    };
  }

  componentDidMount() {
    plansHelper.setPlanFamilies();
    plansHelper.setWccPlans();
    this.setState({
      isLoading: false,
    });
  }

  planSubmit = () => {
    this.setState({
      planSubmitLoader: true,
    });
    const firstPlan =
      this.state.planFamily &&
      this.state.planFamily[Object.keys(this.state.planFamily)[0]]?.plans;
    const renderFirstPlan = firstPlan && Object.keys(firstPlan)[0];

    const updatePlanFamily = this.state.changePlanFamily
      ? this.state.changePlanFamily
      : this.state.defaultPlanFamily
      ? this.state.defaultPlanFamily
      : Object.keys(this.state.planFamily)[0];

    const updateDefualtPlan = this.state.changePlan
      ? this.state.changePlan
      : this.state.defaultPlanName
      ? this.state.defaultPlanName
      : renderFirstPlan;

    axios
      .patch(URL + `/api/update-partner-config`, {
        defaultPlanFamily: updatePlanFamily,
        defaultPlanName: updateDefualtPlan,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "Plan family updated successfully",
          planSubmitLoader: false,
          openPlanDialog: false,
          defaultPlanFamily: updatePlanFamily,
          defaultPlanName: updateDefualtPlan,
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: "Plan family update failed",
          planSubmitLoader: false,
        });
      });
  };

  wccPlanSubmit = () => {
    this.setState({
      planSubmitLoader: true,
    });

    const updateDefaultWcc = this.state.changeWccPlanFamily
      ? this.state.changeWccPlanFamily
      : this.state.defaultWccPlan
      ? this.state.defaultWccPlan
      : Object.keys(this.state?.wccPlanFamily)[0];

    axios
      .patch(URL + `/api/update-partner-config`, {
        defaultWccPlan: updateDefaultWcc,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "WCC Plan family updated successfully",
          planSubmitLoader: false,
          openPlanDialog: false,
          defaultWccPlan: updateDefaultWcc,
        });
        this.props.updateWccFamily({ defaultWccPlan: updateDefaultWcc });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: "WCC Plan family update failed",
          planSubmitLoader: false,
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState(
        {
          planFamily: { ...this.props?.planFamilies },
          defaultPlanFamily: this.props?.partner?.defaultPlanFamily
            ? this.props?.partner?.defaultPlanFamily
            : Object.keys(this.props?.planFamilies)[0],
          defaultPlanName: this.props?.partner?.defaultPlanName,
          defaultWccPlan: this.props?.partner?.defaultWccPlan,
          wccPlanFamily: { ...this.props.wccPlans },
        },
        () => {
          this.setState({
            plans:
              this.props?.planFamilies?.[this?.state?.defaultPlanFamily]?.[
                "plans"
              ],
          });
        }
      );
    }
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  planDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openPlanDialog: !this.state.openPlanDialog });
  };

  SelectWccDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openWccDialog: !this.state.openWccDialog });
  };

  selectPlanDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openSelectPlanDialog: !this.state.openSelectPlanDialog });
  };

  render() {
    const { classes, match } = this.props;
    const {
      planFamily,
      changePlanFamily,
      changePlan,
      plans,
      changeWccPlanFamily,
      defaultWccPlan,
      wccPlanFamily,
      defaultPlanFamily,
      defaultPlanName,
    } = this.state;
    const firstPlan =
      planFamily && planFamily[Object.keys(planFamily)[0]]?.plans;

    const renderFirstPlan = firstPlan && Object.keys(firstPlan)[0];

    const renderPlanName =
      planFamily?.[defaultPlanFamily]?.["plans"]?.[defaultPlanName]?.["name"];

    return this.state.isLoading ? (
      <CircularProgress />
    ) : (
      <div className={classes.root}>
        <Header businessTitle={"Configure Plan Family"} />
        <Box my={3} />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box className={classes.container}>
                  <Typography variant="h4">
                    Select Default Subscription Plan Family
                  </Typography>
                  <Box mt={3} />
                  <Box>
                    <Grid container style={{ marginBottom: "30px" }}>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Active Plan Family
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {planFamily?.[defaultPlanFamily]?.["name"] ??
                          "Select a plan family"}
                      </Grid>
                      <Grid item xs={12} md={4} className={classes.cells}>
                        <IconButton onClick={this.planDialog}>
                          <Edit />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container style={{ marginBottom: "30px" }}>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Active Plan
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {renderPlanName ?? "Select a plan"}
                      </Grid>

                      <Grid item xs={12} md={4} className={classes.cells}>
                        <IconButton onClick={this.planDialog}>
                          <Edit />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2} />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box className={classes.container}>
                  <Typography variant="h4">
                    Select Default WhatsApp Conversation Charges (WCC) Family
                  </Typography>
                  <Box mt={3} />
                  <Box>
                    <Grid container style={{ marginBottom: "30px" }}>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Active Plan
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {wccPlanFamily[defaultWccPlan]?.name ??
                          "Select a plan first"}
                      </Grid>
                      <Grid item xs={12} md={4} className={classes.cells}>
                        <IconButton onClick={this.SelectWccDialog}>
                          <Edit />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box my={8} />

        {this.state.openPlanDialog && (
          <Dialog
            open={this.state.openPlanDialog}
            maxWidth="xs"
            onClose={this.planDialog}
            aria-labelledby="form-dialog-title"
            className={classes.plandialogContainer}
          >
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              style={{
                height: 30,
                marginBottom: 10,
                marginTop: 4,
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 16,
                paddingRight: 8,
              }}
            >
              <Typography variant="h4">Select Plan Family</Typography>
              <IconButton onClick={this.planDialog}>
                <Clear />
              </IconButton>
            </Grid>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    paddingRight: "8px",
                    marginTop: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
                    <Typography>Plan Family</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      fullWidth
                      options={Object.keys(planFamily).map((option) => option)}
                      getOptionLabel={(option) => {
                        return planFamily[option].name;
                      }}
                      onChange={(e, value, reason) => {
                        if (reason === "select-option") {
                          this.setState({
                            changePlanFamily: value,
                            plans: planFamily[value]?.plans,
                          });
                        }
                      }}
                      defaultValue={defaultPlanFamily}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select a message"
                          name="campaignName"
                        />
                      )}
                      style={{ width: "calc(100%)", paddingTop: "0px" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
                    <Typography>Plans</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      fullWidth
                      options={
                        plans && Object.keys(plans).map((option) => option)
                      }
                      getOptionLabel={(option) => {
                        return plans && plans[option]?.name;
                      }}
                      onChange={(e, value, reason) => {
                        if (reason === "select-option") {
                          this.setState({ changePlan: value });
                        }
                      }}
                      defaultValue={
                        defaultPlanName ? defaultPlanName : renderFirstPlan
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select a message"
                          name="campaignName"
                        />
                      )}
                      style={{ width: "calc(100%)", paddingTop: "0px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                item
                container
                xs={12}
                style={{
                  alignItems: "flex-end",
                  justifyContent: "end",
                  paddingRight: "15px",
                  marginBottom: "8px",
                }}
              >
                <Button
                  // color="primary"
                  variant="outlined"
                  onClick={() => (
                    this.planDialog(),
                    this.setState({
                      planSubmitLoader: false,
                    })
                  )}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.planSubmit}
                  disabled={this.state.planSubmitLoader}
                  endIcon={
                    this.state.planSubmitLoader ? (
                      <CircularProgress size={20} />
                    ) : (
                      ""
                    )
                  }
                >
                  Change Plan
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
        {/* {this.state.openSelectPlanDialog && (
          <Dialog
            open={this.state.openSelectPlanDialog}
            maxWidth="xs"
            onClose={this.selectPlanDialog}
            aria-labelledby="form-dialog-title"
            className={classes.plandialogContainer}
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  xs={12}
                  style={{ height: 30, marginBottom: 10 }}
                >
                  <IconButton onClick={this.selectPlanDialog}>
                    <Clear />
                  </IconButton>
                </Grid>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    paddingLeft: "10px",
                    marginTop: "15px",
                  }}
                >
                  <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                    <Typography>PLANS</Typography>
                  </Grid>
                  <Grid item xs={9} style={{ marginBottom: "20px" }}>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      options={Object.keys(plans).map((option) => option)}
                      getOptionLabel={(option) => {
                        return plans[option]?.name;
                      }}
                      onChange={(e, value, reason) => {
                        if (reason === "select-option") {
                          this.setState({ changePlan: value });
                        }
                      }}
                      defaultValue={changePlan ?? renderFirstPlan}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select a message"
                          name="campaignName"
                        />
                      )}
                      style={{ width: "calc(100%)", paddingTop: "0px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item container justifyContent="flex-end" xs={12}>
                <Button
                  // color="primary"
                  variant="outlined"
                  onClick={this.selectPlanDialog}
                  style={{ margin: 16 }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.planSubmit}
                  style={{ margin: 16 }}
                  disabled={this.state.planSubmitLoader}
                  endIcon={
                    this.state.planSubmitLoader ? (
                      <CircularProgress size={20} />
                    ) : (
                      ""
                    )
                  }
                >
                  Change Plan
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )} */}
        {this.state.openWccDialog && (
          <Dialog
            open={this.state.openWccDialog}
            maxWidth="xs"
            onClose={this.SelectWccDialog}
            aria-labelledby="form-dialog-title"
            className={classes.plandialogContainer}
          >
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              style={{
                height: 30,
                marginBottom: 10,
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 16,
                paddingRight: 8,
              }}
            >
              <Typography variant="h4">Select Wcc Plan Family</Typography>
              <IconButton onClick={this.SelectWccDialog}>
                <Clear />
              </IconButton>
            </Grid>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    paddingRight: "8px",
                    marginTop: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                    <Typography>Plans</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      fullWidth
                      options={Object.keys(wccPlanFamily).map(
                        (option) => option
                      )}
                      getOptionLabel={(option) => {
                        return wccPlanFamily[option]?.name;
                      }}
                      onChange={(e, value, reason) => {
                        if (reason === "select-option") {
                          this.setState({ changeWccPlanFamily: value });
                        }
                      }}
                      defaultValue={
                        defaultWccPlan ?? Object.keys(wccPlanFamily)[0]
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select a message"
                          name="campaignName"
                        />
                      )}
                      style={{ width: "calc(100%)", paddingTop: "0px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                item
                container
                xs={12}
                style={{
                  alignItems: "flex-end",
                  justifyContent: "end",
                  paddingRight: "15px",
                  marginBottom: "8px",
                }}
              >
                <Button
                  // color="primary"
                  variant="outlined"
                  onClick={this.SelectWccDialog}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.wccPlanSubmit}
                  disabled={this.state.planSubmitLoader}
                  endIcon={
                    this.state.planSubmitLoader ? (
                      <CircularProgress size={20} />
                    ) : (
                      ""
                    )
                  }
                >
                  Change Plan
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )}

        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  cells: {
    padding: 24,
    color: "grey",
    borderBottom: "1px solid #ecebeb",
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  detailContainer: {
    marginTop: "20px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerPlans: {
    marginTop: 24,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerButton: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "15px 15px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  plandialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

const connectPlanFamilyPage = connect(
  (state) => ({
    partner: state.partner.partner,
    planFamilies: state.planFamilies.allPlanFamilies,
    wccPlans: state.wccPlans.allWccPlans,
  }),
  {
    fetchWccPlans,
  }
)(PlanFamilyPage);

export default withStyles(styles)(connectPlanFamilyPage);
