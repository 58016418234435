import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  DialogContent,
  Dialog,
  DialogActions,
  CircularProgress,
  withStyles,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Clear } from "@material-ui/icons";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector-material-ui-new";
import { countries } from "../countries/countries.js";
import { DialogTitle } from "@material-ui/core";

function CreateProjectDialog(props) {
  const {
    showDialog,
    closeDialog,
    classes,
    allBusiness,
    selectBusiness,
    setProjectName,
    createBusiness,
    onSubmit,
    disableSubmit,
    reqId4Business,
    planFamilies,
    defaultPlanFamily,
    defaultPlanName,
    wccPlans,
    defaultWccPlan,
    type,
  } = props;
  const states = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Foreign Country",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Other Territory",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  // const [country, setCountry] = useState("");
  const [billingAddress, setBillingAddress] = useState({
    country: "",
    street: "",
    state: "",
    city: "",
    zip: "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    country: "",
    street: "",
    state: "",
    city: "",
    zip: "",
  });
  const [gstNumber, setGstNumber] = useState("");
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const copyBillingAddress = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setShippingAddress(billingAddress);
    }
  };

  const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  const defualtBusiness = Object.values(allBusiness).filter((item) => {
    if (item._id === reqId4Business) {
      return item;
    }
  })[0];
  console.log(Object.keys(planFamilies)[0]);
  // <--- Set Plan Family and Plan Name --->
  let [newFamilyId, newPlanId] = [defaultPlanFamily, defaultPlanName];
  if (type !== "DIRECT" && (!defaultPlanFamily || !defaultPlanName)) {
    newFamilyId = Object.keys(planFamilies)[0];
    const plans = planFamilies[newFamilyId].plans;
    newPlanId = Object.keys(plans)[0];
  }
  const [plan, setActivePlan] = useState({
    newFamilyId,
    newPlanId,
  });
  console.log(plan);
  const plans = type !== "DIRECT" && planFamilies[plan.newFamilyId].plans;
  // <--- Set Wcc Plan --->
  const [wccPlan, setWccPlan] = useState(
    type !== "DIRECT" && (defaultWccPlan ?? Object.keys(wccPlans)[0])
  );
  const [name, setName] = useState(selectBusiness ?? "");
  console.log(props.partner);
  return (
    <Dialog
      open={showDialog}
      maxWidth="xs"
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className={classes.projectdialogContainer}
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          xs={12}
          style={{ height: 30, marginBottom: 24 }}
        >
          <Typography variant="h3">Create project</Typography>
          <IconButton onClick={closeDialog}>
            <Clear />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Header */}

          {/* Body */}
          <Grid
            container
            style={{
              alignItems: "center",
              padding: "0 16px",
              marginTop: "16px",
              width: "100%",
            }}
          >
            {/* <Grid item xs={12} sm={3} style={{ marginBottom: "16px" }}>
              <Typography>Business</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <Autocomplete
                disableClearable
                fullWidth
                autoHighlight
                options={
                  allBusiness &&
                  Object.values(allBusiness).map((item) => {
                    return (
                      (item.company || item.displayName) + " - " + item.name
                    );
                  })
                }
                // value={this.state.selectBusiness}
                onChange={selectBusiness}
                defaultValue={
                  defualtBusiness &&
                  (defualtBusiness.company || defualtBusiness.displayName) +
                    " - " +
                    defualtBusiness.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    InputProps={{ ...params.InputProps }}
                    placeholder="Select a business"
                    name="campaignName"
                  />
                )}
                style={{ width: "calc(100%)", paddingTop: "0px" }}
              />
            </Grid> */}

            {/* Body */}
            <Grid container>
              <Grid
                container
                style={{
                  alignItems: "center",
                  padding: "0 16px",
                  marginTop: "16px",
                  width: "100%",
                }}
              >
                <Grid item xs={12} sm={3} style={{ marginBottom: "16px" }}>
                  <Typography>Business</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    fullWidth
                    autoHighlight
                    options={
                      allBusiness &&
                      Object.values(allBusiness).map((item) => {
                        return item.company + " - " + item.name;
                      })
                    }
                    // value={this.state.selectBusiness}
                    onChange={selectBusiness}
                    defaultValue={
                      defualtBusiness &&
                      defualtBusiness.company + " - " + defualtBusiness.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select a business"
                        name="campaignName"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>

                <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
                  <Typography>Project Name</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <TextField
                    name="project name"
                    placeholder="Enter your project name here"
                    className={classes.textField}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    disabled={
                      allBusiness && Object.values(allBusiness).length === 0
                    }
                  />
                </Grid>
                {type !== "DIRECT" && (
                  <>
                    <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
                      <Typography>Plan Family</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Autocomplete
                        disableClearable
                        autoHighlight
                        fullWidth
                        options={Object.keys(planFamilies).map(
                          (option) => option
                        )}
                        getOptionLabel={(option) => {
                          return planFamilies && planFamilies[option].name;
                        }}
                        onChange={(e, value, reason) => {
                          if (reason === "select-option") {
                            setActivePlan((prev) => ({
                              ...prev,
                              newFamilyId: value,
                            }));
                          }
                        }}
                        defaultValue={plan.newFamilyId}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{ ...params.InputProps }}
                            placeholder="Select a plan family"
                            name="campaignName"
                          />
                        )}
                        style={{ width: "calc(100%)", paddingTop: "0px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
                      <Typography>Plan</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Autocomplete
                        disableClearable
                        autoHighlight
                        fullWidth
                        options={
                          plans && Object.keys(plans).map((option) => option)
                        }
                        getOptionLabel={(option) => {
                          return plans && plans[option]?.name;
                        }}
                        onChange={(e, value, reason) => {
                          if (reason === "select-option") {
                            setActivePlan((prev) => ({
                              ...prev,
                              newPlanId: value,
                            }));
                          }
                        }}
                        defaultValue={plan.newPlanId}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{ ...params.InputProps }}
                            placeholder="Select a plan"
                            name="campaignName"
                          />
                        )}
                        style={{ width: "calc(100%)", paddingTop: "0px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography>Wcc Plan Family</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Autocomplete
                        disableClearable
                        autoHighlight
                        fullWidth
                        options={Object.keys(wccPlans).map((option) => option)}
                        getOptionLabel={(option) => {
                          return wccPlans[option]?.name;
                        }}
                        onChange={(e, value, reason) => {
                          if (reason === "select-option") {
                            setWccPlan(value);
                          }
                        }}
                        defaultValue={wccPlan}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{ ...params.InputProps }}
                            placeholder="Select a wcc plan"
                            name="wccName"
                          />
                        )}
                        style={{ width: "calc(100%)", paddingTop: "0px" }}
                      />
                    </Grid>
                  </>
                )}
                {props?.partner?.isZohoEnabled === true && (
                  <>
                    <Grid item xs={12} md={6} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Enter GST Number
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        placeholder="Enter your GST number"
                        type="text"
                        style={{ width: "100%" }}
                        value={gstNumber}
                        onChange={(event) => setGstNumber(event.target.value)}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginBottom: "8px" }}>
                      <Typography variant="h3" gutterBottom>
                        Billing Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Select Country
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Autocomplete
                        disableClearable
                        autoHighlight
                        fullWidth
                        options={countries}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, value, reason) => {
                          console.log(e, value, reason);
                          setBillingAddress((prev) => ({
                            ...prev,
                            country: value.label,
                          }));
                        }}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{countryToFlag(option.code)}</span>
                            {option.label} ({option.code}) +{option.phone}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{ ...params.InputProps }}
                            placeholder="Choose a country"
                          />
                        )}
                        style={{ width: "calc(100%)", paddingTop: "0px" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Select state
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      {billingAddress?.country === "India" && (
                        <Autocomplete
                          disableClearable
                          autoHighlight
                          fullWidth
                          options={states.map((option) => option)}
                          onChange={(e, value, reason) => {
                            setBillingAddress((prev) => ({
                              ...prev,
                              state: value,
                            }));
                          }}
                          value={billingAddress?.state}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="outlined"
                              placeholder="Choose a state"
                              InputProps={{ ...params.InputProps }}
                            />
                          )}
                          style={{ width: "calc(100%)", paddingTop: "0px" }}
                        />
                      )}

                      {billingAddress?.country !== "India" && (
                        <TextField
                          id="State"
                          label="Enter State"
                          variant="outlined"
                          type="text"
                          name="state"
                          value={billingAddress?.state}
                          onChange={(e) =>
                            setBillingAddress((prev) => ({
                              ...prev,
                              state: e.target.value,
                            }))
                          }
                          className={classes.textFieldRoot}
                          // onBlur={() => {
                          //   trackEvent({
                          //     event: !isOtpVerified
                          //       ? "signup_company_name_filled"
                          //       : "new_signup_company_name_filled",
                          //     properties: {
                          //       company,
                          //     },
                          //   });
                          // }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Enter Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        placeholder="Enter your address"
                        type="text"
                        style={{ width: "100%" }}
                        value={billingAddress?.street}
                        onChange={(event) =>
                          setBillingAddress({
                            ...billingAddress,
                            street: event.target.value,
                          })
                        }
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Enter City
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        placeholder="city"
                        type="text"
                        style={{ width: "100%" }}
                        value={billingAddress?.city}
                        onChange={(event) =>
                          setBillingAddress({
                            ...billingAddress,
                            city: event.target.value,
                          })
                        }
                        disabled={isLoading}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Enter Zip
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        placeholder="zip code"
                        type={"number"}
                        style={{ width: "100%" }}
                        value={billingAddress?.zip}
                        onChange={(event) =>
                          setBillingAddress({
                            ...billingAddress,
                            zip: event.target.value,
                          })
                        }
                        disabled={isLoading}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" style={{ marginTop: 24 }}>
                        Shipping Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginBottom: "20px" }}>
                      <Grid
                        md={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="h5" gutterBottom>
                          Use shipping address same as billing address
                        </Typography>
                        <Checkbox
                          checked={checked}
                          color="primary"
                          onChange={(e) => copyBillingAddress(e)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </Grid>
                    </Grid>

                    {!checked && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Select Country
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <Autocomplete
                            disableClearable
                            autoHighlight
                            fullWidth
                            options={countries}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, value, reason) => {
                              console.log(e, value, reason);
                              setShippingAddress({
                                ...shippingAddress,
                                country: value.label,
                              });
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <span>{this.countryToFlag(option.code)}</span>
                                {option.label} ({option.code}) +{option.phone}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputProps={{ ...params.InputProps }}
                                placeholder="Choose a country"
                              />
                            )}
                            style={{ width: "calc(100%)", paddingTop: "0px" }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Select state
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          {shippingAddress?.country === "India" && (
                            <Autocomplete
                              disableClearable
                              autoHighlight
                              fullWidth
                              options={states.map((option) => option)}
                              onChange={(e, value, reason) => {
                                setShippingAddress((prev) => ({
                                  ...prev,
                                  state: value,
                                }));
                              }}
                              value={billingAddress?.state}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Choose a state"
                                  InputProps={{ ...params.InputProps }}
                                />
                              )}
                              style={{ width: "calc(100%)", paddingTop: "0px" }}
                            />
                          )}

                          {billingAddress?.country !== "India" && (
                            <TextField
                              id="State"
                              label="Enter State"
                              variant="outlined"
                              type="text"
                              name="state"
                              value={billingAddress?.state}
                              onChange={(e) =>
                                setShippingAddress((prev) => ({
                                  ...prev,
                                  state: e.target.value,
                                }))
                              }
                              className={classes.textFieldRoot}
                              // onBlur={() => {
                              //   trackEvent({
                              //     event: !isOtpVerified
                              //       ? "signup_company_name_filled"
                              //       : "new_signup_company_name_filled",
                              //     properties: {
                              //       company,
                              //     },
                              //   });
                              // }}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Enter Address
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <TextField
                            placeholder="Enter your address"
                            type="text"
                            style={{ width: "100%" }}
                            value={billingAddress?.street}
                            onChange={(event) =>
                              setShippingAddress({
                                ...shippingAddress,
                                street: event.target.value,
                              })
                            }
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Enter City
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <TextField
                            placeholder="city"
                            type="text"
                            style={{ width: "100%" }}
                            value={billingAddress?.city}
                            onChange={(event) =>
                              setShippingAddress({
                                ...shippingAddress,
                                city: event.target.value,
                              })
                            }
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Enter Zip
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <TextField
                            placeholder="zip code"
                            type={"number"}
                            style={{ width: "100%" }}
                            value={billingAddress?.zip}
                            onChange={(event) =>
                              setShippingAddress({
                                ...shippingAddress,
                                zip: event.target.value,
                              })
                            }
                            disabled={isLoading}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Buttons here */}
      </DialogContent>
      <DialogActions
        style={{ paddingTop: 16, paddingBottom: 16, paddingRight: 32 }}
      >
        <Grid item container justifyContent="flex-end" xs={12}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={createBusiness}
              style={{ color: "black" }}
            >
              Create New Business
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: 12 }}
              onClick={() =>
                onSubmit({
                  name,
                  planFamilyId: plan.newFamilyId,
                  planName: plan.newPlanId,
                  wccId: wccPlan,
                  gstNumber,
                  shippingAddress,
                  billingAddress,
                  checked,
                })
              }
              disabled={disableSubmit}
              endIcon={disableSubmit && <CircularProgress size={20} />}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme) => ({
  textField: {
    width: "100%",
    height: 42,
  },
  dialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "600px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
  projectdialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

export default withStyles(styles)(CreateProjectDialog);
