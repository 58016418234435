// Dashboard.js
import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  Chip,
  Tooltip,
  Snackbar,
  IconButton,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
} from "@material-ui/core";

import { connect } from "react-redux";
import CRMTraining from "../../static/onlineCall.png";
import countriesTimezones from "../../config/countriesTimezones.json";
import { fetchPartnerDetails } from "../../store/partnerState";
import Header from "../../commons/Header/Header";
import { Clear, Close, ErrorOutline } from "@material-ui/icons";
// import AffiliateAnalytics from "../Assistants/WccAnalytics/WccAnalytics";
import axios from "axios";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TrainingSessionCard from "./TrainingSessionCard";
import { Alert, Autocomplete } from "@material-ui/lab";
import { timeZones } from "../../config/timezone";
import { isoToName } from "../../config/dialCodes";
import { PieChart, Pie, Cell, Legend } from "recharts";
import TrainingSessionCardWithHelpCenter from "./TrainingSessionCardWithHelpCenter";
const { APIURL } = require("../../config/config");

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dataInitialized: false,
      totalClick: 0,
      totalLeadsGenerated: 0,
      totalConversions: 0,
      conversionRate: 0,
      termsDialogOpen: true,
      additonalDetailsDialog: true,
      selectedLink: "",
      snackbarOpen: false,
      uniqueClicks: 0,
      analyticsDialog: false,
      data: [
        { name: "Total Clicks", value: 400 },
        { name: "Unique Clicks", value: 300 },
      ],
      step: 1,
      formValues: {
        address: "",
        country: "",
        industry: [],
        currency: "",
        companySize: "",
        timezone: "",
        state: "",
        city: "",
        metaAds: "",
        budget: "",
        postalCode: "",
        whatsAppAds: "",
        whatsAppAdsBudget: "",
        objective: "",
        averageBudget: "",
        designation: "",
        ecosystem: [],
        websiteUrl: "",
        type: "",
        customerBase: "",
      },
      postalCodeError: false,
      availableStates: [],
      availableTimezones: [],
    };
  }

  componentDidMount() {
    this.props.fetchPartnerDetails();
    this.fetchDashboardData();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.dataInitialized &&
      this.props.partnerDetail &&
      this.props.partnerDetail !== prevProps.partnerDetail
    ) {
      this.fetchSavedData();
      this.setState({ dataInitialized: true, isLoading: false });

      // Check if affiliateUrl is empty and call generateAffiliateLinks if true
      if (this.props.partnerDetail.AffiliateUrls.length < 1) {
        this.generateAffiliateLinks();
      }
    }
  }

  handleAnalyticsDiaglog = () => {
    this.setState({ analyticsDialog: true });
    axios
      .post(APIURL + `/affiliates/get-affiliate-links?sort=desc`, {
        affiliateId: this.props.partnerDetail.id,
      })
      .then((response) => {
        const businessData = response.data.business;
        const data = businessData.map((item) => ({
          name: item.linkName,
          value: item.totalClicks + item.uniqueClicks,
        }));
        const totalClicks = businessData.reduce(
          (acc, item) => acc + item.totalClicks,
          0
        );
        const uniqueClicks = businessData.reduce(
          (acc, item) => acc + item.uniqueClicks,
          0
        );
        this.setState({ data, totalClick: totalClicks, uniqueClicks }); // Set uniqueClicks
      });
  };

  handleAnalyticsDialogClose = () => {
    this.setState({ analyticsDialog: false });
  };

  fetchSavedData = () => {
    const { partnerDetail } = this.props;
    if (partnerDetail) {
      const selectedCountry = countryData.find(
        (country) => country.code === partnerDetail.country
      );
      const availableStates = selectedCountry?.states || [];
      const availableTimezones = selectedCountry
        ? [selectedCountry.timezone]
        : [];
      this.setState({
        formValues: {
          designation: partnerDetail.designation || "",
          websiteUrl: partnerDetail.websiteUrl || "",
          type: partnerDetail.businessType || "",
          companySize: partnerDetail.companySize || "",
          customerBase: partnerDetail.customerBase || "",
          ecosystem: partnerDetail.ecosystem || [],
          metaAds: partnerDetail.metaAds || "",
          objective: partnerDetail.objective || "",
          industry: partnerDetail.industry || [],
          whatsAppAds: partnerDetail.whatsAppAds || "",
          budget: partnerDetail.budget || "",
          averageBudget: partnerDetail.averageBudget || "",
          whatsAppAdsBudget: partnerDetail.whatsAppAdsBudget || "",
          address: partnerDetail.address || "",
          country: partnerDetail.country || "",
          state: partnerDetail.state || "",
          city: partnerDetail.city || "",
          postalCode: partnerDetail.postalCode || "",
          timezone: partnerDetail.timezone || "",
          totalCommisionPaidINR: partnerDetail?.totalCommisionPaidINR || " ",
          totalCommisionPaidUSD: partnerDetail?.totalCommisionPaidUSD || "",
        },
        availableStates,
      });
    }
  };
  handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      this.setState({
        snackbarOpen: true,
        alertMsg: "Link Copied Successfully",
      });
    });
  };

  fetchDashboardData = () => {
    axios
      .get(APIURL + "/affiliates/dashboard-analytics")
      .then((response) => {
        const { data } = response;
        const conversionRate = (
          (data?.conversion / data?.leadsGenerated) * 100 || 0
        ).toFixed(2);
        this.setState({
          totalClick: data.totalClicks,
          totalLeadsGenerated: data.leadsGenerated,
          totalConversions: data.conversion,
          conversionRate: conversionRate,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onOnboardingCallClick = () => {
    window.open("https://calendly.com/d/33t-6fj-rsq", "_blank");
  };

  onPlatformDemoClick = () => {
    window.open("https://calendly.com/d/3k8-k9q-9r3", "_blank");
  };

  onButtonClick = () => {
    window.open("https://help.aisensy.com/en/", "_blank");
  };

  handleCloseTerms = () => {
    this.setState({ termsDialogOpen: false });
  };

  handleCloseAdditonalDetails = () => {
    this.setState({ additonalDetailsDialog: false });
  };

  handleOpenTermsDialog = () => {
    this.setState({ termsDialogOpen: true });
  };

  handleAcceptTerms = () => {
    axios
      .post(APIURL + "/affiliates/update-affiliate", {})
      .then((response) => {
        this.setState({ termsDialogOpen: false });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Function to generate affiliate links
  generateAffiliateLinks = async () => {
    try {
      // Post request for the first link
      const response1 = await axios.post(
        APIURL + "/affiliates/create-new-affiliate-link",
        {
          linkTitle: "link1",
          domain: "app.aisensy.com",
        }
      );

      // Post request for the second link
      const response2 = await axios.post(
        APIURL + "/affiliates/create-new-affiliate-link",
        {
          linkTitle: "link2",
          domain: "m.aisensy.com",
        }
      );

      // Force refresh the page after both links are created
      window.location.reload();
    } catch (error) {
      console.error("Error creating affiliate links:", error);
    }
  };

  handleProfileCompletion = () => {
    axios
      .post(APIURL + "/affiliates/update-affiliate", {
        additionalDetails: this.state.formValues,
        currentStep: this.state.step,
        allStepsCompleted: true,
      })
      .then((response) => {
        this.setState({ additonalDetailsDialog: false });

        // Call the function to generate affiliate links
        // this.generateAffiliateLinks();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleInputChange = (field, value) => {
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        [field]: value,
      },
      postalCodeError: field === "postalCode" && value.length > 7,
    }));
  };

  handleCountryChange = (e, value) => {
    const selectedCountry = countryData.find(
      (country) => country.code === value?.code
    );

    this.setState({
      formValues: {
        ...this.state.formValues,
        country: value?.code || "",
        state: "",
        timezone: selectedCountry?.timezone || "",
      },
      availableStates: selectedCountry?.states || [],
      availableTimezones: selectedCountry ? [selectedCountry.timezone] : [],
    });
  };

  nextStep = () => {
    if (this.allFieldsFilled()) {
      this.setState(
        (prevState) => ({
          step: prevState.step + 1,
        }),
        () => {
          // Save the current form state and step
          this.saveFormData();
        }
      );
    }
  };

  prevStep = () => {
    this.setState(
      (prevState) => ({
        step: prevState.step - 1,
      }),
      () => {
        // Save the current form state and step
        this.saveFormData();
      }
    );
  };

  saveFormData = () => {
    const { formValues, step } = this.state;
    axios
      .post(APIURL + "/affiliates/update-affiliate", {
        additionalDetails: formValues,
        currentStep: step,
      })
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error saving form data:", error);
      });
  };

  allFieldsFilled = () => {
    const { formValues, step } = this.state;

    if (step === 1) {
      return (
        formValues.designation &&
        formValues.customerBase &&
        formValues.ecosystem.length > 0 &&
        (formValues.type !== "Business" || formValues.companySize)
      );
    } else if (step === 2) {
      return (
        formValues.metaAds &&
        (formValues.metaAds === "No" ||
          (formValues.objective &&
            formValues.industry.length > 0 &&
            formValues.budget &&
            formValues.averageBudget &&
            formValues.whatsAppAds &&
            (formValues.whatsAppAds === "No" || formValues.whatsAppAdsBudget)))
      );
    } else if (step === 3) {
      return (
        formValues.address &&
        formValues.country &&
        (!formValues.state || formValues.city) &&
        formValues.timezone &&
        formValues.postalCode &&
        !this.state.postalCodeError
      );
    }
    return false;
  };

  renderStep = () => {
    const { formValues, availableStates, availableTimezones, postalCodeError } =
      this.state;
    const { classes } = this.props;

    switch (this.state.step) {
      case 1:
        return (
          <div>
            <Autocomplete
              freeSolo
              disableClearable
              options={[]}
              onChange={(e, value) =>
                this.handleInputChange("designation", value)
              }
              onInputChange={(e, value) =>
                this.handleInputChange("designation", value)
              }
              value={formValues.designation}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Your Designation"
                  variant="outlined"
                  classes={{ root: classes.textFieldRootAutocomplete }}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
            <Autocomplete
              freeSolo
              disableClearable
              options={[]}
              onChange={(e, value) =>
                this.handleInputChange("websiteUrl", value)
              }
              onInputChange={(e, value) =>
                this.handleInputChange("websiteUrl", value)
              }
              value={formValues.websiteUrl}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Your Website"
                  variant="outlined"
                  classes={{ root: classes.textFieldRootAutocomplete }}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
            <Autocomplete
              disableClearable
              options={TYPE}
              onChange={(e, value) => this.handleInputChange("type", value)}
              value={formValues.type}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company Type"
                  variant="outlined"
                  classes={{ root: classes.textFieldRootAutocomplete }}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
            {formValues.type === "Business" && (
              <Autocomplete
                disableClearable
                options={SIZE}
                onChange={(e, value) =>
                  this.handleInputChange("companySize", value)
                }
                value={formValues.companySize}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company Size"
                    variant="outlined"
                    classes={{ root: classes.textFieldRootAutocomplete }}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            )}
            <Autocomplete
              disableClearable
              options={CUSTOMERBASE}
              onChange={(e, value) =>
                this.handleInputChange("customerBase", value)
              }
              value={formValues.customerBase}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client Base"
                  variant="outlined"
                  classes={{ root: classes.textFieldRootAutocomplete }}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
            <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={2}
              disableClearable
              options={PARTNERECOSYSTEM}
              onChange={(e, value) =>
                this.handleInputChange("ecosystem", value)
              }
              value={formValues.ecosystem}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </React.Fragment>
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((value, index) => (
                  <Chip
                    key={value}
                    label={value}
                    variant="outlined"
                    {...getTagProps({ index })}
                    style={{
                      maxWidth: "100px", // Set a maximum width for the Chip
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Areas of Focus"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      display: "flex",
                      flexWrap: "nowrap",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                    },
                  }}
                  classes={{ root: classes.textFieldRootAutocomplete }}
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: "200px",
                  overflowY: "auto",
                },
              }}
              style={{
                width: "340px",
                display: "flex",
                position: "relative",
                bottom: "11px",
                overflowX: "auto",
              }}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <Autocomplete
              disableClearable
              options={ADS}
              onChange={(e, value) => this.handleInputChange("metaAds", value)}
              value={formValues.metaAds}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Do you run Meta Ads for Clients?"
                  variant="outlined"
                  classes={{ root: classes.textFieldRootAutocomplete }}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />

            {formValues.metaAds === "Yes" && (
              <>
                <Autocomplete
                  disableClearable
                  options={OBJECTIVE}
                  onChange={(e, value) =>
                    this.handleInputChange("objective", value)
                  }
                  onInputChange={(e, value) =>
                    this.handleInputChange("objective", value)
                  }
                  value={formValues.objective}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Objective for running Meta Ads"
                      variant="outlined"
                      classes={{ root: classes.textFieldRootAutocomplete }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
                <Autocomplete
                  multiple
                  limitTags={2}
                  disableClearable
                  disableCloseOnSelect
                  options={VERTICALS}
                  onChange={(e, value) =>
                    this.handleInputChange("industry", value)
                  }
                  value={formValues.industry}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </React.Fragment>
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map((value, index) => (
                      <Chip
                        key={value}
                        label={value}
                        variant="outlined"
                        {...getTagProps({ index })}
                        style={{
                          maxWidth: "100px", // Set a maximum width for the Chip
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Which industry you run Ads for?"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          display: "flex",
                          flexWrap: "nowrap",
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                        },
                      }}
                      classes={{
                        root: classes.textFieldRootAutocompleteDropDown,
                      }}
                    />
                  )}
                  ListboxProps={{
                    style: {
                      maxHeight: "200px",
                      overflowY: "auto",
                    },
                  }}
                  style={{ width: "360px", display: "flex", overflowX: "auto" }}
                />

                <Autocomplete
                  disableClearable
                  options={WHATSAPPADS}
                  onChange={(e, value) =>
                    this.handleInputChange("whatsAppAds", value)
                  }
                  value={formValues.whatsAppAds}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Do you run Click to WhatsApp Ads?"
                      variant="outlined"
                      classes={{ root: classes.textFieldRootAutocomplete }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
                <Autocomplete
                  disableClearable
                  options={BUDGET}
                  onChange={(e, value) =>
                    this.handleInputChange("budget", value)
                  }
                  onInputChange={(e, value) =>
                    this.handleInputChange("budget", value)
                  }
                  value={formValues.budget}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Total Meta Ad Budget"
                      variant="outlined"
                      classes={{ root: classes.textFieldRootAutocomplete }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
                <Autocomplete
                  disableClearable
                  options={AVERAGEADSBUDGET}
                  onChange={(e, value) =>
                    this.handleInputChange("averageBudget", value)
                  }
                  onInputChange={(e, value) =>
                    this.handleInputChange("averageBudget", value)
                  }
                  value={formValues.averageBudget}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Average Cost Per Lead"
                      variant="outlined"
                      classes={{ root: classes.textFieldRootAutocomplete }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
                {formValues?.whatsAppAds !== "No" && (
                  <Autocomplete
                    disableClearable
                    options={WHATSAPPADSBUDGET}
                    onChange={(e, value) =>
                      this.handleInputChange("whatsAppAdsBudget", value)
                    }
                    value={formValues.whatsAppAdsBudget}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Click to WhatsApp Ads Budget"
                        variant="outlined"
                        classes={{ root: classes.textFieldRootAutocomplete }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                )}
              </>
            )}
          </div>
        );
      case 3:
        return this.renderStep3();
      default:
        return null;
    }
  };

  renderStep3 = () => {
    const { formValues, availableStates, availableTimezones, postalCodeError } =
      this.state;
    const { classes } = this.props;

    return (
      <div>
        <Autocomplete
          freeSolo
          disableClearable
          options={[]}
          onChange={(e, value) => this.handleInputChange("address", value)}
          onInputChange={(e, value) => this.handleInputChange("address", value)}
          value={formValues.address}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Address"
              variant="outlined"
              classes={{ root: classes.textFieldRootAutocomplete }}
              InputProps={{ ...params.InputProps }}
            />
          )}
        />
        <Autocomplete
          disableClearable
          options={countryData}
          getOptionLabel={(option) => option.country}
          onChange={this.handleCountryChange}
          value={
            countryData.find((option) => option.code === formValues.country) ||
            null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              variant="outlined"
              classes={{ root: classes.textFieldRootAutocomplete }}
              InputProps={{ ...params.InputProps }}
            />
          )}
        />
        {availableStates.length > 0 && (
          <Autocomplete
            disableClearable
            options={availableStates}
            onChange={(e, value) => this.handleInputChange("state", value)}
            value={formValues.state}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                variant="outlined"
                classes={{ root: classes.textFieldRootAutocomplete }}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        )}
        <Autocomplete
          freeSolo
          disableClearable
          options={[]}
          onChange={(e, value) => this.handleInputChange("city", value)}
          onInputChange={(e, value) => this.handleInputChange("city", value)}
          value={formValues.city}
          renderInput={(params) => (
            <TextField
              {...params}
              label="City"
              variant="outlined"
              classes={{ root: classes.textFieldRootAutocomplete }}
              InputProps={{ ...params.InputProps }}
            />
          )}
        />
        <Autocomplete
          freeSolo
          disableClearable
          options={[]}
          onChange={(e, value) => this.handleInputChange("postalCode", value)}
          onInputChange={(e, value) =>
            this.handleInputChange("postalCode", value)
          }
          value={formValues.postalCode}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Postal Code"
              variant="outlined"
              classes={{ root: classes.textFieldRootAutocomplete }}
              InputProps={{
                ...params.InputProps,
                endAdornment: postalCodeError && (
                  <Tooltip
                    title="Postal code should not exceed 7 characters"
                    placement="right"
                  >
                    <ErrorOutline color="error" />
                  </Tooltip>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          disableClearable
          freeSolo
          options={availableTimezones}
          inputValue={formValues.timezone}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Timezone"
              variant="outlined"
              classes={{ root: classes.textFieldRootAutocomplete }}
              InputProps={{ ...params.InputProps, readOnly: true }}
            />
          )}
        />
      </div>
    );
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { classes, isPartnerDetailLoading, partnerDetail } = this.props;
    const {
      snackbarOpen,
      step,
      additonalDetailsDialog,
      formValues,
      data,
      analyticsDialog,
    } = this.state;
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

    const isPartnerDetailEmpty =
      !partnerDetail || Object.keys(partnerDetail).length === 0;

    if (isPartnerDetailLoading || isPartnerDetailEmpty) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <div className={classes.root}>
        {isPartnerDetailLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className={classes.root}>
            <Header
              businessTitle={`Welcome, ${partnerDetail.name}`}
              // buttonTitle={"Help Center"}
              // onButtonClick={this.onButtonClick}
              // onOnboardingCallClick={this.onOnboardingCallClick}
              // onPlatformDemoClick={this.onPlatformDemoClick}
              // showDashboardButtons={true}
            />
            <Box my={3} />
            <Grid container justify="center">
              <Grid item xs={12} md={11}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box className={classes.container}>
                          <Typography
                            variant="body1"
                            style={{ marginBottom: "10px" }}
                          >
                            <b>
                              Share your link to refer new customers to AiSensy!
                            </b>
                          </Typography>
                          <Box display="flex" alignItems="center">
                            {partnerDetail.AffiliateUrls &&
                            partnerDetail.AffiliateUrls.length >= 1 ? (
                              <TextField
                                style={{ padding: 0, width: "50%" }}
                                variant="outlined"
                                margin="dense"
                                value={partnerDetail.AffiliateUrls[0]}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          this.handleCopy(
                                            partnerDetail.AffiliateUrls[0]
                                          )
                                        }
                                      >
                                        <FileCopyIcon fontSize="small" />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            ) : (
                              <CircularProgress size={24} />
                            )}
                            <IconButton
                              component="a"
                              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                                partnerDetail.AffiliateUrls[0]
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TwitterIcon style={{ color: "#1DA1F2" }} />
                            </IconButton>
                            <IconButton
                              component="a"
                              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                                "Hey👋!\n\nWish to generate *3 times more Revenue* for your brand via WhatsApp?💰\n\nUse *AiSensy*, the *Smartest WhatsApp Engagement Platform* trusted by *50,000+ brands* like *PhysicsWallah, Delhi Transport Corporation, Emami & Man Matters*\n\n✅ Broadcast messages, Automate notifications, offer multi-agent live chat, Run Ads that click to WhatsApp, Showcase Catalogs, Collect Payments, Build Chatbots, build WhatsApp forms... ALL in ONE place!\n\n🔥 Start your *14-Day FREE Trial NOW* - " +
                                  partnerDetail.AffiliateUrls[0]
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <WhatsAppIcon style={{ color: "#25D366" }} />
                            </IconButton>
                            <IconButton
                              component="a"
                              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                                partnerDetail.AffiliateUrls[0]
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LinkedInIcon style={{ color: "#0762C8" }} />
                            </IconButton>
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              width="35%"
                            >
                              <Button
                                onClick={this.handleAnalyticsDiaglog}
                                variant="contained"
                                color="primary"
                              >
                                View Link Analytics
                              </Button>
                              <Dialog
                                open={analyticsDialog}
                                onClose={this.handleAnalyticsDialogClose}
                                style={{ height: "auto", width: "100%" }}
                              >
                                <DialogTitle>
                                  Affiliate Link Analytics
                                  <IconButton
                                    aria-label="close"
                                    onClick={this.handleAnalyticsDialogClose}
                                    style={{
                                      position: "absolute",
                                      right: 8,
                                      top: 8,
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ padding: "16%" }}
                                    >
                                      <Box textAlign="center">
                                        <Typography variant="h6">
                                          Click Distribution
                                        </Typography>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} md={12}>
                                            <Box
                                              border="1px solid lightgrey"
                                              className={classes.container}
                                              textAlign="center"
                                            >
                                              <Typography
                                                variant="body1"
                                                color="textPrimary"
                                                // style={{ marginBottom: "10px" }}
                                              >
                                                <b>Total Clicks</b>
                                              </Typography>
                                              <Typography
                                                variant="h3"
                                                color="primary"
                                              >
                                                <b>{this.state.totalClick}</b>
                                              </Typography>
                                            </Box>
                                          </Grid>
                                          <Grid item xs={12} md={12}>
                                            <Box
                                              border="1px solid lightgrey"
                                              className={classes.container}
                                              textAlign="center"
                                            >
                                              <Typography
                                                variant="body1"
                                                color="textPrimary"
                                                style={{ marginBottom: "10px" }}
                                              >
                                                <b>Unique Click</b>
                                              </Typography>
                                              <Typography
                                                variant="h3"
                                                color="primary"
                                              >
                                                <b>{this.state.uniqueClicks}</b>
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                              </Dialog>
                            </Box>
                          </Box>
                        </Box>
                        <Grid container spacing={2}>
                          {/* First Card */}
                          <Grid item xs={12} md={6}>
                            <TrainingSessionCard
                              gridSize={12}
                              link="https://go.aisensy.com/onboarding"
                              themeBackground="rgb(194 220 255)"
                              title="Dashboard Onboarding Call"
                              subTitle="Schedule your dashboard onboarding call with our team"
                              Description={() => (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  * Understand how to use the affiliate
                                  dashboard
                                  <br />
                                  * Learn how to track your commissions and
                                  performance
                                  <br />
                                  * Get tips on optimizing your affiliate
                                  strategies
                                  <br />* Explore advanced reporting and
                                  analytics features
                                </Typography>
                              )}
                              buttonTitle="Schedule Call Now!"
                              iconLink={CRMTraining}
                              maxImageSize="100px"
                              height="175px"
                            />
                          </Grid>

                          {/* Second Card */}
                          <Grid item xs={12} md={6}>
                            <TrainingSessionCardWithHelpCenter
                              gridSize={12}
                              link="https://calendly.com/d/3k8-k9q-9r3"
                              themeBackground="rgb(255, 239, 239)"
                              title="Platform Demo Call"
                              subTitle="Schedule your platform demo call with our team"
                              Description={() => (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  * Understand how to use the affiliate
                                  dashboard effectively
                                  <br />
                                  * Explore key features and tools available on
                                  the platform
                                  <br />
                                  * Learn how to track and analyze your
                                  affiliate performance
                                  <br />
                                  * Get insights into commission structures and
                                  payouts
                                  <br />
                                  <Box className={classes.helpLink}>
                                    For more details please refer to{" "}
                                    <a
                                      href="https://help.aisensy.com/en/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      help doc.
                                    </a>
                                  </Box>
                                </Typography>
                              )}
                              buttonTitle="Schedule Call Now!"
                              iconLink={CRMTraining}
                              maxImageSize="100px"
                              height="175px"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    {/* <Box className={classes.container}>
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        <b>
                          Share your link to refer new customers to AiSensy!
                        </b>
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <TextField
                          style={{ padding: 0, width: "50%" }}
                          variant="outlined"
                          margin="dense"
                          value={partnerDetail.AffiliateUrls[1]}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    this.handleCopy(
                                      partnerDetail.AffiliateUrls[1]
                                    )
                                  }
                                >
                                  <FileCopyIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <IconButton
                          component="a"
                          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                            partnerDetail.AffiliateUrls[1]
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TwitterIcon style={{ color: "#1DA1F2" }} />
                        </IconButton>
                        <IconButton
                          component="a"
                          href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                            "Hey👋!\n\nWish to generate 3 times more Revenue for your brand?💰\n\nUse AiSensy, a Smart WhatsApp Engagement Platform⚡️.\nTrusted by 25,000+ brands like Cosco, Skullcandy, and Vivo for Official WhatsApp Marketing & Automation.\n\nCheck it out now - " +
                              partnerDetail.AffiliateUrls[1]
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <WhatsAppIcon style={{ color: "#25D366" }} />
                        </IconButton>
                        <IconButton
                          component="a"
                          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                            partnerDetail.AffiliateUrls[1]
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedInIcon style={{ color: "#0762C8" }} />
                        </IconButton>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          width="35%"
                        >
                          <Button
                            onClick={this.handleAnalyticsDiaglog}
                            variant="contained"
                            color="primary"
                          >
                            View Link Analytics
                          </Button>
                          <Dialog
                            open={analyticsDialog}
                            onClose={this.handleAnalyticsDialogClose}
                            style={{ height: "auto" }}
                          >
                            <DialogTitle>
                              Affiliate Link Analytics
                              <IconButton
                                aria-label="close"
                                onClick={this.handleAnalyticsDialogClose}
                                style={{
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                }}
                              >
                                <Close />
                              </IconButton>
                            </DialogTitle>
                            <DialogContent>
                              <Grid container spacing={2}>
                                <Grid item xs={12} style={{ padding: "16%" }}>
                                  <Box textAlign="center">
                                    <Typography variant="h6">
                                      Click Distribution
                                    </Typography>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={12}>
                                        <Box
                                          border="1px solid lightgrey"
                                          className={classes.container}
                                          textAlign="center"
                                        >
                                          <Typography
                                            variant="body1"
                                            color="textPrimary"
                                            // style={{ marginBottom: "10px" }}
                                          >
                                            <b>Total Clicks</b>
                                          </Typography>
                                          <Typography
                                            variant="h3"
                                            color="primary"
                                          >
                                            <b>{this.state.totalClick}</b>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <Box
                                          border="1px solid lightgrey"
                                          className={classes.container}
                                          textAlign="center"
                                        >
                                          <Typography
                                            variant="body1"
                                            color="textPrimary"
                                            style={{ marginBottom: "10px" }}
                                          >
                                            <b>Unique Click</b>
                                          </Typography>
                                          <Typography
                                            variant="h3"
                                            color="primary"
                                          >
                                            <b>{this.state.uniqueClicks}</b>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Box>
                    </Box> */}

                    <Box className={classes.container}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: 8 }}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography variant="h4" color="primary" xs={12} sm={6}>
                          Overview
                        </Typography>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Box
                            border="1px solid lightgrey"
                            className={classes.container}
                            textAlign="center"
                          >
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              style={{ marginBottom: "10px" }}
                            >
                              <b>Total Commission</b>
                            </Typography>
                            <Typography variant="h3" color="primary">
                              <b>
                                {partnerDetail.totalCommisionINR > 0 &&
                                  `₹ ${(
                                    partnerDetail.totalCommisionINR / 100000
                                  ).toFixed(2)}`}
                                {
                                  partnerDetail.totalCommisionINR > 0 &&
                                    partnerDetail.totalCommisionUSD > 0 &&
                                    " | " // Display a bar only if both INR and USD have values
                                }
                                {partnerDetail.totalCommisionUSD > 0 &&
                                  ` $ ${(
                                    partnerDetail.totalCommisionUSD / 100000
                                  ).toFixed(2)}`}
                                {partnerDetail.totalCommisionINR === 0 &&
                                  partnerDetail.totalCommisionUSD === 0 && (
                                    <>₹ 0.00</>
                                  )}
                              </b>
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Box
                            border="1px solid lightgrey"
                            className={classes.container}
                            textAlign="center"
                          >
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              style={{ marginBottom: "10px" }}
                            >
                              <b>Revenue Generated</b>
                            </Typography>
                            <Typography variant="h3" color="primary">
                              <b>
                                {partnerDetail.revenueGeneratedINR > 0 &&
                                  `₹ ${(
                                    partnerDetail.revenueGeneratedINR / 100000
                                  ).toFixed(2)}`}
                                {
                                  partnerDetail.revenueGeneratedINR > 0 &&
                                    partnerDetail.revenueGeneratedUSD > 0 &&
                                    " | " // Display a bar only if both INR and USD have values
                                }
                                {partnerDetail.revenueGeneratedUSD > 0 &&
                                  `$ ${(
                                    partnerDetail.revenueGeneratedUSD / 100000
                                  ).toFixed(2)}`}
                                {partnerDetail.revenueGeneratedINR === 0 &&
                                  partnerDetail.revenueGeneratedUSD === 0 && (
                                    <>₹ 0.00</>
                                  )}
                              </b>
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Box
                            border="1px solid lightgrey"
                            className={classes.container}
                            textAlign="center"
                          >
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              style={{ marginBottom: "10px" }}
                            >
                              <b>Total Commission Paid</b>
                            </Typography>
                            <Typography variant="h3" color="primary">
                              <b>
                                {(partnerDetail.totalCommisionPaidINR ?? 0) >
                                  0 &&
                                  `₹ ${(
                                    partnerDetail.totalCommisionPaidINR / 100000
                                  ).toFixed(2)}`}
                                {
                                  (partnerDetail.totalCommisionPaidINR ?? 0) >
                                    0 &&
                                    (partnerDetail.totalCommisionPaidUSD ?? 0) >
                                      0 &&
                                    " | " // Display a bar only if both INR and USD have values
                                }
                                {(partnerDetail.totalCommisionPaidUSD ?? 0) >
                                  0 &&
                                  `$ ${(
                                    partnerDetail.totalCommisionPaidUSD / 100000
                                  ).toFixed(2)}`}
                                {(partnerDetail.totalCommisionPaidINR ?? 0) ===
                                  0 &&
                                  (partnerDetail.totalCommisionPaidUSD ?? 0) ===
                                    0 && <>₹ 0.00</>}
                              </b>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={4}>
                          <Box
                            border="1px solid lightgrey"
                            className={classes.container}
                            textAlign="center"
                          >
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              style={{ marginBottom: "10px" }}
                            >
                              <b>Total Leads Generated</b>
                            </Typography>
                            <Typography variant="h3" color="primary">
                              <b>{this.state.totalLeadsGenerated || 0}</b>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Box
                            border="1px solid lightgrey"
                            className={classes.container}
                            textAlign="center"
                          >
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              style={{ marginBottom: "10px" }}
                            >
                              <b>Total Conversions & Conversion Rate</b>
                            </Typography>
                            <Typography variant="h3" color="primary">
                              <b>
                                {this.state.totalConversions} |{" "}
                                {this.state.conversionRate}%
                              </b>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box my={8} />
            {partnerDetail.affiliatePlan === "tier0" &&
              !partnerDetail.additionalDetailsFilled && (
                <Dialog
                  open={additonalDetailsDialog}
                  onClose={this.handleCloseAdditonalDetails}
                  scroll="paper"
                  disableBackdropClick
                  disableEscapeKeyDown
                >
                  <DialogTitle
                    disableTypography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  ></DialogTitle>
                  <DialogContent>
                    <Typography
                      variant="h3"
                      gutterBottom
                      style={{ marginBottom: "10px" }}
                    >
                      <b> Welcome to AiSensy Affiliate Program 🎉 </b>
                    </Typography>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ marginBottom: "10px" }}
                    >
                      <b> Tell us a bit about yourself to get started </b>
                    </Typography>
                    <DialogContent>{this.renderStep()}</DialogContent>
                  </DialogContent>
                  <DialogActions style={{ margin: "10px" }}>
                    {step > 1 && (
                      <Button
                        onClick={this.prevStep}
                        variant="outlined"
                        style={{ marginRight: "auto", alignItems: "left" }}
                      >
                        Back
                      </Button>
                    )}
                    {step < 3 ? (
                      <Button
                        onClick={this.nextStep}
                        color="primary"
                        variant="contained"
                        disabled={!this.allFieldsFilled()}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleProfileCompletion}
                        color="primary"
                        variant="contained"
                        disabled={!this.allFieldsFilled()}
                      >
                        Proceed
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              )}
            <Snackbar
              open={!!this.state.alertMsg}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={this.onSnackbarClose}
              className={classes.snackbar}
              autoHideDuration={3000}
            >
              <Alert
                severity={this.state.alertType}
                onClose={this.onSnackbarClose}
              >
                {this.state.alertMsg}
              </Alert>
            </Snackbar>
          </div>
        )}
      </div>
    );
  }
}

const countryData = countriesTimezones.map((option) => option);

const SIZE = [
  "1 - 10 Employees",
  "10 - 20 Employees",
  "20 - 50 Employees",
  "50 - 200 Employees",
  "200 + Employees",
];

const VERTICALS = [
  "Ecommerce",
  "Education",
  "Automotive",
  "IT Services",
  "Real Estate",
  "SAAS/Apps",
  "Gaming",
  "Entertainment",
  "Finance and Banking",
  "Medical and Health",
  "Hotel and Lodging",
  "Beauty, Spa and Salon",
  "Clothing and Apparel",
  "Event Planning and Service",
  "Food and Grocery",
  "Professional Services",
  "Public Service",
  "Non-profit",
  "Shopping and Retail",
  "Travel and Transportation",
  "Restaurant",
  "Other",
];

const BUDGET = [
  "₹0 - ₹25,000",
  "₹25,000 - ₹1,00,000",
  "₹1,00,000 - ₹5,00,000",
  "₹5,00,000 - ₹20,00,000",
  "₹20,00,000 - ₹50,00,000",
  "₹50 lakhs - ₹1 crore",
  "₹1 crore+",
];

const AVERAGEADSBUDGET = [
  "₹0 - ₹50",
  "₹50 - ₹100",
  "₹100 - ₹150",
  "₹150 - ₹200",
  "₹200 - ₹500",
  "₹500 and above",
];

const WHATSAPPADS = ["Yes", "No", "No, but willing to try out"];

const CUSTOMERBASE = [
  "0 - 10",
  "11 - 50",
  "51 - 100",
  "101 - 200",
  "201 - 500",
  "501 - 1000",
  "1001 - 2000",
  "2000+",
];

const TYPE = ["Business", "Individual"];

const PARTNERECOSYSTEM = [
  "IVR Service Provider",
  "CRM",
  "WhatsApp Marketing Services",
  "SMS Service Provider",
  "Digital Marketing Agency",
  "Solution Provider",
  "Website and Application Development",
  "Other",
];

const WHATSAPPADSBUDGET = [
  "₹0 - ₹5000/month",
  "₹5000 - 20,000/month",
  "₹20,000 - 50,000/month",
  "₹50,000/month",
  "₹1,00,000/month",
  "₹5,00,000/month",
  "₹10,00,000/month",
  "₹50 Lakh+",
];

const OBJECTIVE = [
  "Lead Generation",
  "Sales / Conversions",
  "Branding / Awareness",
  "Running Ads for clients",
];
const ADS = ["Yes", "No"];

const CURRENCY = ["INR", "USD"];

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    // marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
    marginTop: 10,
    marginBottom: 10,
  },
  analyticsContainer: {
    background: "white",
    padding: 0,
    margin: 0,
  },
  snackbar: {
    top: theme.spacing(9),
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  textFieldRootAutocomplete: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    padding: "2px 8px",
    marginTop: "10px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    justifyContent: "center",
    alignItems: "center",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },

  helpLink: {
    cursor:
      "pointer !important" /* Optional: to make it more distinguishable */,
  },

  textFieldRootAutocompleteDropDown: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    padding: "2px 8px",
    marginTop: "7px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    justifyContent: "center",
    alignItems: "center",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  chip: {
    margin: "2px",
    fontSize: "0.5rem",
    display: "flex",
    flexDirection: "row",
  },
  tagList: {
    display: "flex",
    flexWrap: "wrap",
  },
});

const connectDashboard = connect(
  (state) => ({
    isPartnerDetailLoading: state.partner.isLoading,
    partnerDetail: state.partner.partner,
  }),
  {
    fetchPartnerDetails,
  }
)(Dashboard);

export default withStyles(styles)(connectDashboard);