import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import themes from "./themes";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import setAuthToken from "./helpers/setAuthToken";
import jwt_decode from "jwt-decode";
import { compose } from "redux";

const login = async () => {
  try {
    if (localStorage.getItem("jwtToken")) {
      await setAuthToken(localStorage.getItem("jwtToken"));
      const decoded = jwt_decode(localStorage.getItem("jwtToken"));
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem("jwtToken");
        await store.dispatch({ type: "Login/SIGN_OUT_SUCCESS" });
      } else {
        await store.dispatch({ type: "Login/LOGIN_SUCCESS", payload: decoded });
        const newObj = {
          partnerData: {
            ...decoded,
            _id: decoded.id,
          },
        };
        await store.dispatch({ type: "PARTNER/LOAD_SUCCESS", payload: newObj });
      }
    }
  } catch (error) {
    console.error({ message: "invalid JWT token", jwtError: error });
    await store.dispatch({ type: "Login/SIGN_OUT_SUCCESS" });
  }
};

login().then(() => {
  const theme = createTheme({ ...themes.default });

  ReactDOM.render(
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
